import { dateRange } from './Components/DatePicker/reducers/reducers';
import { lenox } from './Components/lenox/reducers';
import { filters } from './Components/Filters/reducers/reducers';
import { pageReducer } from './Components/PageContainer/reducers';
import { automaticTesting } from './Components/Affiliates/Components/AutomaticTesting/reducers';

export const reducers = {
  lenox,
  dateRange,
  filters,
  pageReducer,
  automaticTesting
};
