/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable no-return-await */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import classNames from 'classnames';
import { withStyles } from 'tss-react/mui';
import {
  Grid,
  Select as SelectField,
  InputLabel,
  FormControl,
  MenuItem,
  Chip
} from '@mui/material';
import { CircularProgress } from '../../Utilities/CircularProgress';

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%'
  },
  margin: {
    marginRight: '1rem'
  },
  loader: {
    textAlign: 'center'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(1 / 4)
  },
  image: {
    height: '10px',
    width: '10px'
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null
};

class MultiSelect extends Component {
  state = {
    labelWidth: 0,
    options: [],
    loading: false
  };

  componentDidMount() {
    this.getOptions(this.props);
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
    });
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (
      this.props.dependency !== nextProps.dependency ||
      this.props.options !== nextProps.options
    ) {
      this.getOptions(nextProps);
    }
  }

  getOptions = async (props) => {
    if (typeof props.options().then === 'function') {
      this.setState({ loading: true });
      return await props.options(props.dependency || false).then((options) =>
        this.setState({
          loading: false,
          options: [...options]
        })
      );
    }
    this.setState({ options: [...props.options()], loading: false });
  };

  renderInputLabel = () => (
    <InputLabel
      ref={(ref) => {
        this.InputLabelRef = ref;
      }}
      id={`${this.props.label}-label`}
      htmlFor={this.props.name}
    >
      {this.props.label}
    </InputLabel>
  );

  renderSelectField = (classes) => (
    <SelectField
      variant="outlined"
      label={this.props.label}
      fullwidth={this.props.fullwidth}
      value={this.props.value}
      name={this.props.name}
      error={this.props.error}
      renderValue={(selected) => (
        <div className={classes.chips}>
          {selected.map((value) => {
            const selectedOption = find(this.state.options, ['value', value]);
            return (
              (!!selectedOption && (
                <Chip
                  key={value}
                  label={selectedOption.display}
                  className={classes.chip}
                />
              )) ||
              ''
            );
          })}
        </div>
      )}
      multiple
      MenuProps={MenuProps}
      id={this.props.name}
      labelId={`${this.props.label}-label`}
      style={{ width: '100%' }}
      InputLabelProps={{ shrink: true }}
      className={classNames(classes.margin, classes.textField)}
      onChange={({ target: { value } }) => {
        if (this.props.allValues) {
          if (value.includes('all') && !this.props.value.includes('all')) {
            this.props.onChange(this.props.name, ['all']);
          } else if (value.length > 1 && this.props.value.includes('all')) {
            const newValues = [...value];
            newValues.splice(newValues.indexOf('all'), 1);
            this.props.onChange(this.props.name, newValues);
          } else {
            this.props.onChange(this.props.name, value);
          }
        } else {
          this.props.onChange(this.props.name, value);
        }
      }}
    >
      {this.state.options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.image && (
            <img className={classes.image} src={option.image} alt="" />
          )}
          {option.display}
        </MenuItem>
      ))}
    </SelectField>
  );

  renderInput = (classes) => (
    <FormControl variant="outlined" className={classes.formControl}>
      {this.renderInputLabel()}
      {this.renderSelectField(classes)}
    </FormControl>
  );

  render() {
    const { classes, name } = this.props;
    return (
      <Grid key={name} container justifyContent="center" alignItems="center">
        <Grid item xs={12} className={classes.margin}>
          {this.state.loading
            ? CircularProgress(40, classes)
            : this.renderInput(classes)}
        </Grid>
      </Grid>
    );
  }
}

MultiSelect.propTypes = {
  value: PropTypes.arrayOf([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
};

MultiSelect.defaultProps = {
  label: '',
  fullwidth: 'false',
  error: false
};

export default withStyles(MultiSelect, styles);
