/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable default-param-last */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import { Table, TableBody, TableRow, TableCell, Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import BarChart from '../../../Charts/BarChart';
import {
  convertToFormattedNumber,
  numberWithCommas
} from '../../../TableContainer/TableUtilities/columnFormatter';
import { colors } from '../../../../Utilities/LenoxColors';
import {
  checkAdmin,
  checkAffiliate,
  checkAffiliateManager,
  checkPowerUser,
  checkSuperAffiliate,
  checkAmb
} from '../../../PermissionsWrappers/permissionChecks';
import { formatXAxis, getDataSorted } from '../../../common/utilities';
import FormSingleSubmit from '../../../FormContainer/FormSingleSubmit';
import RevShareControl from '../../../common/RevShareControl';
import FormContainer from '../../../FormContainer/FormContainer';
import { buildFormFieldsWithStateForm } from '../../../Utilities/formHelper';
import { handleFormObjectChange } from '../../../Utilities/HandleFormObjectChange';
import {
  addThrottleState,
  editThrottleState
} from '../../../Affiliates/Components/AffiliateSettings/defaultState/defaultState';
import { formatSubmitValues } from '../../../Utilities/SubmitHelper/FormatForSubmit';
import { NetworkRequest } from '../../../Utilities/NetworkRequests/NetworkRequests';
import { validateForSubmit } from '../../../Utilities/ValidationHelper/ValidateForm';

const has = Object.prototype.hasOwnProperty;

const styles = () => ({
  tableCell: {
    width: '50%'
  },
  tableCellMulti: {
    textAlign: 'right'
  },
  tableCellFormConv: {
    paddingLeft: '5px',
    paddingRight: '10px',
    textAlign: 'right'
  },
  tableCellMultiDisplay: {
    textAlign: 'left'
  },
  tableCellHead: {
    fontWeight: 'bold',
    textAlign: 'center',
    paddingLeft: '5px',
    paddingRight: '10px'
  },
  tableContainer: {
    border: 'solid 1px rgba(20,20,20,.3)',
    borderRadius: '8px',
    margin: '1rem'
  },
  table: {},
  tableTitle: {
    fontSize: '1.25rem',
    textAlign: 'center',
    marginTop: 0,
    padding: '.5rem',
    backgroundColor: colors.lenoxDark2,
    color: colors.lenoxLight1,
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px'
  },
  tableDescription: {
    fontSize: '.75rem',
    padding: '.5rem',
    borderBottom: 'solid 1px rgba(20,20,20,.3)'
  }
});

const revBreakdownMapping = [
  { name: 'agent_revenue', display: 'Agent' },
  { name: 'revenue', display: 'Aggr.' },
  { name: 'email_revenue', display: 'Email' },
  { name: 'lb_revenue', display: 'LB' },
  {
    name: 'phoneRevenueInbound',
    display: 'Phone Inbound',
    formatter: (val) => `${numberWithCommas(val)}`
  },
  {
    name: 'phoneRevenueOutbound',
    display: 'Phone Outbound',
    formatter: (val) => `${numberWithCommas(val)}`
  },
  // {name: "sms_revenue", display: 'SMS'},
  { name: 'ty_revenue', display: 'TY' },
  { name: 'ty_offer_revenue', display: 'Ext. Offer' },
  { name: 'warranty_revenue', display: 'CoReg' }
];

const revReturnsMapping = [
  {
    value: 'unique_users_returns',
    display: 'Returned Count',
    formatter: (val) => `${numberWithCommas(val)}`
  },
  {
    value: 'to_return',
    display: 'Returned Amount',
    formatter: (val) => `$${convertToFormattedNumber(val)}`
  }
];

const soldMapping = [
  {
    name: 'aggregator_sold',
    display: 'Aggregator',
    formatter: (val) => `${numberWithCommas(val)}`
  },
  {
    name: 'agent_sold',
    display: 'Agent',
    formatter: (val) => `${numberWithCommas(val)}`
  },
  {
    name: 'phoneSales',
    display: 'Phone',
    formatter: (val) => `${numberWithCommas(val)}`
  },
  {
    name: 'unsold',
    display: 'Unsold',
    formatter: (val) => `${numberWithCommas(val)}`
  }
];

const conversionMapping = [
  {
    value: 'conversion_percent',
    display: 'Forms Conversion %',
    formatter: (val) => `${convertToFormattedNumber(val)}%`
  }
];

// const epcMapping = [
//   {value: "epc", display: 'EPC', formatter: (val) => `$${convertToFormattedNumber(val)}`},
// ];
const throttle = [
  {
    value: 'total_leads',
    display: 'Total Leads Throttled',
    formatter: (val) => `${numberWithCommas(val)}`
  },
  {
    value: 'total_spend',
    display: 'Total Spend Throttled',
    formatter: (val) => `${numberWithCommas(val)}`
  }
];

class AffiliateBreakdown extends Component {
  state = {
    formValuesAddThrottle: addThrottleState(),
    formValuesEditThrottle: editThrottleState(),
    submitting: false,
    dialog: {
      message: '',
      type: 'error',
      title: 'Error'
    }
  };

  componentDidMount() {
    if (this.props.row.throttle_id !== -1) {
      this.loadEditThrottleValues();
    }
  }

  loadEditThrottleValues = () => {
    const {
      formValuesEditThrottle: { site, throttle_percentage, throttle_status }
    } = this.state;
    const { row } = this.props;

    this.setState({
      formValuesEditThrottle: {
        ...this.state.formValuesEditThrottle,
        site: {
          ...site,
          value: row.site
        },
        throttle_percentage: {
          ...throttle_percentage,
          value: row.throttle_percentage
        },
        throttle_status: {
          ...throttle_status,
          value:
            (this.props.row.throttle_status ||
              this.props.row.throttle_status === '1') > 0
              ? '1'
              : '0'
        }
      }
    });
  };

  assignValuesToMapping = (mapping) => {
    const tempMapping = Object.assign([], mapping);
    const { row } = this.props;
    const map = tempMapping.map((item) => {
      const name = row[item.name];

      if (name || name === 0) {
        item.value = name;
      }

      return item;
    });

    return getDataSorted(map, 'value');
  };

  renderChart = (title, mapping, label) => {
    const { classes } = this.props;

    return (
      <Grid item xs={10} className={classes.tableContainer}>
        <h2 className={classes.tableTitle}>{title}</h2>
        <div style={{ padding: '2rem', overflow: 'auto hidden' }}>
          <BarChart
            data={this.assignValuesToMapping(mapping).map((row) => ({
              ...row,
              value: +row.value
            }))}
            barNameKey="display"
            formatXAxis={formatXAxis}
            customTick
            bars={[
              {
                dataKey: 'value',
                label,
                color: colors.lenoxSuccess1
              }
            ]}
            showDollar={label !== 'Count'}
            hideDecimal={label === 'Count'}
            loading={false}
          />
        </div>
      </Grid>
    );
  };

  renderTableCell = (data, className = 'tableCell') => (
    <TableCell className={this.props.classes[className]} key={data}>
      {data}
    </TableCell>
  );

  renderRow = (data, defaultVal, index) => {
    const { row } = this.props;

    return (
      <TableRow key={index}>
        {this.renderTableCell(data.display)}
        {this.renderTableCell(
          row && (row[data.value] || row[data.value] === 0)
            ? data.formatter(row[data.value])
            : data.defaultVal
            ? data.defaultVal
            : defaultVal
        )}
      </TableRow>
    );
  };

  renderMultiRow = (value, values, defaultVal, index) => {
    const { row } = this.props;

    return (
      <TableRow key={index}>
        {this.renderTableCell(value.display, 'tableCellMultiDisplay')}
        {values.map((data) =>
          this.renderTableCell(
            row && (row[data.value] || row[data.value] === 0)
              ? data.formatter(row[data.value])
              : data.defaultVal
              ? data.defaultVal
              : defaultVal,
            data.class ? data.class : 'tableCellMulti'
          )
        )}
      </TableRow>
    );
  };

  renderTableDescription = (description) => (
    <div className={this.props.classes.tableDescription}>{description}</div>
  );

  renderTable = (title, dataMapping = [], defaultVal, description) => {
    const { classes } = this.props;

    return (
      <Grid item xs={10} className={classes.tableContainer}>
        <h2 className={classes.tableTitle}>{title}</h2>
        {description && this.renderTableDescription(description)}
        <Table className={classes.table}>
          <TableBody>
            {dataMapping.map((data, index) => {
              if (data.values) {
                return this.renderMultiRow(
                  data,
                  data.values,
                  defaultVal,
                  index
                );
              }
              return this.renderRow(data, defaultVal, index);
            })}
          </TableBody>
        </Table>
      </Grid>
    );
  };

  handleChangeAddT = (field, value) => {
    const formValuesAddThrottle = handleFormObjectChange(field, value, {
      ...this.state.formValuesAddThrottle
    });
    this.setState({ formValuesAddThrottle });
  };

  handleChangeEditT = (field, value) => {
    const formValuesEditThrottle = handleFormObjectChange(field, value, {
      ...this.state.formValuesEditThrottle
    });
    this.setState({ formValuesEditThrottle });
  };

  renderFormContainer = (
    formValues,
    handleSubmitFunc,
    handleChange,
    submitting,
    dialog,
    title
  ) => {
    const { classes } = this.props;
    return (
      <Grid item xs={10} className={classes.tableContainer}>
        <div className={classes.tableTitle} style={{ marginBottom: '1rem' }}>
          {title}
        </div>
        <FormContainer
          formValues={buildFormFieldsWithStateForm(formValues, formValues)}
          submitting={submitting}
          handleChange={handleChange}
          handleSubmit={handleSubmitFunc}
          inputWidth={8}
          separateBlock
          dialog={dialog}
          toggleDialog={this.toggleDialog}
          caption="testing"
        />
      </Grid>
    );
  };

  handleSubmitRequest = (endpoint, action, formValues) => {
    const formattedSubmitValues = formatSubmitValues(formValues);
    const { row } = this.props;

    this.setState({ submitting: true }, () =>
      NetworkRequest(
        endpoint,
        {
          ...formattedSubmitValues,
          affiliateID: row.affiliate_id,
          id: row.throttle_id
        },
        action
      )
        .then(({ data }) =>
          this.handleResponse(
            data,
            formValues,
            endpoint === 'affiliateSpend' && action === 'addAffSpend',
            action === 'addThrottle'
          )
        )
        .catch(() => this.setState({ submitting: false }))
    );
  };

  handleSubmitAddThrottle = () => {
    const { valid, messages } = validateForSubmit(
      this.state.formValuesAddThrottle
    );
    if (!valid) {
      this.toggleDialog(messages, 'error');
      return false;
    }
    this.handleSubmitRequest(
      'throttle',
      'addThrottle',
      this.state.formValuesAddThrottle
    );
  };

  handleSubmitEditThrottle = () => {
    const { valid, messages } = validateForSubmit(
      this.state.formValuesEditThrottle
    );
    if (!valid) {
      this.toggleDialog(messages, 'error');
      return false;
    }
    this.handleSubmitRequest(
      'throttle',
      'updateThrottle',
      this.state.formValuesEditThrottle
    );
  };

  handleResponse = (
    data,
    formValues = null,
    loadPy = false,
    addedThrottle = false
  ) => {
    const { row, onChange } = this.props;

    this.setState({ submitting: false }, () => {
      this.toggleDialog(
        data.message,
        data.success ? 'success' : 'error',
        data.success ? 'Success' : 'Error'
      );
      if (loadPy) {
        this.loadPayouts();
      } else {
        /* eslint-disable-next-line no-lonely-if */
        if (onChange && typeof onChange === 'function') {
          Object.keys(formValues).forEach((key) =>
            onChange(row, key, formValues[key].value)
          );
        }
      }
      if (addedThrottle && has.call(data, 'id')) {
        onChange(row, 'throttle_id', data.id);
        this.loadEditThrottleValues();
      }
    });
  };

  toggleDialog = (message = '', type = 'error', title = 'Error') =>
    this.setState({
      dialog: {
        ...this.state.dialog,
        type,
        title,
        message
      }
    });

  render() {
    const {
      formValuesAddThrottle,
      formValuesEditThrottle,
      submitting,
      dialog
    } = this.state;
    const { row } = this.props;

    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: '5rem' }}
      >
        {!checkAffiliate() &&
          this.renderChart('Revenue', revBreakdownMapping, 'Revenue')}
        {!checkAffiliate() &&
          this.renderChart('Sales Count', soldMapping, 'Count')}
        {!checkAffiliate() &&
          this.renderTable('Revenue Returns', revReturnsMapping, '0')}
        {this.renderTable('Forms Converted', conversionMapping, '0')}
        {!(
          checkAffiliate() ||
          checkSuperAffiliate() ||
          checkAmb() ||
          checkAffiliateManager() ||
          checkPowerUser()
        ) && this.renderTable('Throttle Data', throttle, '0')}
        {checkAdmin() &&
          (row.throttle_id === -1
            ? this.renderFormContainer(
                formValuesAddThrottle,
                this.handleSubmitAddThrottle,
                this.handleChangeAddT,
                submitting,
                dialog,
                'Add Throttle'
              )
            : this.renderFormContainer(
                formValuesEditThrottle,
                this.handleSubmitEditThrottle,
                this.handleChangeEditT,
                submitting,
                dialog,
                'Edit Throttle'
              ))}
        {checkAdmin() && (
          <FormSingleSubmit
            loadEndpoint="affiliateSpend"
            loadEndpointAction="getAffiliateSpends"
            loadEndpointParams={{ affiliate_id: row.affiliate_id }}
            submitEndpoint="affiliateSpend"
            submitEndpointAction="update"
            columnMapping={[
              { name: 'vertical', display: 'Vertical' },
              { name: 'step', display: 'Step' },
              { name: 'device', display: 'Device' },
              { name: 'peak_hours', display: 'Peak Hours' },
              {
                name: 'is_revshare',
                display: 'Use Revshare?',
                customRender: (props) => <RevShareControl {...props} />
              },
              {
                name: 'spend',
                display: 'Payout',
                adornment: '$',
                adornmentPosition: 'start'
              }
            ]}
            fieldName="spend"
            additionalSubmitFields={['is_revshare']}
            title="Existing Payouts"
            confirmCallback={(row) => !row.is_revshare && +row.spend > 25}
            confirmText={(val) =>
              `You are setting a payout to an abnormally high amount. Are you sure you want to set the payout to $${val}?`
            }
            confirmDialog
          />
        )}
      </Grid>
    );
  }
}

export default withStyles(AffiliateBreakdown, styles);
