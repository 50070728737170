import {
  siteOptions,
  affiliateID,
  formConversionOptions,
  getTrafficSources
} from '../../../../common/commonRequests';

export const filters = () => ({
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions(),
    value: '2',
    inputWidth: 3
  },
  affiliateID: {
    name: 'affiliateID',
    type: 'autocomplete',
    label: 'Affiliate',
    options: () => affiliateID(),
    value: 'none',
    inputWidth: 3
  },
  formID: {
    name: 'formID',
    type: 'select',
    label: 'Form',
    options: (dependency) => formConversionOptions(dependency),
    value: 'all',
    inputWidth: 3,
    dependencyName: 'site'
  },
  trafficSource: {
    name: 'trafficSource',
    type: 'select',
    label: 'Traffic Source',
    defaultVal: '0',
    value: '0',
    inputWidth: 3,
    options: () => getTrafficSources()
  }
});
