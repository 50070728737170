export const formatSubmitValues = (formValues) => {
  const values = {};
  Object.keys(formValues).map((key) => {
    if (formValues[key].hidden && !formValues[key].sendHidden) {
      return true;
    }
    if (
      !Number.isNaN(Date.parse(formValues[key].value)) &&
      typeof formValues[key].value === 'string' &&
      formValues[key].value.includes('/')
    ) {
      const parts = formValues[key].value.split('/');
      Object.assign(values, {
        [key]: `${parts[2]}-${parts[0]}-${parts[1]}`
      });
    } else {
      Object.assign(values, {
        [key]: formValues[key].value
      });
    }
    return true;
  });
  return values;
};
