const siteOptions = [
  { value: 0, display: 'Select a Vertical' },
  { value: '2', display: 'Auto' },
  { value: '4', display: 'Home' },
  { value: '1', display: 'Life' }
];

const lookbackDayOptions = [
  { value: 'custom', display: 'Custom Date Range' },
  { value: 'all', display: 'Weekdays + Weekends' },
  { value: '1', display: 'Weekdays Only' },
  { value: '2', display: 'Weekends Only' }
];

export const filters = () => ({
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions,
    value: '2',
    inputWidth: 3
  },
  lookbackDayCriteriaState: {
    name: 'lookbackDayCriteriaState',
    type: 'select',
    label: 'Lookback Day Criteria',
    options: () => lookbackDayOptions,
    value: 'all'
  },
  lookbackDaysState: {
    name: 'lookbackDaysState',
    type: 'input',
    label: 'Lookback Days',
    value: '1',
    placeholder: 'Please Select Number of Look Back Days'
  }
});
