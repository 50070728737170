import { checkAffiliateManager } from '../../../../PermissionsWrappers/permissionChecks';

const type = [
  { value: 'all', display: 'All' },
  { value: 'external', display: 'External' },
  { value: 'internal', display: 'Internal' }
];

export const filters = () => ({
  affiliateType: {
    name: 'affiliateType',
    type: 'select',
    label: 'Affiliate Type',
    options: () => type,
    value: 'all',
    inputWidth: 3,
    hidden: checkAffiliateManager()
  }
});
