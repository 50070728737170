import { type FC, memo } from 'react';
import {
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Bar,
  Cell,
  ResponsiveContainer,
  type XAxisProps,
  Legend
} from 'recharts';
import { type IBarDataItem, type IBarChartComponent } from './types';
import { CircularProgress } from '../../Utilities/CircularProgress';
import {
  isMobileDevice,
  breakLongWordByUnderscore
} from '../../common/utilities';
import { useBarUtils } from './utils';

const CustomizedAxisTick: XAxisProps['tick'] = (tickProps) => {
  const { x, y, payload } = tickProps;

  return (
    <foreignObject
      width="50"
      height="160"
      x={x}
      y={y}
      style={{
        wordWrap: 'break-word',
        whiteSpace: 'normal',
        overflow: 'visible'
      }}
    >
      <div style={{ width: 200, transform: 'translateX(-100%)' }}>
        <p
          style={{
            userSelect: 'none',
            transform: 'rotate(-40deg) translate(-18px, 60px)',
            color: '#666',
            textAnchor: 'end',
            textAlign: 'right'
          }}
        >
          {breakLongWordByUnderscore(payload.value)}
        </p>
      </div>
    </foreignObject>
  );
};

const BarChartComponent: FC<IBarChartComponent> = memo((props) => {
  const {
    barChatData,
    xAxisProps,
    yAxisProps,
    tooltipFormatter,
    barTooltipFormatter
  } = useBarUtils(props);

  const { loading, data, bars, width: propsWidth, height: propsHeight } = props;

  const isMobile = isMobileDevice();

  if (loading) {
    return CircularProgress(45, {});
  }

  const minMobileWidth = window.innerWidth - 50;
  let mobileWidth = minMobileWidth;

  if (barChatData.length > 4) {
    mobileWidth = Math.ceil(barChatData.length / 4) * minMobileWidth;
  }

  const width = isMobile ? mobileWidth : propsWidth || 650;
  const height = propsHeight || 450;

  return (
    <ResponsiveContainer
      className="responsiveContainer"
      width={width}
      height={height}
    >
      <BarChart
        data={barChatData}
        margin={{ top: 5, right: 30, left: 20, bottom: 70 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis {...xAxisProps} tick={CustomizedAxisTick} />
        <YAxis {...yAxisProps} />
        <Tooltip formatter={tooltipFormatter} />
        {bars.map(({ dataKey, label, color, negativeColor }) => {
          return (
            <Bar dataKey={dataKey} key={dataKey} name={label} fill={color}>
              {data.map((entry, index) => {
                const key = `cell-${index}-${dataKey}`;
                const cellFill =
                  negativeColor &&
                  parseFloat(`${entry?.[dataKey as keyof IBarDataItem]}`) < 0
                    ? negativeColor
                    : color;

                return <Cell key={key} fill={cellFill} />;
              })}
              <Tooltip formatter={barTooltipFormatter} />
              <Legend wrapperStyle={{ bottom: '-10px' }} />
            </Bar>
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
});

export default BarChartComponent;
