import { siteOptions } from '../../../../common/commonRequests';

export const filters = () => ({
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: async () => {
      const sites = await siteOptions();
      return sites.filter((site) => +site.value === 2);
    },
    value: 2,
    width: 8
  }
});
