import { mapValues, isEqual } from 'lodash';

const defaultValidationReturn = { valid: true, message: '' };

export const validateFormValue = (value, name, formValues) => {
  const field = formValues[name];
  if (field.validate) {
    return (
      field.validate(
        value,
        field.min || false,
        field.max || false,
        formValues[field.minMaxField] || false,
        field.dependencyName
          ? formValues[field.dependencyName].value || false
          : false,
        name
      ) || defaultValidationReturn
    );
  }
  return defaultValidationReturn;
};

export const validateForSubmit = (formValues) => {
  let valid = true;
  const messages = [];
  Object.keys(formValues).forEach((key) => {
    const field = formValues[key];
    if (field.hidden) {
      return;
    }

    const hasValidationError = !validateFormValue(field.value, key, formValues)
      .valid;

    let hasInvalidValue = false;
    if (
      !field.skipValidation &&
      ((field.checkForEmptyValue &&
        field.checkForEmptyValue(formValues, field.value)) ||
        !field.checkForEmptyValue)
    ) {
      if (Array.isArray(field.value) && Array.isArray(field.defaultVal)) {
        hasInvalidValue = isEqual(field.value, field.defaultVal);
      } else {
        hasInvalidValue = field.value === field.defaultVal;
      }
    }
    if (hasValidationError || hasInvalidValue) {
      valid = false;
      messages.push(
        field.message ||
          `Please enter a valid value for ${field.label || field.adornment}.`
      );
    }
  });
  return { valid, messages };
};

export const validateForSubmitForAllFields = (formValues) => {
  return mapValues(formValues, (field, key) => {
    let valid = true;
    let message = '';

    if (field.hidden) {
      return field;
    }
    const hasValidationError = !validateFormValue(field.value, key, formValues)
      .valid;
    let hasInvalidValue = false;
    if (
      (field.checkForEmptyValue &&
        field.checkForEmptyValue(formValues, field.value)) ||
      !field.checkForEmptyValue
    ) {
      hasInvalidValue = field.value === field.defaultVal;
    }
    if (hasValidationError || hasInvalidValue) {
      valid = false;
      message =
        field.message ||
        `Please enter a valid value for ${field.label || field.adornment}.`;
    }
    return {
      ...field,
      error: !valid,
      message
    };
  });
};
