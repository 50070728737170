/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';

const styles = {
  card: { maxWidth: 210 },
  media: { maxWidth: 210 }
};

const formatProfitString = (value, profitColor) => {
  if (profitColor === 'red') {
    value = value.replace('-', '');
    value = `-${value}`;
  }
  return value;
};

class ProfitFormatter extends Component {
  render() {
    let compareValue = this.props.value;
    compareValue = compareValue.toString();
    compareValue = compareValue.replace('$', '');
    compareValue = compareValue.replace('%', '');
    compareValue = compareValue.replace(',', '');
    compareValue = parseFloat(compareValue);
    const profitColor = compareValue < 0 ? 'red' : 'green';
    return (
      <p style={{ color: profitColor }}>
        {formatProfitString(this.props.value, profitColor)}
      </p>
    );
  }
}

export default withStyles(ProfitFormatter, styles);
