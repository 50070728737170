/* eslint-disable no-param-reassign */
import axios from 'axios';
import { getDataUrl } from '../../../Endpoints/Endpoints';

const setParams = (params, action) => {
  if (!params.token) params.token = localStorage.getItem('token');
  if (!params.action) params.action = action;
  return params;
};

const createFormData = (files, params) => {
  const formData = new FormData();
  Object.keys(files).forEach((fileName) =>
    formData.append(fileName, files[fileName])
  );

  Object.keys(params).map((key) => {
    let item = params[key];
    item = key === 'params' ? JSON.stringify(params[key]) : item;
    formData.append(key, item);
    return true;
  });
  return formData;
};

export const NetworkRequest = async (endpoint = '', params = {}, action = '') =>
  axios.post(getDataUrl()[endpoint], {
    params: setParams(params, action)
  });

export const NetworkRequestMultipart = async (
  endpoint = '',
  params = {},
  fileNames = [],
  action = ''
) => {
  const formattedParams = setParams(params, action);
  const files = {};
  fileNames.forEach((fileName) => {
    const file = params[fileName];
    delete formattedParams[fileName];
    files[fileName] = file;
  });
  const formData = createFormData(files, { params: formattedParams });
  return axios.post(getDataUrl()[endpoint], formData);
};
