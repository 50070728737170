/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import SectionTitle from './SectionTitle';
import SectionItems from './SectionItems';
import { logError } from '../../../Utilities/logError';

const useStyles = makeStyles()(() => ({
  sectionContainer: {
    overflowY: 'hidden',
    padding: '0 15px',
    maxHeight: '50rem',
    transition: 'max-height ease-in-out .25s'
  },
  collapsed: {
    maxHeight: '45px'
  }
}));

const getParsedStoredCollapsedObject = (title) => {
  const localStorageCollapsed = localStorage.getItem('collapsedSections');
  try {
    const parsedCollapsed = JSON.parse(localStorageCollapsed);
    return parsedCollapsed.includes(title);
  } catch (e) {
    return false;
  }
};

const Section = React.memo(({ section, title }) => {
  const [collapsed, setCollapsed] = React.useState(
    getParsedStoredCollapsedObject(title)
  );
  const { classes } = useStyles();

  const handleCollapsedLocalStorage = (set) => {
    setCollapsed(set);
    const localStorageCollapsed = localStorage.getItem('collapsedSections');
    try {
      if (localStorageCollapsed) {
        const parsedCollapsed = JSON.parse(localStorageCollapsed);
        if (parsedCollapsed) {
          const newCollapsedArray = parsedCollapsed;
          if (newCollapsedArray.includes(title) && set === false) {
            const pageIndex = parsedCollapsed.indexOf(title);
            newCollapsedArray.splice(pageIndex, 1);
          } else if (set === true) {
            newCollapsedArray.push(title);
          }
          localStorage.setItem(
            'collapsedSections',
            JSON.stringify(newCollapsedArray)
          );
        }
      } else if (set === true) {
        localStorage.setItem('collapsedSections', JSON.stringify([title]));
      }
    } catch (e) {
      logError(e);
    }
  };

  const collapsable = title !== 'Top Pages';

  return (
    <div
      className={`${classes.sectionContainer} ${
        collapsed && classes.collapsed
      }`}
    >
      <SectionTitle
        title={title}
        setCollapsed={(set) => handleCollapsedLocalStorage(set)}
        collapsed={collapsed}
        collapsible={collapsable}
      />
      <SectionItems section={section} />
    </div>
  );
});

Section.propTypes = {
  section: PropTypes.instanceOf(Object).isRequired
};

export default Section;
