import {
  siteOptions,
  affiliateID,
  buyerList
} from '../../../common/commonRequests';
import { EXCLUDE_BUYER_TYPE_OPTIONS } from '../../../common/dropdownConstants';

export const filters = () => ({
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions(),
    value: '2',
    inputWidth: 3
  },
  affiliateID: {
    name: 'affiliateID',
    type: 'autocomplete',
    label: 'Affiliate',
    options: () => affiliateID(),
    value: 'none',
    inputWidth: 3
  },
  buyer: {
    name: 'buyer',
    type: 'select',
    label: 'Buyer',
    options: (dependency) => buyerList(dependency, false, false, false, true),
    value: 'all',
    inputWidth: 3,
    dependencyName: 'site'
  },
  excludeBuyerType: {
    name: 'excludeBuyerType',
    label: 'Type',
    type: 'select',
    options: () => EXCLUDE_BUYER_TYPE_OPTIONS,
    value: 0
  }
});
