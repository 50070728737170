import { getSellers } from '../../../../common/commonRequests';

const lookbackDayOptions = [
  { value: 'custom', display: 'Custom Date Range' },
  { value: 'all', display: 'Weekdays + Weekends' },
  { value: '1', display: 'Weekdays Only' },
  { value: '2', display: 'Weekends Only' }
];

const siteOptions = [
  { value: 0, display: 'Select a Vertical' },
  { value: '2', display: 'Auto' },
  { value: '4', display: 'Home' },
  { value: '1', display: 'Life' },
  { value: '9', display: 'Final Expense' }
];

export const filters = () => ({
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions,
    value: '2',
    inputWidth: 3
  },
  sellerState: {
    name: 'sellerState',
    type: 'select',
    label: 'Seller',
    options: (dependency) => getSellers(dependency, false),
    inputWidth: 3,
    dependencyName: 'site'
  },
  lookbackDayCriteriaState: {
    name: 'lookbackDayCriteriaState',
    type: 'select',
    label: 'Lookback Day Criteria',
    options: () => lookbackDayOptions,
    value: 'custom'
  },
  lookbackDaysState: {
    name: 'lookbackDaysState',
    type: 'input',
    label: 'Lookback Days',
    placeholder: 'Please Select Number of Look Back Days'
  }
});
