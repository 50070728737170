import React from 'react';

export const Close = () => (
  <svg
    style={{ width: '18px', height: '18px', color: '#fe0000' }}
    fill="currentColor"
    viewBox="0 0 24 24"
    stroke="none"
  >
    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
  </svg>
);
