import { getUserGroups } from '../../../common/commonRequests';

export const filters = () => ({
  userGroup: {
    name: 'userGroup',
    type: 'select',
    label: 'User Group',
    options: () => getUserGroups(),
    value: 'all'
  }
});
