import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Tooltip, FormControlLabel } from '@mui/material';

const Toggle = (props) => {
  const {
    value,
    onChange,
    name,
    label,
    disabled,
    status,
    tooltipTitle,
    testId
  } = props;
  const [tooltipTitleValue, setTooltipTitleValue] = useState('');

  const getTooltipTitle = () => {
    if (Number(status) === 2) {
      setTooltipTitleValue(
        'This buyer is not able to be activated and requires integration.'
      );
    }
    if (tooltipTitle) {
      setTooltipTitleValue(tooltipTitle);
    } else if (disabled) {
      setTooltipTitleValue('Seller cannot be paused');
    } else {
      setTooltipTitleValue(`Click To Toggle ${value ? 'Off' : 'On'}`);
    }
  };

  useEffect(() => {
    getTooltipTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tooltip title={tooltipTitleValue} placement="top">
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onClick={() => onChange(name, !value)}
            disabled={disabled}
            inputProps={{ 'data-testid': testId }}
          />
        }
        label={label}
      />
    </Tooltip>
  );
};

Toggle.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  tooltipTitle: PropTypes.string
};

Toggle.defaultProps = {
  disabled: false
};

export default Toggle;
