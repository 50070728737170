/* eslint-disable no-param-reassign */
import Validation from '../../../../../Utilities/ValidationHelper/Validation';

const createComparableNumber = (value) => {
  value = value || 'none';
  return parseInt(value.split(':')[0], 10) + (value.includes('PM') ? 12 : 0);
};
export default class ValidateBuyerSetup extends Validation {
  static validateStartTime = (value, start, end, endTime) => {
    const result = { ...Validation.defaultReturn };

    if (endTime && endTime.value) {
      const endtime = createComparableNumber(endTime.value);
      const starttime = createComparableNumber(value);

      if (starttime > endtime || starttime === endtime) {
        result.valid = false;
        result.message = 'Make sure your start time is before your end time.';
      }
    }

    return result;
  };

  static validateEndTime = (value, start, end, startTime) => {
    const result = { ...Validation.defaultReturn };

    if (startTime && startTime.value) {
      const endtime = createComparableNumber(value);
      const starttime = createComparableNumber(startTime.value);

      if (endtime < starttime || starttime === endtime) {
        result.valid = false;
        result.message = 'Make sure your end time is after your start time.';
      }
    }

    return result;
  };
}
