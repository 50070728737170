/* eslint-disable react/default-props-match-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TextField as Field, IconButton } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { RemoveCircle, AddCircle } from '@mui/icons-material';
import { colors } from '../../../Utilities/LenoxColors';

const styles = (theme) => ({
  textField: {
    flexBasis: 200,
    width: '100%',
    color: `${colors.lenoxLight1}!important`
  },
  margin: {
    margin: theme.spacing(1)
  }
});

class MultiInputs extends Component {
  state = {
    value: ['']
  };

  componentDidMount() {
    this.setState({ value: this.props.value });
  }

  handleChange = (index, value) => {
    const shallowCopy = [...this.state.value];
    shallowCopy[index] = value;
    this.setNewValue(shallowCopy);
  };

  setNewValue = (shallowCopy) => {
    this.setState({ value: shallowCopy });
    this.globalChange(shallowCopy);
  };

  globalChange = (value) => this.props.onChange(this.props.name, value);

  renderInputs = () =>
    this.state.value.map((inputVal, index) => this.renderInput(index));

  renderInput = (index = 0) => {
    const { classes, inputType, messages } = this.props;
    return (
      <div style={{ width: '100%', padding: '8px', display: 'inline-flex' }}>
        <div style={{ display: 'inline-block', width: '90%' }}>
          <Field
            label={this.props.label}
            multiline={false}
            rows={this.props.rows}
            rowsMax={this.props.rowsMax}
            fullwidth={this.props.fullwidth}
            inputProps={this.props.inputProps}
            id={this.props.label}
            className={classNames(classes.textField)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            placeholder={this.props.placeholder || ''}
            type={inputType || 'text'}
            value={this.state.value[index] || ''}
            error={this.props.errors[index]}
            helperText={messages[index] || ''}
            onChange={({ target: { value } }) =>
              this.handleChange(index, value)
            }
          />
        </div>
        <div
          style={{
            display: 'inline-flex',
            width: '10%',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {this.renderIcon(index)}
        </div>
      </div>
    );
  };

  renderIcon = (index) =>
    index === 0 ? (
      <IconButton onClick={() => this.addNewInput()} size="large">
        <AddCircle />
      </IconButton>
    ) : (
      <IconButton onClick={() => this.removeInput(index)} size="large">
        <RemoveCircle />
      </IconButton>
    );

  addNewInput = () => {
    const shallowCopy = [...this.state.value];
    shallowCopy.push('');
    this.setNewValue(shallowCopy);
  };

  removeInput = (index) => {
    const shallowCopy = [...this.state.value];
    shallowCopy.splice(index, 1);
    this.setNewValue(shallowCopy);
  };

  render() {
    return this.renderInputs();
  }
}

MultiInputs.propTypes = {
  value: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

MultiInputs.defaultProps = {
  label: '',
  multiline: false,
  rows: '',
  rowsMax: '',
  fullwidth: 'false',
  errors: [false],
  inputProps: {}
};

export default withStyles(MultiInputs, styles);
