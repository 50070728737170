import moment from 'moment';
import { estTimeZoneDate, currentTimeZoneDate } from '../constants';
import * as SaveStateHelper from '../../Utilities/saveStateHelper';

const setDate = (dateType, dateRange) => {
  const date = SaveStateHelper.getFromLocalStorage(dateType, null);

  switch (dateRange) {
    case 'today':
      return estTimeZoneDate !== currentTimeZoneDate
        ? moment().add(1, 'days')
        : moment();
    case 'yesterday':
      return estTimeZoneDate !== currentTimeZoneDate
        ? moment()
        : moment().subtract(1, 'days');
    case 'two_days_ago':
      return estTimeZoneDate !== currentTimeZoneDate
        ? moment().subtract(1, 'days')
        : moment().subtract(2, 'days');
    case 'last_seven_days':
      return dateType === 'startDate' ? moment().subtract(6, 'days') : moment();
    case 'this_month':
      return dateType === 'startDate' ? moment().startOf('month') : moment();
    case 'last_month':
      return dateType === 'startDate'
        ? moment().startOf('month').subtract(1, 'months')
        : moment().startOf('month').subtract(1, 'months').endOf('month');
    case 'all_time':
      return dateType === 'startDate' ? moment('1/1/2017') : moment();
    default:
      return (!!date && moment(date, 'YYYY-MM-DD')) || moment();
  }
};

const setDateRange = () => {
  const localStorageDateRange = SaveStateHelper.getFromLocalStorage(
    'dateRange',
    null
  );
  if (localStorageDateRange === null || localStorageDateRange === undefined) {
    return 'today';
  }
  return localStorageDateRange;
};

export const getLocalStorageDateRangeValues = () => {
  const dateRange = setDateRange();
  const startDate = setDate('startDate', dateRange);
  const endDate = setDate('endDate', dateRange);
  return { dateRange, startDate, endDate };
};
