/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import { Grid, Paper, Snackbar, Button } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import { colors } from '../../../Utilities/LenoxColors';
import USAMap from '../../../libs/react-usa-map';

const styles = ({ breakpoints }) => ({
  labelStyle: {
    marginLeft: '10px',
    marginRight: '45px',
    padding: '4px',
    fontSize: '1rem',
    color: 'rgba(0, 0, 0, 0.54)',
    backgroundColor: 'white',
    position: 'absolute',
    top: '-8px',
    left: '-20px',
    transform: 'translate(14px, -6px) scale(0.75)'
  },
  fullHeight: {
    height: '30rem',
    [breakpoints.down('md')]: {
      height: 'auto'
    }
  },
  withPadding: {
    padding: '1rem',
    [breakpoints.down(700)]: {
      padding: '10px 0'
    }
  },

  clearStatesButton: {
    backgroundColor: colors.lenoxDark1,
    color: colors.lenoxLight1,
    marginTop: '3rem',
    marginBottom: '3rem'
  },
  pickStateContainer: {
    padding: '5px',
    [breakpoints.down(700)]: {
      width: 'calc(100vw - 20px)'
    }
  },
  boxContainer: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    width: '100%',
    paddingTop: '20px',
    paddingBottom: '20px',
    paddingLeft: '40px',
    paddingRight: '40px',
    borderRadius: '4px',
    position: 'relative',
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.54)'
    },
    [breakpoints.down(700)]: {
      width: 'calc(100vw - 20px)',
      boxSizing: 'border-box',
      padding: '20px'
    }
  },
  content: {
    height: '25rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2rem',
    fontWeight: '100',
    [breakpoints.down(700)]: {
      width: '100%',
      overflowX: 'scroll',
      display: 'block'
    }
  }
});

const data = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
];
const formatStateColor = (selected) => (selected ? 'red' : 'green');

class PickStatesMap extends Component {
  state = {
    snackBarOpen: false
  };

  timer = null;

  mapHandler = (event) => {
    this.props.onChange(
      this.props.name,
      this.handleStateClicked(event.target.dataset.name)
    );

    if (!this.state.snackBarOpen) {
      this.openSnackBar();
    }

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ snackBarOpen: false });
    }, 3000);
  };

  handleStateClicked = (stateName) =>
    this.props.value.includes(stateName)
      ? this.props.value.replace(`${stateName}, `, '')
      : `${this.props.value + stateName}, `;

  handleClearStates = () => {
    this.props.onChange(this.props.name, '');
  };

  openSnackBar = () => {
    this.setState({ snackBarOpen: true });
  };

  renderStateColor = () => {
    const stateList = {};

    data.map((state) => {
      if (state === '0') {
        return true;
      }
      stateList[state] = {
        fill: formatStateColor(this.props.value.includes(state))
      };
      return true;
    });
    return stateList;
  };

  render() {
    const { classes, name, label, value } = this.props;

    return (
      <Grid
        container
        xs={12}
        md={12}
        xl={12}
        key={name}
        className={classes.pickStateContainer}
        align="center"
        justifyContent="center"
      >
        <div className={classes.boxContainer} align="center" justify="center">
          <label className={classes.labelStyle}>{label}</label>
          <Paper className={`${classes.fullHeight} ${classes.withPadding}`}>
            <div className={classes.content}>
              <USAMap
                title={label}
                width={500}
                height={375}
                customize={this.renderStateColor()}
                onClick={this.mapHandler}
              />
            </div>
            <div>
              Green states are allowed for traffic. Red states are blocked.
            </div>
          </Paper>
          <Button
            id="clearButton"
            variant="contained"
            color="inherit"
            className={classes.clearStatesButton}
            onClick={this.handleClearStates}
            disabled={this.props.submitting}
          >
            Clear States
          </Button>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={this.state.snackBarOpen}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={<span>{`States Selected: ${value.slice(0, -2)}`}</span>}
        />
      </Grid>
    );
  }
}

PickStatesMap.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

PickStatesMap.defaultProps = {
  label: '',
  error: false
};

export default withStyles(PickStatesMap, styles);
