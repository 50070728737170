/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { startCase } from 'lodash';
import { Grid, CircularProgress } from '@mui/material';
import { PieChart, Pie, Sector, Cell } from 'recharts';
import { colors } from '../../Utilities/LenoxColors';
import { convertToFormattedNumber } from '../TableContainer/TableUtilities/columnFormatter';
import { isMobileDevice } from '../common/utilities';

const palette = [
  '#3A4750',
  '#346C78',
  '#2A948F',
  '#53BB8F',
  '#9DDD80',
  '#F9F871'
];

const renderActiveShape = (
  props,
  showDollar,
  showKey,
  nameMapping,
  fixedMode,
  uppercase,
  hideLowLabel,
  formatValue
) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    percent,
    value,
    name
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my < 180 ? my - 20 : my + 20;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  const formattedValue = `${showDollar ? '$' : ''}${
    formatValue ? convertToFormattedNumber(value) : value
  }`;
  const hideLabel = hideLowLabel !== undefined ? hideLowLabel : percent < 0.02;

  return (
    <g>
      {!fixedMode && (
        <>
          {showKey ? (
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
              {nameMapping
                ? nameMapping[name].capitalize().slice(0, 14)
                : name
                    .capitalize()
                    .split(' ')
                    .map((element, index) => {
                      return (
                        <tspan x={cx} y={cy} dy={`${index}em`}>
                          {uppercase ? element.toUpperCase() : element}
                        </tspan>
                      );
                    })}
            </text>
          ) : (
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
              {formattedValue}
            </text>
          )}
        </>
      )}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle - 1}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle - 1}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      {!hideLabel && (
        <>
          <path
            d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
            stroke={fill}
            fill="none"
          />
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            textAnchor={textAnchor}
            fill="#333"
          >
            {nameMapping ? nameMapping[name] : name}
          </text>
          {!showKey && (
            <text
              x={ex + (cos >= 0 ? 1 : -1) * 12}
              y={ey}
              dy={18}
              textAnchor={textAnchor}
              fill="#333"
            >
              {formattedValue}
            </text>
          )}
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            dy={showKey ? 18 : 36}
            textAnchor={textAnchor}
            fill="#999"
          >
            {`(${(percent * 100).toFixed(2)}%)`}
          </text>
        </>
      )}
    </g>
  );
};

const getAllIndex = (length) => {
  const indexes = [];
  for (let i = 0; i < length; i += 1) {
    indexes.push(i);
  }
  return indexes;
};

export default class PieChartComponent extends React.Component {
  state = {
    activeIndex: 0
  };

  onPieEnter = (data, index) =>
    this.setState({
      activeIndex: index
    });

  renderLoader = () => (
    <Grid>
      <CircularProgress color="inherit" size={45} />
    </Grid>
  );

  renderTitle = () =>
    this.props.title && (
      <h2 style={{ textAlign: 'center' }}>{this.props.title}</h2>
    );

  render() {
    const {
      data,
      dataKey,
      nameKey,
      showDollar = false,
      loading,
      width,
      height,
      showKey = false,
      nameMapping = false,
      fixedMode = false,
      uppercase = false,
      hideLowLabel = undefined,
      formatValue = true,
      notChangeNameKey = false
    } = this.props;
    if (loading) {
      return this.renderLoader();
    }
    const displayVal = isMobileDevice() ? 'block' : 'inline-block';
    const dataToShow = fixedMode
      ? data.filter((elem) => elem[dataKey] > 0)
      : data;

    return (
      <div
        style={{
          width: width || isMobileDevice() ? 300 : 600,
          display: displayVal
        }}
      >
        {this.renderTitle()}
        <PieChart
          width={width || isMobileDevice() ? 300 : 600}
          height={height || 300}
          style={{ display: isMobileDevice() ? 'block' : 'inline' }}
        >
          <Pie
            activeIndex={
              (fixedMode && getAllIndex(data.length)) || this.state.activeIndex
            }
            activeShape={(props) =>
              renderActiveShape(
                props,
                showDollar,
                showKey,
                nameMapping,
                fixedMode,
                uppercase,
                hideLowLabel,
                formatValue
              )
            }
            data={dataToShow.map((item) => ({
              ...item,
              [dataKey]: +item[dataKey],
              [nameKey]: notChangeNameKey
                ? item[nameKey]
                : startCase(item[nameKey])
            }))}
            dataKey={dataKey}
            nameKey={nameKey}
            innerRadius={60}
            outerRadius={80}
            fill={colors.lenoxDark2}
            onMouseEnter={!fixedMode && this.onPieEnter}
          >
            {this.props.multiColor
              ? data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={palette[index % palette.length]}
                  />
                ))
              : data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors.lenoxDark2} />
                ))}
          </Pie>
        </PieChart>
      </div>
    );
  }
}
