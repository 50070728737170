import { type AxiosResponse } from 'axios';
import { logError } from '../../../Utilities/logError';
import { NetworkRequest } from '../../Utilities/NetworkRequests/NetworkRequests';
import { type ILoginValues } from '../components/LoginForm';

export interface ILoginResponse {
  message: string;
  token: string;
  status: boolean;
  user_id: string;
  affiliate_id?: string;
  terms_accepted: string;
}

type ExtendedLoginValuesType = ILoginValues & { token: string | null };

export const attemptLogin = (
  formValues: ExtendedLoginValuesType
): Promise<AxiosResponse<ILoginResponse>> => {
  return NetworkRequest('login', formValues, 'get_token');
};

export const checkToken = async () =>
  NetworkRequest('login', {}, 'test_token')
    .then(({ data }) => {
      return data.status === true;
    })
    .catch((error) => logError(error));
