/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import {
  Grid,
  FormLabel,
  FormGroup,
  FormControlLabel,
  FormControl,
  Button
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import ShowMore from 'react-show-more-button';
import Checkbox from './Checkbox';

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
    paddingLeft: '15px'
  },
  margin: {
    marginRight: '1rem'
  },
  loader: {
    textAlign: 'center'
  },
  helper: {
    color: 'red'
  }
});

class MultiCheckbox extends Component {
  state = {
    options: [],
    showValue: false
  };

  componentDidMount() {
    this.setState({ options: this.props.value });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.options, this.props.value)) {
      this.setState({ options: this.props.value });
    }
  }

  globalChange = (value) => this.props.onChange(this.props.name, value);

  handleChange = (index, value) => {
    let optionValues = [...this.state.options];
    if (value && !optionValues.includes(this.props.options[index].optionVal)) {
      optionValues.push(this.props.options[index].optionVal);
    } else if (optionValues.includes(this.props.options[index].optionVal)) {
      optionValues = optionValues.filter(
        (option) => option !== this.props.options[index].optionVal
      );
    }
    this.setState({ options: optionValues });
    this.globalChange(optionValues);
  };

  renderOption = (index) => {
    const { optionVal } = this.props.options[index];

    return (
      <FormControlLabel
        key={optionVal}
        control={
          <Checkbox
            name={`${this.props.name}[]`}
            // label={this.props.options[index]['label']}
            value={this.state.options.includes(optionVal)}
            onChange={(name, value) => this.handleChange(index, value)}
          />
        }
        label={this.props.options[index].label}
        style={{ marginLeft: '15px', marginRight: '30px' }}
      />
    );
  };

  renderOptions = () =>
    this.props.options.map((inputVal, index) => this.renderOption(index));

  renderInput = (classes) => (
    <FormControl variant="standard" className={classes.formControl}>
      <FormLabel component="legend">{this.props.label}</FormLabel>
      <ShowMore
        maxHeight={120}
        onChange={() => this.setState({ showValue: !this.state.showValue })}
        button={
          <Button variant="outlined">
            {this.state.showValue ? 'Show less options' : 'Show more options'}
          </Button>
        }
      >
        <FormGroup row>{this.renderOptions()}</FormGroup>
      </ShowMore>
    </FormControl>
  );

  render() {
    const { classes, name } = this.props;
    return (
      <Grid key={name} container justifyContent="center" alignItems="center">
        <Grid item xs={12} className={classes.margin}>
          {this.renderInput(classes)}
        </Grid>
      </Grid>
    );
  }
}

MultiCheckbox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
};

export default withStyles(MultiCheckbox, styles);
