import Validation from '../../../../Utilities/ValidationHelper/Validation';
import { validationPayload } from '../../../../Utilities/ValidationHelper/validationHelpers';

export default class ValidateName extends Validation {
  static first = (value) => {
    if (value.length >= 30) {
      return validationPayload(
        false,
        'First name must be less than 30 characters long.'
      );
    }

    return validationPayload(true, '');
  };

  static last = (value) => {
    if (value.length >= 30) {
      return validationPayload(
        false,
        'Last name must be less than 30 characters long.'
      );
    }

    return validationPayload(true, '');
  };
}
