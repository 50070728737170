import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import cn from 'classnames';
import { keyframes } from 'tss-react';

class SuccessCheckmark extends Component {
  state = {
    loaded: false
  };

  componentDidMount() {
    // display checkmark animation after 1 second -
    setInterval(() => {
      this.setState({
        loaded: true
      });
    }, 1000);
  }

  render() {
    const { loaded } = this.state;
    const { checkmark, circleLoader, loadComplete } = this.props.classes;

    return (
      <div className={cn(circleLoader, loaded && loadComplete)}>
        <div
          className={checkmark}
          style={{ display: loaded ? 'block' : 'none' }}
        />
      </div>
    );
  }
}

const loaderSpin = keyframes`
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
`;

const checkmark = keyframes`
  0% {
      height: 0;
      width: 0;
      opacity: 1;
  }
  20% {
      height: 0;
      width: 1.75em;
      opacity: 1;
  }
  40% {
      height: 3.5em;
      width: 1.75em;
      opacity: 1;
  }
  100% {
      height: 3.5em;
      width: 1.75em;
      opacity: 1;
  }
`;

const styles = () => ({
  circleLoader: {
    marginBottom: '3.5em',
    border: '3px solid rgba(0, 0, 0, 0.2)',
    borderLeftColor: '#5cb85c',
    animation: `${loaderSpin} 1.2s infinite linear`,
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'top',
    borderRadius: '50%',
    width: '7em',
    height: '7em'
  },
  loadComplete: {
    animation: 'none',
    borderColor: '#5cb85c',
    transition: 'border 500ms ease-out'
  },
  checkmark: {
    display: 'none',

    '&:after': {
      content: '""',
      position: 'absolute',
      top: '3.5em',
      left: '1.75em',
      opacity: 1,
      height: '3.5em',
      width: '1.75em',
      borderRight: '4px solid #5cb85c',
      borderTop: '4px solid #5cb85c',
      animationDuration: '800ms',
      animationTimingFunction: 'ease',
      animationName: checkmark,
      transform: 'scaleX(-1) rotate(135deg)',
      transformOrigin: 'left top'
    }
  }
});

export default withStyles(SuccessCheckmark, styles);
