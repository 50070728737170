import Validation from '../../../../Utilities/ValidationHelper/Validation';

export const validateEndDate = (value, start_date) => {
  const result = { ...Validation.defaultReturn };
  if (start_date === '') {
    return result;
  }
  const isBeforeStart = new Date(start_date) > new Date(value);
  if (isBeforeStart) {
    result.valid = false;
    result.message = 'Value for End Date can not be early than the Start Date.';
  }
  return result;
};

export default class ValidateAdvertiserRevenue extends Validation {
  static revenue = (value) => {
    const result = { ...Validation.defaultReturn };
    if (!value || value < 0) {
      result.valid = false;
      result.message = 'Value for Revenue cannot be less than 0.';
    }
    return result;
  };

  static date = (value) => {
    const result = { ...Validation.defaultReturn };
    if (!value) {
      result.valid = false;
      result.message = 'Please enter a valid date.';
    }

    if (Number.isNaN(Date.parse(value))) {
      result.valid = false;
      result.message = 'Please enter a valid date.';
    }

    const isFutureDate = new Date(value) > new Date();
    if (isFutureDate) {
      result.valid = false;
      result.message = 'Value for Date can not be a future date.';
    }
    return result;
  };
}
