import {
  siteOptions,
  formOptions,
  getDevices,
  getPlatforms,
  affiliateID,
  getTrafficSources
} from '../../../common/commonRequests';

export const filters = () => ({
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions(),
    value: '2',
    defaultVal: '2',
    inputWidth: 3
  },
  affiliateID: {
    name: 'affiliateID',
    type: 'autocomplete',
    label: 'Affiliate',
    options: () => affiliateID(),
    value: 'all',
    inputWidth: 3
  },
  trafficSource: {
    name: 'trafficSource',
    type: 'select',
    label: 'Traffic Source',
    defaultVal: '0',
    value: '0',
    inputWidth: 3,
    options: () => getTrafficSources()
  },
  formID: {
    name: 'formID',
    type: 'select',
    label: 'Form',
    options: (dependency) => formOptions(dependency, '0', false, false),
    value: '8',
    inputWidth: 3,
    dependencyName: 'site'
  },
  platform_device: {
    name: 'platform_device',
    type: 'select',
    label: 'Device',
    options: () => getDevices(true),
    value: 0,
    inputWidth: 3
  },
  platform: {
    name: 'platform',
    type: 'select',
    label: 'Platform',
    options: (dependency) => getPlatforms(dependency),
    value: 0,
    inputWidth: 3,
    dependencyName: 'platform_device'
  }
});
