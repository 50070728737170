import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DatePicker from './DatePicker';
import { estTimeZoneDate, currentTimeZoneDate } from './constants';
import { handleChange } from './actions/actions';
import { saveToLocalStorage } from '../Utilities/saveStateHelper';
import { getLocalStorageDateRangeValues } from './Utilities/getLocalStorageDateRangeValues';

class DatePickerContainer extends Component {
  componentDidMount() {
    this.onChange(getLocalStorageDateRangeValues());
  }

  onChange = ({ dateRange, startDate, endDate, weekDay = 'all' }) => {
    this.setValue('dateRange', dateRange);
    this.setValue('startDate', startDate);
    this.setValue('endDate', endDate);
    this.setValue('peak', weekDay);
  };

  setValue = (name, value) => {
    this.props.handleChange(name, value);
    const { selectedPage } = this.props;
    if (selectedPage !== 'week_over_week_report') {
      saveToLocalStorage(name, value);
    }
  };

  componentDidUpdate(prevProps) {
    const { selectedPage, dateRange } = this.props;

    const newDateRange = { ...(dateRange ?? {}) };

    if (
      selectedPage !== prevProps.selectedPage &&
      dateRange.dateRange.includes('today')
    ) {
      if (
        !moment(newDateRange.startDate).isSame(
          estTimeZoneDate !== currentTimeZoneDate
            ? moment().add(1, 'days')
            : moment(),
          'day'
        ) &&
        !moment(newDateRange.endDate).isSame(
          estTimeZoneDate !== currentTimeZoneDate
            ? moment().add(1, 'days')
            : moment(),
          'day'
        )
      ) {
        newDateRange.startDate =
          estTimeZoneDate !== currentTimeZoneDate
            ? moment().add(1, 'days')
            : moment();
        newDateRange.endDate =
          estTimeZoneDate !== currentTimeZoneDate
            ? moment().add(1, 'days')
            : moment();

        this.setValue('dateRange', newDateRange.dateRange);
        this.setValue('startDate', newDateRange.startDate);
      }
    }
  }

  render() {
    const {
      dateRange,
      hideBigRanges,
      newRanges,
      dateRangeLimit,
      specialRange,
      hideAllTime
    } = this.props;

    const newDateRange = { ...(dateRange ?? {}) };

    if (
      hideBigRanges &&
      (newDateRange.dateRange.includes('month') ||
        newDateRange.dateRange.includes('all') ||
        newDateRange.dateRange.includes('seven_days'))
    ) {
      newDateRange.dateRange = 'today';
      newDateRange.startDate = moment();
      newDateRange.endDate = moment();
      this.setValue('dateRange', newDateRange.dateRange);
      this.setValue('startDate', newDateRange.startDate);
      this.setValue('endDate', newDateRange.endDate);
    }
    if (specialRange && !newDateRange.dateRange.includes('weeks')) {
      newDateRange.dateRange = 'weeks4';
      newDateRange.startDate = moment().subtract(4, 'weeks').isoWeekday(7);
      newDateRange.endDate = moment();
      this.setValue('dateRange', newDateRange.dateRange);
      this.setValue('startDate', newDateRange.startDate);
      this.setValue('endDate', newDateRange.endDate);
    }
    if (!specialRange && newDateRange.dateRange?.includes?.('weeks')) {
      newDateRange.dateRange = 'today';
      newDateRange.startDate = moment();
      newDateRange.endDate = moment();
      this.setValue('dateRange', newDateRange.dateRange);
      this.setValue('startDate', newDateRange.startDate);
      this.setValue('endDate', newDateRange.endDate);
    }
    const hideablePages = [
      'clickPurchases',
      'hourlyClickPurchases',
      'amb_state_report',
      'amb_hourly_state_report'
    ];
    return this.props.hide &&
      hideablePages.includes(this.props.selectedPage) ? (
      ''
    ) : (
      <DatePicker
        startDate={newDateRange.startDate}
        endDate={newDateRange.endDate}
        dateRange={newDateRange.dateRange}
        peakSelected={newDateRange.peak}
        filterByPeak
        onChange={this.onChange}
        hideBigRanges={hideBigRanges || false}
        newRanges={newRanges || false}
        dateRangeLimit={dateRangeLimit || false}
        specialRange={specialRange || false}
        hideAllTime={hideAllTime || false}
      />
    );
  }
}

export default connect(
  (state) => ({
    dateRange: state.dateRange,
    hide: state.dateRange.hide,
    selectedPage: state.pageReducer.selectedPage
  }),
  (dispatch) =>
    bindActionCreators(
      {
        handleChange
      },
      dispatch
    )
)(DatePickerContainer);
