/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField as Field,
  Typography
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import Accordion from '@mui/material/Accordion';
import { Info } from '@mui/icons-material';
import { colors } from '../../../Utilities/LenoxColors';

const styles = (theme) => ({
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    flexBasis: 200,
    width: '100%',
    color: `${colors.lenoxLight1}!important`
  }
});

class FileUpload extends Component {
  render() {
    const { name, classes, exampleFormat, example } = this.props;
    return (
      <Grid
        key={this.props.name}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} className={classNames(this.props.classes.margin)}>
          <Field
            label={this.props.label}
            fullwidth={this.props.fullwidth}
            error={this.props.error}
            id={this.props.name}
            className={classNames(classes.textField)}
            variant="outlined"
            helperText={this.props.message || ''}
            type="file"
            margin="dense"
            InputLabelProps={{ shrink: true }}
            onChange={({ target: { files } }) =>
              this.props.onChange(name, files[0])
            }
          />
          {exampleFormat ? (
            <Accordion>
              <AccordionSummary
                expandIcon={<Info />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Example Format</Typography>
              </AccordionSummary>
              <AccordionDetails>{example}</AccordionDetails>
            </Accordion>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(FileUpload, styles);

FileUpload.propTypes = {
  onChange: PropTypes.func.isRequired
};
