/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { uniq } from 'lodash';
import Section from './Section';

const createSectionTitleArray = (sections, pageOrder) => {
  let result = [];
  const sectionValues = Object.values(sections);
  sectionValues.forEach(({ collapsableSectionTitle = [] }) => {
    collapsableSectionTitle.forEach((title) => {
      if (result.indexOf(title) === -1) {
        result.push(title);
      }
    });
  });
  result = uniq([...pageOrder, ...result]);
  return result;
};

const formatSections = (sections, titles) => {
  const result = {};
  titles.forEach((sectionTitle) => {
    Object.keys(sections).forEach((section) => {
      if (sections[section].collapsableSectionTitle.includes(sectionTitle)) {
        if (result[sectionTitle]) {
          result[sectionTitle][section] = sections[section];
        } else {
          result[sectionTitle] = {};
          result[sectionTitle][section] = sections[section];
        }
      }
    });
  });
  return result;
};

function CollapsableNavContainer(props) {
  const { sections, pageOrder } = props;

  const [sectionTitles, setSectionTitles] = useState([]);
  const [formattedSections, setFormattedSections] = useState({});

  useEffect(() => {
    const titles = createSectionTitleArray(sections, pageOrder);
    setSectionTitles(titles);
    setFormattedSections(formatSections(sections, titles));
    // eslint-disable-next-line
  }, []);

  return (
    <span>
      {sectionTitles.map((section, index) => (
        <Section
          key={`${index + 1}`}
          title={section}
          section={formattedSections[section]}
        />
      ))}
    </span>
  );
}

CollapsableNavContainer.propTypes = {
  sections: PropTypes.object.isRequired,
  pageOrder: PropTypes.array.isRequired
};

export default CollapsableNavContainer;
