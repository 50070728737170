/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import { IconButton } from '@mui/material';
import { Replay as ReplayIcon } from '@mui/icons-material';
import moment from 'moment';
import Checkbox from '../FormContainer/FormComponents/Checkbox';
import { colors } from '../../Utilities/LenoxColors';

const styles = {
  textStyle: {
    color: colors.lenoxLight1,
    fontSize: 12,
    textAlign: 'right'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
    paddingRight: 5
  },
  refreshIcon: {
    color: colors.lenoxSuccess1,
    width: '1.3em',
    height: '1.3em'
  }
};

export const renderVisibilityCheckbox = (props) => (
  <div style={{ marginTop: 50 }}>
    <Checkbox
      value={props.hide_rows}
      name="hide_rows"
      label={props.text}
      labelInline
      onChange={props.handleHideShow}
    />
  </div>
);

export class LastUpdated extends Component {
  intervalID = null;

  state = {
    lastUpdatedText: '',
    updatedTime: moment()
  };

  isValidDate = moment(this.props.lastUpdated).isValid();

  componentDidMount() {
    this.isValidDate ? this.lastUpdatedDate() : this.checkUpdatedTime();
    this.intervalID = setInterval(
      this.isValidDate ? this.lastUpdatedDate : this.checkUpdatedTime,
      60000
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  lastUpdatedDate = () => {
    const currentTime = moment();
    const diff = currentTime.diff(this.props.lastUpdated, 'minutes');
    let text;
    if (diff < 1) {
      text = 'Now';
    } else if (diff < 60) {
      text = `${diff} Minutes Ago`;
    } else {
      text = `${moment(this.props.lastUpdated).format('LLL')}`;
    }
    this.setState({ lastUpdatedText: text });
  };

  checkUpdatedTime = () => {
    const currentTime = moment();
    const diff = currentTime.diff(this.state.updatedTime, 'minutes');
    let text;
    if (diff < 1) {
      text = 'Now';
    } else if (diff < 5) {
      text = 'Less than 5 Minutes Ago';
    } else {
      text = `${diff} Minutes Ago`;
    }
    this.setState({ lastUpdatedText: text });
  };

  render() {
    const { onReload } = this.props;

    return (
      <div style={styles.container}>
        <IconButton
          onClick={() => {
            onReload();
            this.setState({ updatedTime: moment() }, () =>
              this.isValidDate
                ? this.lastUpdatedDate()
                : this.checkUpdatedTime()
            );
          }}
          variant="contained"
          style={{ padding: 0, width: 20 }}
          size="large"
        >
          <ReplayIcon style={styles.refreshIcon} />
        </IconButton>
        <span style={{ ...styles.textStyle, marginLeft: 10 }}>
          Last Updated: {this.state.lastUpdatedText}
        </span>
      </div>
    );
  }
}
