import { lazy } from 'react';
import {
  checkAdmin,
  checkAgent,
  checkTech
} from '../../PermissionsWrappers/permissionChecks';
import { filters as transactionHistoryFilters } from '../../Agents/Components/TransactionHistory/filters/filters';
import { type IRoute } from '../../../Utilities/types';

const CustomerCriteria = lazy(
  () =>
    import('../../Agents/Components/CustomerCriteria/render/CustomerCriteria')
);
const TransactionHistory = lazy(
  () =>
    import(
      '../../Agents/Components/TransactionHistory/TransactionHistoryReport'
    )
);
const CompleteProfile = lazy(
  () => import('../../Agents/Components/CompletePerfil/render/CompleteProfile')
);
const Dashboard = lazy(
  () => import('../../Agents/Components/Dashboard/Dashboard')
);

const adminList = {
  customer_criteria: {
    section: 'Setup',
    displayName: 'Customer Criteria',
    content: <CustomerCriteria />
  },
  transaction_history: {
    section: 'Reporting',
    displayName: 'Transaction History',
    content: <TransactionHistory />,
    displayDateRange: true,
    filters: transactionHistoryFilters()
  },
  complete_profile: {
    section: 'Setup',
    displayName: 'Complete Profile',
    content: <CompleteProfile />
  }
};

const dashboard = {
  displayDateRange: true,
  displayName: 'Dashboard',
  content: <Dashboard />
};

const agentList = {
  dashboard,
  customer_criteria: adminList.customer_criteria,
  transaction_history: adminList.transaction_history,
  complete_profile: adminList.complete_profile
};

export type AgentListResultType =
  | Record<keyof typeof adminList, IRoute>
  | Record<keyof typeof agentList, IRoute>
  | Record<string, { content?: React.ReactNode; section?: string }>;

export const AgentsPageList = (): AgentListResultType => {
  if (checkAdmin() || checkTech()) {
    return adminList;
  }
  if (checkAgent()) {
    return agentList;
  }
  return {};
};

export const sections = ['Reporting', 'Setup'];
