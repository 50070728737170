import {
  createContext,
  memo,
  type PropsWithChildren,
  useState,
  useMemo,
  useEffect,
  useCallback
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createSelector } from 'reselect';
import { ReportingPageList } from '../../Components/NavBar/Reporting/ReportingPageList';
import { StatusPagePageList } from '../../Components/NavBar/StatusPage/StatusPagePageList';
import { SetupPageList } from '../../Components/NavBar/Setup/SetupPageList';
import { AccountReports } from '../../Components/NavBar/Accounting/AccountingPageList';
import { type IPageListElementType } from '../../Utilities/types/pageContainerTypes';
import { handleChange } from '../../Components/PageContainer/actions';
import { localStoragePageName } from '../../Components/PageContainer/PageContainer';
import { saveToLocalStorage } from '../../Components/Utilities/saveStateHelper';
import { AgentsPageList } from '../../Components/NavBar/Agents/AgentsPageList';
import { SettingsPageList } from '../../Components/NavBar/Settings/SettingsPageList';
import { ROUTE_NAMES } from '../../Components/NavBar/NavigationContainer';
import { useSubPages } from '../../Utilities/hooks/useSubPages';

interface IStore {
  pageReducer: { selectedPage: string; route: string };
}

type BreadCrumbType = Record<'name' | 'url' | 'route', string>;
type AllPagesType = Record<string, Record<string, IPageListElementType>>;

interface IBreadCrumbsContext {
  breadCrumbs: Array<BreadCrumbType>;
  goTobreadCrumb: (crumb: BreadCrumbType) => void;
}

const defaultValue: IBreadCrumbsContext = {
  breadCrumbs: [],
  goTobreadCrumb: () => {}
};

const dashboardItem = {
  displayName: ROUTE_NAMES.Dashboard,
  name: ROUTE_NAMES.Dashboard,
  url: ROUTE_NAMES.Dashboard,
  route: ROUTE_NAMES.Dashboard
};

export const BreadCrumbsContext =
  createContext<IBreadCrumbsContext>(defaultValue);

const MAX_BREADCRUMBS_SIZE = 3;

const selectedPageSelector = createSelector(
  (state: IStore) => {
    const { selectedPage, route } = state.pageReducer;

    return { selectedPage, route };
  },
  (obj) => obj
);

export const BreadCrumbsProvider = memo<PropsWithChildren>(({ children }) => {
  const [breadCrumbs, setBreadCrumbs] = useState<
    IBreadCrumbsContext['breadCrumbs']
  >([]);
  const { route: mainRoute } = useSubPages();

  const navigate = useNavigate();
  const { selectedPage, route } = useSelector(selectedPageSelector);
  const dispatch = useDispatch();

  const allPages = useMemo(() => {
    return {
      [ROUTE_NAMES.Reporting]: ReportingPageList(),
      [ROUTE_NAMES.Accounting]: AccountReports(),
      [ROUTE_NAMES.Setup]: SetupPageList(),
      [ROUTE_NAMES.Status]: StatusPagePageList(),
      [ROUTE_NAMES.Agents]: AgentsPageList(),
      [ROUTE_NAMES.Settings]: SettingsPageList()
    } as AllPagesType;
  }, []);

  useEffect(() => {
    const isDashboard = mainRoute === ROUTE_NAMES.Dashboard;

    if (!selectedPage && !isDashboard) {
      return;
    }

    const selectedBreadCrumb = isDashboard
      ? dashboardItem
      : allPages[route]?.[selectedPage];

    if (!selectedBreadCrumb) {
      return;
    }

    setBreadCrumbs((prevState) => {
      const newBreadCrumbs = [...prevState];

      const breadCrumbIndex = newBreadCrumbs.findIndex(
        (page) => page.name === (selectedBreadCrumb?.displayName || route)
      );

      if (breadCrumbIndex !== -1) {
        const selectedBreadCrumb = newBreadCrumbs[breadCrumbIndex];

        newBreadCrumbs.splice(breadCrumbIndex, 1);

        return [...newBreadCrumbs, selectedBreadCrumb];
      }

      const isFull = newBreadCrumbs.length === MAX_BREADCRUMBS_SIZE;

      if (isFull) {
        newBreadCrumbs.shift();
      }

      return [
        ...newBreadCrumbs,
        {
          name: selectedBreadCrumb.displayName || route,
          url: isDashboard ? dashboardItem.url : selectedPage,
          route: isDashboard ? dashboardItem.route : route
        }
      ];
    });
  }, [allPages, mainRoute, route, selectedPage]);

  const goTobreadCrumb = useCallback(
    ({ url, name, route }: BreadCrumbType) => {
      if (route === ROUTE_NAMES.Dashboard) {
        navigate(`/${route}`);

        return;
      }

      dispatch(handleChange(localStoragePageName, url));
      dispatch(handleChange('route', route));
      saveToLocalStorage(`${route}${localStoragePageName}`, name);
      navigate(`/${route}/${url}`);

      setBreadCrumbs((prevState) => {
        const newBreadCrumbs = [...prevState];
        const selectedBreadCrumbIndex = newBreadCrumbs.findIndex(
          (crumb) => crumb.url === url
        );

        if (selectedBreadCrumbIndex === -1) {
          return newBreadCrumbs;
        }

        const selectedCrumb = newBreadCrumbs[selectedBreadCrumbIndex];

        newBreadCrumbs.splice(selectedBreadCrumbIndex, 1);

        return [...newBreadCrumbs, selectedCrumb];
      });
    },

    [dispatch, navigate]
  );

  const value = useMemo(() => {
    return {
      breadCrumbs,
      goTobreadCrumb
    };
  }, [breadCrumbs, goTobreadCrumb]);

  return (
    <BreadCrumbsContext.Provider value={value}>
      {children}
    </BreadCrumbsContext.Provider>
  );
});
