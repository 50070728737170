import { siteOptions } from '../../../common/commonRequests';

export const filters = () => ({
  affiliateSite: {
    name: 'affiliateSite',
    type: 'select',
    label: 'Vertical',
    options: () =>
      siteOptions(false, false, false, [], {
        value: 'none',
        display: 'Choose a Vertical',
        disabled: true
      }),
    value: 'none',
    defaultVal: 'none',
    inputWidth: 6
  }
});
