/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { LinearProgress } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import { colors } from '../../Utilities/LenoxColors';
import Logo from '../../assets/images/otto_ai_logo.png';

const styles = () => ({
  bar1: {
    backgroundColor: colors.lenoxSuccess1
  },
  bar2: {
    backgroundColor: colors.lenoxDark2
  },
  logo: {
    width: '12rem',
    marginBottom: '3rem'
  }
});

class LoaderComponent extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div
        style={{
          textAlign: 'center',
          margin: '10px auto 10px auto',
          width: `${this.props.width}%`,
          padding: `${this.props.padding}px 0`
        }}
      >
        <img src={Logo} className={classes.logo} alt="" />
        <LinearProgress
          className={classes.bar2}
          classes={{
            bar1Indeterminate: classes.bar1,
            bar2Indeterminate: classes.bar1
          }}
        />
      </div>
    );
  }
}

LoaderComponent.propTypes = {
  padding: PropTypes.number.isRequired,
  width: PropTypes.number
};

LoaderComponent.defaultProps = {
  width: 50
};

export default withStyles(LoaderComponent, styles);
