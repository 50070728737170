/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, IconButton } from '@mui/material';
import { Clear, Done } from '@mui/icons-material';
import { TextField } from '../../../FormContainer/FormComponents';

class EditReasonsField extends Component {
  static propTypes = {
    row: PropTypes.object.isRequired,
    submitReason: PropTypes.func.isRequired
  };

  state = {
    originalValue: this.props.row.reason,
    value: this.props.row.reason,
    saving: false
  };

  componentDidUpdate() {
    const { reason: nextValue } = this.props.row;
    const { originalValue } = this.state;
    if (originalValue !== nextValue) {
      this.setState({ value: nextValue, originalValue: nextValue });
    }
  }

  handleChange = (value) => this.setState({ value });

  handleSubmitReason = async (row) => {
    this.setState({ saving: true });
    await this.props.submitReason(row, this.state.value);
    this.setState({ saving: false });
  };

  renderValueSubmit = (row) => () =>
    (
      <>
        <IconButton
          onClick={() => {
            this.handleSubmitReason(row);
          }}
          size="large"
        >
          {this.state.saving ? <CircularProgress size={20} /> : <Done />}
        </IconButton>
        <IconButton
          onClick={() => this.setState({ value: this.state.originalValue })}
          size="large"
        >
          <Clear />
        </IconButton>
      </>
    );

  render() {
    const { value, originalValue } = this.state;
    return (
      <TextField
        value={value}
        key="reason"
        name="reason"
        includeSubmit={originalValue !== value}
        onChange={(name, value) => this.handleChange(value)}
        submitRender={this.renderValueSubmit(this.props.row)}
        style={{ height: '2rem', fontSize: '14px' }}
        fieldGrid={8}
        buttonsGrid={4}
      />
    );
  }
}

export default EditReasonsField;
