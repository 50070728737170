import { buyerList, statesList } from '../../../../common/commonRequests';
import { defaultInputProps } from '../../../../FormContainer/defaultFormStates';

export const filters = () => ({
  // site: {
  //   name: 'site',
  //   type: 'select',
  //   label: 'Vertical',
  //   options: () => siteOptions(true),
  //   value: '0',
  //   inputWidth: 3,
  // },
  includedStates: {
    ...defaultInputProps,
    name: 'includedStates',
    type: 'multiselect',
    label: 'States',
    options: () => statesList(true),
    value: ['all'],
    inputWidth: 3
  },
  buyers: {
    name: 'buyers',
    type: 'select',
    label: 'Buyer',
    options: () => buyerList(2, false, false, false, true),
    value: 'all',
    inputWidth: 3
  }
});
