/* eslint-disable no-param-reassign */
export const comparePriority = (a, b) => {
  a = a === '' ? -100000000 : a;
  b = b === '' ? -100000000 : b;

  if (typeof a === 'string') {
    a = parseFloat(a.replace(/[|&;$%@"<>()+,]/g, ''));
  }
  if (typeof b === 'string') {
    b = parseFloat(b.replace(/[|&;$%@"<>()+,]/g, ''));
  }
  return a - b;
};
