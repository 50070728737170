import {
  affiliateID,
  buyerList,
  siteOptions
} from '../../../common/commonRequests';
import { DEVICES, FORM_DATA_OPTIONS } from '../../../common/dropdownConstants';

export const filters = () => ({
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions(true),
    value: 0,
    defaultVal: 0
  },
  affiliateID: {
    name: 'affiliateID',
    type: 'autocomplete',
    label: 'Affiliate',
    options: () => affiliateID(),
    value: 'all'
  },
  buyer: {
    name: 'buyer',
    label: 'Buyers',
    type: 'select',
    options: (site) =>
      buyerList(site, false, 2, false, true, false, false, false),
    value: 'all',
    dependencyName: 'site'
  },
  partial: {
    name: 'partial',
    type: 'select',
    label: 'Form Type',
    options: () => FORM_DATA_OPTIONS,
    value: 'all'
  },
  device: {
    name: 'device',
    type: 'select',
    label: 'Device',
    options: () => DEVICES,
    value: 'all'
  }
});
