/* eslint-disable no-param-reassign */
export const buildFormFields = (fields, state) => {
  Object.keys(fields).map((fieldName) => {
    fields[fieldName].dependency =
      (state.formValues[fields[fieldName].dependencyName] &&
        state.formValues[fields[fieldName].dependencyName].value) ||
      false;
    fields[fieldName].value = state.formValues[fieldName].value;
    return true;
  });
  return fields;
};

export const buildFormFieldsWithStateForm = (fields, stateForm) => {
  Object.keys(fields).map((fieldName) => {
    fields[fieldName].dependency =
      (stateForm[fields[fieldName].dependencyName] &&
        stateForm[fields[fieldName].dependencyName].value) ||
      false;
    fields[fieldName].value = stateForm[fieldName].value;
    return true;
  });
  return fields;
};
