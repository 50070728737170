/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-return-await */
/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import { Grid, Button } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import FormContainer from '../../../../../../../FormContainer/FormContainer';
import { editBuyerCapState } from '../../../defaultState/state';
import { affiliateID } from '../../../../../../../common/commonRequests';
import { formatAffiliateValue } from '../../../../../../../Utilities/getAffiliateIDFromName';
import { handleFormObjectChange } from '../../../../../../../Utilities/HandleFormObjectChange';
import { validateForSubmit } from '../../../../../../../Utilities/ValidationHelper/ValidateForm';
import { formatSubmitValues } from '../../../../../../../Utilities/SubmitHelper/FormatForSubmit';
import ValidateAddBuyerAffiliateCap from '../validation/validation';
import { defaultInputProps } from '../../../../../../../FormContainer/defaultFormStates';
import AddBuyerAffiliateTable from './BuyerAffiliateCapTable';
import { colors } from '../../../../../../../../Utilities/LenoxColors';
import { NetworkRequest } from '../../../../../../../Utilities/NetworkRequests/NetworkRequests';
import { CircularProgress } from '../../../../../../../Utilities/CircularProgress';

const styles = () => ({
  sectionContainer: {
    border: 'solid 1px rgba(20,20,20,.3)',
    borderRadius: '8px',
    margin: '1rem auto'
  },
  sectionTitle: {
    fontSize: '1.25rem',
    textAlign: 'center',
    padding: '.5rem',
    backgroundColor: colors.lenoxDark2,
    color: colors.lenoxLight1,
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px'
  },
  section: { padding: '2rem' },
  container: { textAlign: 'center', paddingBottom: '5rem' },
  hr: { margin: '1rem' },
  header: {},
  addAffiliateButton: {
    backgroundColor: colors.lenoxDark2,
    margin: '1rem',
    color: 'white'
  }
});

const setFormAffiliates = (filteredAffiliates) => ({
  ...defaultInputProps,
  name: 'affiliate_id',
  type: 'autocomplete',
  label: 'Affiliate',
  validate: ValidateAddBuyerAffiliateCap.validateAffiliateDropdown,
  options: () => filteredAffiliates,
  value: 'Select an Affiliate'
});

const setFormDenylist = () => ({
  ...defaultInputProps,
  name: 'is_denylist',
  type: 'checkbox',
  label: 'Denylist Affiliate?',
  value: false
});

const createNewBuyerAffiliateCapState = (
  formValues,
  affiliates,
  selectedAffiliates
) => {
  affiliates.splice(0, 1);
  const filteredAffiliates = affiliates.filter(
    (affiliate) => !selectedAffiliates.includes(affiliate.value)
  );
  filteredAffiliates.unshift({
    value: '-1',
    display: 'Select an Affiliate'
  });

  formValues.affiliate_id = setFormAffiliates(filteredAffiliates);
  formValues.is_denylist = setFormDenylist();
  return formValues;
};

class AddBuyerAffiliateCap extends Component {
  state = {
    formValues: editBuyerCapState(),
    submitting: false,
    hideAddAffiliateForm: true,
    affiliates: [],
    selectedAffiliates: [],
    tableLoaded: false,
    tableData: [],
    dialog: {
      message: '',
      type: 'error',
      title: 'Error'
    }
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.loadTableData().then(() => this.loadFormData());
  };

  loadTableData = async () => {
    const tableLoaded = true;
    await NetworkRequest('buyerAffidConfig', {
      action: 'get',
      buyer_name: this.props.row.buyer
    }).then(async ({ data } = {}) => {
      if (data && typeof data === 'object') {
        data.map((row) => {
          row.affiliate_id = row.affid;
          row.start_time = row.working_hours_start;
          row.end_time = row.working_hours_end;
          return true;
        });
        this.setState({ tableData: data, tableLoaded });
      } else {
        this.setState({ tableData: [], tableLoaded });
      }
    });
  };

  loadFormData = async () =>
    await this.loadAffiliates()
      .then(() => this.setSelectedAffiliates())
      .then(() => this.reloadFormValues());

  setSelectedAffiliates = async () => {
    const { tableData } = this.state;
    const selectedAffiliates = [];
    tableData.map((row) => {
      selectedAffiliates.push(row.affiliate_id);
      return true;
    });
    this.setState({ selectedAffiliates });
  };

  loadAffiliates = async () => {
    const affiliates = await affiliateID();
    if (affiliates && typeof affiliates === 'object') {
      await new Promise((resolve) => {
        this.setState({ affiliates }, () => resolve());
      });
    } else {
      this.toggleDialog('We were unable to load affiliates to add rules to.');
    }
  };

  reloadFormValues = () => {
    const { formValues, affiliates, selectedAffiliates } = this.state;
    if (affiliates && typeof affiliates === 'object' && affiliates.length > 0) {
      const formValuesWithAffiliates = createNewBuyerAffiliateCapState(
        formValues,
        affiliates,
        selectedAffiliates
      );
      this.setState({ formValues: formValuesWithAffiliates });
    }
  };

  handleChange = (field, value) => {
    const formValues = handleFormObjectChange(field, value, {
      ...this.state.formValues
    });
    if (field === 'is_denylist') {
      formValues.lead_cap.hidden = !!value;
      formValues.lead_cap.value = value ? '-1' : '0';
      formValues.start_time.hidden = !!value;
      formValues.start_time.value = value ? null : 'none';
      formValues.end_time.hidden = !!value;
      formValues.end_time.value = value ? null : 'none';
    }
    this.setState({ formValues });
  };

  toggleDialog = (message = '', type = 'error', title = 'Error') =>
    this.setState({
      dialog: { ...this.state.dialog, message, type, title }
    });

  handleSubmit = () => {
    const { valid, messages } = validateForSubmit(this.state.formValues);

    if (!valid) {
      this.toggleDialog(messages, 'error');
      return false;
    }
    this.handleSubmitRequest();
  };

  resetForm = async () => {
    this.setState({ formValues: editBuyerCapState() });
  };

  formatSubmitAffiliate = (submitData) => {
    const { row } = this.props;

    return {
      ...submitData,
      lead_cap: parseInt(submitData.lead_cap, 10),
      affid: formatAffiliateValue(submitData.affiliate_id ?? ''),
      working_hours_end: submitData.end_time,
      working_hours_start: submitData.start_time,
      action: 'insert',
      buyer_name: row.buyer
    };
  };

  handleSubmitRequest = () => {
    const { formValues, tableData } = this.state;
    const submitData = formatSubmitValues(formValues);
    const params = this.formatSubmitAffiliate(submitData);
    this.setState({ submitting: true }, () =>
      NetworkRequest('buyerAffidConfig', { ...params })
        .then(({ data } = {}) =>
          this.handleResponse(data, submitData, tableData)
        )
        .catch(() => this.setState({ submitting: false }))
    );
  };

  requestRemoveAffiliate = async (affiliate_id, database_id) => {
    if (database_id) {
      await NetworkRequest('buyerAffidConfig', {
        action: 'delete',
        id: parseInt(database_id, 10)
      }).then(({ data }) => {
        if (data.success.toString() === 'true') {
          this.removeAffiliateFromTable(affiliate_id);
        }
      });
    }
    return true;
  };

  removeAffiliateFromTable = (affiliate_id) => {
    let removeIndex;
    const { tableData } = JSON.parse(JSON.stringify(this.state));

    tableData.map((row, index) => {
      if (row.affiliate_id.toString() === affiliate_id.toString()) {
        removeIndex = index;
      }
      return true;
    });
    if (removeIndex !== undefined) {
      tableData.splice(removeIndex, 1);
      this.setState({ tableData }, () =>
        this.setSelectedAffiliates().then(() => this.reloadFormValues())
      );
    }
  };

  handleResponse = (response) => {
    this.setState({ submitting: false });
    if (response && response.success === true) {
      this.toggleDialog('Cap successfully set', 'success', 'Success');
      this.loadData();
      this.resetForm();
    } else {
      this.toggleDialog(
        response?.message ?? 'An error has happened',
        'error',
        'Error'
      );
    }
  };

  buildFormValues = (fields) => {
    return fields;
  };

  renderAddAffiliateButton = () => (
    <Button
      variant="contained"
      className={this.props.classes.addAffiliateButton}
      onClick={() => this.setState({ hideAddAffiliateForm: false })}
    >
      Add an Affiliate Cap
    </Button>
  );

  renderSectionContainer = (title, content) => (
    <Grid item xs={10} className={this.props.classes.sectionContainer}>
      <div className={this.props.classes.sectionTitle}>{title}</div>
      <div className={this.props.classes.section}>{content}</div>
    </Grid>
  );

  renderAddAffiliateForm = (formValues, submitting, dialog) => (
    <FormContainer
      formTitle="Add Affiliate Cap"
      formValues={this.buildFormValues(formValues)}
      submitting={submitting}
      handleChange={this.handleChange}
      handleSubmit={this.handleSubmit}
      inputWidth={8}
      separateBlock
      dialog={dialog}
      toggleDialog={this.toggleDialog}
    />
  );

  renderAddAffiliate = (formValues, submitting, dialog, hideAddAffiliateForm) =>
    hideAddAffiliateForm
      ? this.renderAddAffiliateButton()
      : this.renderSectionContainer(
          'Add Affiliate Cap',
          this.renderAddAffiliateForm(formValues, submitting, dialog)
        );

  renderAffiliateTable = (tableData) =>
    tableData.length > 0 &&
    this.renderSectionContainer(
      'Existing Affiliate Caps',
      <AddBuyerAffiliateTable
        data={tableData}
        requestRemoveAffiliate={this.requestRemoveAffiliate}
      />
    );

  render() {
    const {
      formValues,
      submitting,
      dialog,
      hideAddAffiliateForm,
      tableData,
      tableLoaded
    } = this.state;
    const { classes } = this.props;

    return (
      <Grid className={classes.container}>
        <hr className={classes.hr} />
        <h3 className={classes.header}>Affiliate Caps</h3>
        {tableLoaded &&
          this.renderAddAffiliate(
            formValues,
            submitting,
            dialog,
            hideAddAffiliateForm
          )}
        {tableLoaded
          ? this.renderAffiliateTable(tableData)
          : CircularProgress()}
      </Grid>
    );
  }
}

export default withStyles(AddBuyerAffiliateCap, styles);
