import { affiliateID, buyerList } from '../../../common/commonRequests';

const type = [
  { value: 'all', display: 'All' },
  { value: 'AllState', display: 'AllState' },
  { value: 'Experian', display: 'Experian' },
  { value: 'Farmers', display: 'Farmers' },
  { value: 'Liberty Mutual', display: 'Liberty Mutual' },
  { value: 'Progressive', display: 'Progressive' },
  { value: 'State Farm', display: 'State Farm' },
  { value: 'Kissterra', display: 'Kissterra' },
  { value: 'Provide', display: 'Provide' },
  { value: 'Savvy', display: 'Savvy' },
  { value: 'Other', display: 'Other' }
];

export const filters = () => ({
  affiliateID: {
    name: 'affiliateID',
    type: 'autocomplete',
    label: 'Affiliate',
    options: () => affiliateID(),
    value: 'all'
  },
  buyer: {
    name: 'buyer',
    label: 'Buyers',
    type: 'select',
    options: () => buyerList(2, false, 2, false, true),
    value: 'all'
  },
  carriers: {
    name: 'carriers',
    type: 'select',
    label: 'Carriers',
    options: () => type,
    value: 'all'
  }
});
