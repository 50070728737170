const lookbackDayOptions = [
  { value: 'custom', display: 'Custom Date Range' },
  { value: 'all', display: 'Weekdays + Weekends' },
  { value: '1', display: 'Weekdays Only' },
  { value: '2', display: 'Weekends Only' }
];

export const filters = () => ({
  lookbackDayCriteriaState: {
    name: 'lookbackDayCriteriaState',
    type: 'select',
    label: 'Lookback Day Criteria',
    options: () => lookbackDayOptions,
    value: 'all'
  },
  lookbackDaysState: {
    name: 'lookbackDaysState',
    type: 'input',
    label: 'Look back Days',
    value: '1',
    placeholder: 'Please Select Number of Look Back Days'
  }
});
