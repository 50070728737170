import Validation from '../../../../Utilities/ValidationHelper/Validation';
import {
  validationPayload,
  validateStringExists
} from '../../../../Utilities/ValidationHelper/validationHelpers';
import Stack from '../../../../../Utilities/Stack';

const correspondingClosingChar = (char) => {
  if (char === '{') return '}';
  if (char === '[') return ']';
  if (char === '(') return ')';
};

function balancedGrouping(value, valid) {
  if (!valid) {
    return valid;
  }
  const stack = new Stack();
  const openingCharacters = ['{', '(', '['];
  const closeningCharacters = ['}', ')', ']'];
  const quotesCount = (value.match(/'/g) || []).length;
  if (quotesCount % 2 !== 0) return false;
  const doubleQuotesCount = (value.match(/"/g) || []).length;
  if (doubleQuotesCount % 2 !== 0) return false;
  const hashTagsCount = (value.match(/#/g) || []).length;
  if (hashTagsCount % 2 !== 0) return false;
  for (let i = 0; i < value.length; i += 1) {
    if (openingCharacters.includes(value.charAt(i))) {
      stack.push(value.charAt(i));
    }
    if (closeningCharacters.includes(value.charAt(i))) {
      const onTop = stack.pop();
      if (correspondingClosingChar(onTop) !== value.charAt(i)) {
        return false;
      }
    }
  }
  return stack.size === 0;
}

function balancedTags(value, valid) {
  if (!valid) {
    return valid;
  }
  const stack = new Stack();
  const selfClosing = [
    '<img',
    '<area',
    '<base',
    '<br',
    '<embed',
    '<hr',
    '<iframe',
    '<input',
    '<link',
    '<meta',
    '<param',
    '<source',
    '<track'
  ];
  const reg = /<(.*?)>/g;
  const textAttributes = /^(\s*\D*(\w+\s*=\s*('(\S+)'|"(\S+)"))|\w*\s*)*$/;
  const matches = Array.from(value.matchAll(reg));
  for (let i = 0; i < matches.length; i += 1) {
    const tag = matches[i][1];
    if (tag.charAt(0) === '/') {
      const onTop = stack.pop();
      const withOutTag = `/${onTop}`.replace(tag, '');
      if (`/${onTop}` !== tag && !textAttributes.test(withOutTag)) {
        return false;
      }
    } else if (
      !selfClosing.some((selfClosingTag) =>
        matches[i][0].includes(selfClosingTag)
      )
    ) {
      stack.push(tag);
    }
  }
  return stack.size === 0;
}

const removeComments = (value) => {
  const regex = /<!--(.*?)-->/g;
  return value.replace(regex, '');
};

const validatePixel = (value) =>
  validateStringExists(value) &&
  value.trimStart().charAt(0) === '<' &&
  value.trimEnd().charAt(value.trimEnd().length - 1) === '>';
const validateJSPixel = (value, valid) =>
  valid &&
  validateStringExists(value) &&
  /<script/.test(value) &&
  /<\/script>/.test(value);
const validateImgPixel = (value, valid) =>
  valid && validateStringExists(value) && /<img/.test(value);
const validateTokenDelimiters = (value) => {
  const regex =
    /\w+=({(\w+|\d+\.\d{2})}|\[(\w+|\d+\.\d{2})\]|\((\w+|\d+\.\d{2})\))/;
  return validateStringExists(value) && !regex.test(value);
};

export default class ValidatePixel extends Validation {
  static typeAndLocation = (type, location) => {
    if (
      (type === 'iframe' || type === 'img') &&
      location !== 'body' &&
      location !== '-1'
    ) {
      return validationPayload(
        false,
        `This pixel type can only be located on the body.`
      );
    }
    return validationPayload(true, '');
  };

  static validatePixelString = (value, pixel_type) => {
    let valid;
    if (pixel_type === 'postback') {
      valid = validateTokenDelimiters(value);
      if (!valid) {
        return validationPayload(
          false,
          'The pixel provided is invalid. Please check the token delimiters (## are the only valid delimiters).'
        );
      }
      valid = balancedGrouping(value, valid);
      return validationPayload(
        valid,
        valid
          ? ''
          : 'The pixel provided is invalid. Please check that the delimiters are balanced.'
      );
    }
    const newStr = removeComments(value);
    valid = validatePixel(newStr);
    if (pixel_type === 'img') {
      valid = validateImgPixel(newStr, valid);
      return validationPayload(
        valid,
        valid
          ? ''
          : 'The pixel provided is invalid. This type of pixel should contain image tag.'
      );
    }
    if (pixel_type !== 'js_script') {
      return validationPayload(valid, '');
    }
    valid = validateJSPixel(newStr, valid);
    valid = balancedTags(newStr, valid);
    valid = balancedGrouping(newStr, valid);
    if (!valid) {
      return validationPayload(
        false,
        `The pixel provided is invalid. Please check braces, square brackets, quotes, double quotes, hashtags and HTML tags.`
      );
    }
    return validationPayload(true, '');
  };
}
