import React, { memo } from 'react';
import {
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { tss } from 'tss-react/mui';

const useStyles = tss.create({
  button: {
    columnGap: 8
  },
  checkbox: {
    padding: 0,
    color: 'z',

    '&.Mui-checked': {
      color: '#f50057'
    }
  },
  icon: {
    minWidth: 0
  }
});

/**
 * {@link https://devexpress.github.io/devextreme-reactive/react/grid/docs/reference/column-chooser/#columnchooseritemprops API Docs}
 */
export const ColumnChooserItem = memo(({ disabled, item, onToggle }) => {
  const { classes } = useStyles();

  return (
    <ListItem disablePadding>
      <ListItemButton
        classes={{ root: classes.button }}
        disabled={disabled}
        onClick={onToggle}
      >
        <ListItemIcon classes={{ root: classes.icon }}>
          <Checkbox
            checked={!item.hidden}
            name={item.column.name}
            classes={{ root: classes.checkbox }}
            disableRipple
            tabIndex={-1}
          />
        </ListItemIcon>
        <ListItemText primary={item.column.title} />
      </ListItemButton>
    </ListItem>
  );
});
