import { defaultInputProps } from '../../../../FormContainer/defaultFormStates';
import { siteOptions, statesList } from '../../../../common/commonRequests';
import { SPEND_STEP } from '../../../../common/dropdownConstants';
import { validateStringExists } from '../../../../Utilities/ValidationHelper/validationHelpers';
import ValidateBuyerSetup from '../../../../Buyers/Components/BuyerSetup/form/validation/validation';
import { hoursList } from '../../../../Buyers/Components/BuyerSetup/form/defaultState/state';
import Validation from '../../../../Utilities/ValidationHelper/Validation';

const status = [
  { value: '0', display: 'Inactive' },
  { value: '1', display: 'Active' }
];

const getPayoutSteps = (site) =>
  site === '4'
    ? SPEND_STEP.filter(
        (elem) => elem.value !== 'form' && elem.value !== 'landing'
      )
    : SPEND_STEP;

const affiliateType = [
  { value: 'all', display: 'Select Type' },
  { value: '0', display: 'Internal' },
  { value: '1', display: 'External' }
];

export const addAffiliatePayoutState = (s1 = false, reset = null) => {
  const fields = {
    site: {
      ...defaultInputProps,
      type: 'select',
      options: () => siteOptions(),
      value: '2',
      defaultVal: '0',
      name: 'site',
      label: 'Vertical'
    },
    step: {
      ...defaultInputProps,
      name: 'step',
      type: 'select',
      label: 'Step',
      options: (dependency) => getPayoutSteps(dependency),
      value: '0',
      defaultVal: '0',
      dependencyName: 'site'
    },
    affiliate_type: {
      ...defaultInputProps,
      name: 'affiliate_type',
      type: 'select',
      label: 'Affiliate Type',
      options: () => affiliateType,
      value: 'all',
      defaultVal: 'all'
    },
    is_revshare: {
      ...defaultInputProps,
      name: 'is_revshare',
      label: 'Enable Revshare',
      type: 'checkbox',
      value: false
    },
    off_peak_desktop: {
      name: 'off_peak_desktop',
      type: 'input',
      inputType: 'number',
      label: 'Desktop Payout (Off-Peak)',
      value: '5',
      defaultVal: '0',
      error: false,
      message: '',
      hidden: true,
      adornment: '$',
      adornmentPosition: 'start'
    },
    off_peak_mobile: {
      name: 'off_peak_mobile',
      type: 'input',
      inputType: 'number',
      label: 'Mobile Payout (Off-Peak)',
      value: '5',
      defaultVal: '0',
      error: false,
      message: '',
      hidden: true,
      adornment: '$',
      adornmentPosition: 'start'
    },
    desktop_payout: {
      name: 'desktop_payout',
      type: 'input',
      inputType: 'number',
      label: 'Desktop Payout',
      value: '10',
      defaultVal: '0',
      error: false,
      message: '',
      adornment: '$',
      adornmentPosition: 'start'
    },
    mobile_payout: {
      name: 'mobile_payout',
      type: 'input',
      inputType: 'number',
      label: 'Mobile Payout',
      value: '10',
      defaultVal: '0',
      error: false,
      message: '',
      adornment: '$',
      adornmentPosition: 'start'
    }
  };
  if (s1) {
    fields.s1 = {
      ...defaultInputProps,
      name: 's1',
      label: 'S1',
      validate: (value) => {
        const valid = validateStringExists(value.trim());
        return { valid, message: !valid ? 'S1 is not valid.' : '' };
      }
    };
  }
  if (reset) {
    fields.site = {
      ...defaultInputProps,
      type: 'select',
      options: () => siteOptions(),
      value: reset,
      defaultVal: '0',
      name: 'site',
      label: 'Vertical'
    };
  }
  return fields;
};

export const addAffiliatePayoutStateAdv = () => {
  return {
    site: {
      ...defaultInputProps,
      type: 'select',
      options: () => siteOptions(),
      value: '2',
      defaultVal: '0',
      name: 'site',
      label: 'Vertical'
    },
    state: {
      ...defaultInputProps,
      name: 'state',
      type: 'select',
      label: 'State',
      options: () => statesList(false, false, true),
      value: 'default'
    },
    start_time: {
      ...defaultInputProps,
      name: 'start_time',
      type: 'select',
      label: 'Start Time',
      validate: ValidateBuyerSetup.validateStartTime,
      options: () => hoursList,
      value: null,
      minMaxField: 'end_time'
    },
    end_time: {
      ...defaultInputProps,
      name: 'end_time',
      type: 'select',
      label: 'End Time',
      validate: ValidateBuyerSetup.validateEndTime,
      options: () => hoursList,
      value: null,
      minMaxField: 'start_time'
    },
    days: {
      ...defaultInputProps,
      name: 'days',
      type: 'multicheckbox',
      label: 'Days',
      options: [
        { label: 'Monday', optionVal: 1 },
        { label: 'Tuesday', optionVal: 2 },
        { label: 'Wednesday', optionVal: 3 },
        { label: 'Thursday', optionVal: 4 },
        { label: 'Friday', optionVal: 5 },
        { label: 'Saturday', optionVal: 6 },
        { label: 'Sunday', optionVal: 7 }
      ],
      values: []
    },
    payout: {
      name: 'payout',
      type: 'input',
      inputType: 'number',
      label: 'Payout',
      value: '10',
      defaultVal: '0',
      error: false,
      message: '',
      adornment: '$',
      adornmentPosition: 'start',
      validate: (value) => {
        const result = { ...Validation.defaultReturn };
        if (!value || value < 0) {
          result.valid = false;
          result.message = 'Please enter a valid value for Payout.';
        }
        return result;
      }
    }
  };
};

export const addAffiliatePayoutStateAdvCap = () => {
  return {
    site: {
      ...defaultInputProps,
      type: 'select',
      options: () => siteOptions(),
      value: '2',
      defaultVal: '0',
      name: 'site',
      label: 'Vertical'
    },
    start_time: {
      ...defaultInputProps,
      name: 'start_time',
      type: 'select',
      label: 'Start Time',
      validate: ValidateBuyerSetup.validateStartTime,
      options: () => hoursList,
      value: null,
      minMaxField: 'end_time'
    },
    end_time: {
      ...defaultInputProps,
      name: 'end_time',
      type: 'select',
      label: 'End Time',
      validate: ValidateBuyerSetup.validateEndTime,
      options: () => hoursList,
      value: null,
      minMaxField: 'start_time'
    },
    days: {
      ...defaultInputProps,
      name: 'days',
      type: 'multicheckbox',
      label: 'Days',
      options: [
        { label: 'Monday', optionVal: 1 },
        { label: 'Tuesday', optionVal: 2 },
        { label: 'Wednesday', optionVal: 3 },
        { label: 'Thursday', optionVal: 4 },
        { label: 'Friday', optionVal: 5 },
        { label: 'Saturday', optionVal: 6 },
        { label: 'Sunday', optionVal: 7 }
      ],
      values: []
    },
    cap: {
      name: 'cap',
      type: 'input',
      inputType: 'number',
      label: 'Cap',
      value: '10',
      defaultVal: '0',
      error: false,
      message: '',
      validate: (value) => {
        const result = { ...Validation.defaultReturn };
        if (!value || value < 0) {
          result.valid = false;
          result.message = 'Please enter a valid value for Cap.';
        }
        return result;
      }
    }
  };
};

export const addThrottleState = () => ({
  site: {
    ...defaultInputProps,
    type: 'select',
    options: () => siteOptions(true),
    value: 2,
    defaultVal: 'all',
    name: 'site',
    label: 'Vertical'
  },
  throttle_percentage: {
    ...defaultInputProps,
    inputType: 'number',
    name: 'throttle_percentage',
    label: 'Throttle Percentage',
    adornmentPosition: 'end',
    adornment: '%'
  },
  throttle_status: {
    ...defaultInputProps,
    type: 'select',
    name: 'throttle_status',
    label: 'Status',
    options: () => status,
    value: '1'
  }
});

export const editThrottleState = () => ({
  site: {
    ...defaultInputProps,
    type: 'select',
    options: () => siteOptions(true),
    value: 2,
    defaultVal: 'all',
    name: 'site',
    label: 'Vertical'
  },
  throttle_percentage: {
    ...defaultInputProps,
    inputType: 'number',
    name: 'throttle_percentage',
    label: 'Throttle Percentage',
    adornmentPosition: 'end',
    adornment: '%'
  },
  throttle_status: {
    ...defaultInputProps,
    type: 'select',
    name: 'throttle_status',
    label: 'Status',
    options: () => status,
    value: '0'
  }
});

export const addPhonePayoutState = (reset = null) => {
  const fields = {
    site_id: {
      ...defaultInputProps,
      type: 'select',
      options: () => siteOptions(),
      value: '2',
      defaultVal: '0',
      name: 'site_id',
      label: 'Vertical'
    },
    is_revshare: {
      ...defaultInputProps,
      name: 'is_revshare',
      label: 'Enable Revshare',
      type: 'checkbox',
      value: false
    },
    payout: {
      name: 'payout',
      type: 'input',
      inputType: 'number',
      label: 'Payout',
      value: '10',
      defaultVal: '0',
      error: false,
      message: '',
      adornment: '$',
      adornmentPosition: 'start'
    }
  };
  if (reset) {
    fields.site_id = {
      ...defaultInputProps,
      type: 'select',
      options: () => siteOptions(),
      value: reset,
      defaultVal: '0',
      name: 'site_id',
      label: 'Vertical'
    };
  }
  return fields;
};
