import {
  affiliateID,
  getTrafficSources,
  siteOptions
} from '../../../../common/commonRequests';
import {
  EXCLUDE_BUYER_TYPE_OPTIONS,
  TIME_PEAK_OPTIONS
} from '../../../../common/dropdownConstants';

export const filters = () => ({
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions(true),
    value: '2',
    inputWidth: 3
  },
  affiliateID: {
    name: 'affiliateID',
    type: 'autocomplete',
    label: 'Affiliate',
    options: () => affiliateID(),
    value: 'all',
    inputWidth: 3
  },
  legacy: {
    name: 'legacy',
    label: 'Legacy Buyers',
    type: 'select',
    options: () => [
      { value: 0, display: 'Hide' },
      { value: 1, display: 'Show All' }
    ],
    value: 0
  },
  trafficSource: {
    name: 'trafficSource',
    type: 'select',
    label: 'Traffic Source',
    defaultVal: '0',
    value: '0',
    inputWidth: 3,
    options: () => getTrafficSources()
  },
  excludeBuyerType: {
    name: 'excludeBuyerType',
    label: 'Type',
    type: 'select',
    options: () => EXCLUDE_BUYER_TYPE_OPTIONS,
    value: 0
  },
  time: {
    name: 'time',
    label: 'Time',
    type: 'select',
    options: () => TIME_PEAK_OPTIONS,
    value: 'all'
  }
});
