/* eslint-disable no-dupe-class-members */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/default-props-match-prop-types */
import React, { Component, memo } from 'react';
import PropTypes from 'prop-types';
import { uniq } from 'lodash';
import {
  SortingState,
  SelectionState,
  FilteringState,
  PagingState,
  GroupingState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedPaging,
  IntegratedSorting,
  RowDetailState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table as MaterialTable,
  VirtualTable,
  TableHeaderRow,
  TableFilterRow,
  TableGroupRow,
  PagingPanel,
  Toolbar,
  GroupingPanel,
  DragDropProvider,
  TableBandHeader,
  TableColumnVisibility,
  ColumnChooser,
  TableColumnReordering
} from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from 'tss-react/mui';
import { Tooltip } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoaderComponent from '../../Utilities/LoaderComponent';
import '../../../assets/react-datagrid.css';
import GrandTotals from '../../Utilities/GrandTotals';
import { isMobileDevice } from '../../common/utilities';
import { NetworkRequest } from '../../Utilities/NetworkRequests/NetworkRequests';
import { handleChange } from '../../lenox/actions';
import ActionDrawer from '../../PageContainer/ActionDrawer';
import GrandTotalsState from '../TableUtilities/GrandTotalsState';
import { ColumnChooserItem } from './components/ColumnChooserItem';
import { ColumnVirtualizationDisabler } from './components/ColumnVirtualizationDisabler';
import { saveToLocalStorage } from '../../Utilities/saveStateHelper';

const headerCellStyles = () => ({
  sortLabelSpan: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '.75rem',
    maxWidth: '100%',
    color: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.87)'
    }
  }
});

const PagingContainer = memo((props) => (
  <PagingPanel.Container {...props} className="devexpress-paggination" />
));

export const customizeDataGrid = async (newCustomization) => {
  const { data = { success: false } } = await NetworkRequest(
    'tableSettings',
    { datagrid_customization: JSON.stringify(newCustomization) },
    'updateTableSettings'
  );
  return data.success;
};

const SortingIcon = ({ direction }) =>
  direction === 'asc' ? (
    <ArrowUpward style={{ fontSize: '16px' }} />
  ) : (
    <ArrowDownward style={{ fontSize: '16px' }} />
  );

const SortLabel = ({ onSort, children, direction, classes }) => (
  <Tooltip title="Sort">
    <span onClick={onSort} className={classes.sortLabelSpan}>
      {children}
      {direction && <SortingIcon direction={direction} />}
    </span>
  </Tooltip>
);

const toLowerCase = (value) => String(value ?? '').toLowerCase();

export const trimPredicate = (value, filter) => {
  const newValue =
    typeof value === 'number'
      ? parseFloat(value).toFixed(2)
      : toLowerCase(value);

  const { value: filterValue } = filter;

  if (filterValue.at(-1) === ' ') {
    return newValue === toLowerCase(filterValue.trim());
  }

  return newValue.includes(toLowerCase(filterValue).trim());
};

const trimFilters = (columns) => {
  return columns.map(({ name }) => ({
    columnName: name,
    predicate: trimPredicate
  }));
};

const SortLabelStyled = withStyles(SortLabel, headerCellStyles);

class Table extends Component {
  state = {
    groupingState: [],
    hiddenColumnOrder: {}
  };

  static propTypes = {
    allowedPageSizes: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    cellComponent: PropTypes.func.isRequired,
    defaultColumnWidths: PropTypes.array.isRequired,
    defaultSortingOrder: PropTypes.array.isRequired,
    getColumnCompare: PropTypes.array.isRequired,
    tableColumnLocalStorageName: PropTypes.string.isRequired,
    height: PropTypes.number,
    mobileHeight: PropTypes.number,
    groupingState: PropTypes.array,
    expandedGrouping: PropTypes.array,
    totalsMapping: PropTypes.instanceOf(Object),
    loadingError: PropTypes.bool
  };

  static defaultProps = {
    height: 650,
    mobileHeight: 500,
    columnChooser: true,
    grouping: true,
    filters: true,
    groupingState: [],
    expandedGrouping: false,
    totalsMapping: {},
    loadingError: false
  };

  getColumnOrder = () => {
    const { columns, dataGridCustomization, tableColumnLocalStorageName } =
      this.props;

    const storedOrder =
      dataGridCustomization.columnOrder[tableColumnLocalStorageName];
    if (storedOrder && typeof storedOrder === 'object') {
      if (storedOrder.length === (columns || []).length) {
        return storedOrder;
      }
      const addNewColumns = (columns || [])
        .filter(({ name }) => storedOrder.indexOf(name) === -1)
        .map((column) => column.name);

      const newRemovedState = {};

      const removedMissingColumns = storedOrder.filter((columnName, index) => {
        const itExist =
          (columns || []).map((column) => column.name).indexOf(columnName) > -1;

        if (!itExist) {
          newRemovedState[columnName] = index;
        }

        return itExist;
      });

      if (!this.props.updateGridDisabled) {
        this.setState((prevState) => ({
          hiddenColumnOrder: {
            ...prevState.hiddenColumnOrder,
            ...newRemovedState
          }
        }));
      }

      const newDataGrid = [...removedMissingColumns];

      addNewColumns.forEach((column) => {
        if (this.state.hiddenColumnOrder[column] !== undefined) {
          newDataGrid.splice(this.state.hiddenColumnOrder[column], 0, column);

          return;
        }

        newDataGrid.push(column);
      });

      this.updateDataGrid('columnOrder', newDataGrid);
      return newDataGrid;
    }
    return (columns || []).map((column) => column.name);
  };

  updateDataGrid = (type, update) => {
    if (this.props.updateGridDisabled) {
      return;
    }
    const { dataGridCustomization, tableColumnLocalStorageName, handleChange } =
      this.props;
    const newDataGrid = {
      ...dataGridCustomization,
      [type]: {
        ...dataGridCustomization[type],
        [tableColumnLocalStorageName]: uniq(update)
      }
    };
    customizeDataGrid(newDataGrid);
    handleChange('dataGridCustomization', newDataGrid);
  };

  getDefaultHiddenColumns = () => {
    const { tableColumnLocalStorageName, dataGridCustomization } = this.props;
    if (!dataGridCustomization.hideColumns[tableColumnLocalStorageName]) {
      return [
        ...(dataGridCustomization.hideColumns[tableColumnLocalStorageName] ||
          [])
      ];
    }
    return dataGridCustomization.hideColumns[tableColumnLocalStorageName];
  };

  columnFilterWarning = () => {
    const filter = uniq(this.getDefaultHiddenColumns());
    if (filter.length > 0) {
      return (
        <div
          style={{
            color: 'red',
            fontSize: '12px',
            textAlign: 'right',
            marginTop: '1.5rem',
            marginRight: '1.5rem'
          }}
        >
          Your column filter is enabled
        </div>
      );
    }
    return null;
  };

  renderTable = () => {
    const { noVirtualTable, saveSorting, tableName } = this.props;

    const SelectedTable = noVirtualTable ? MaterialTable : VirtualTable;

    const sortingChange = saveSorting
      ? (newSorting) => {
          saveSorting(newSorting);
          saveToLocalStorage(`${tableName}-sorting`, newSorting);
        }
      : undefined;

    const sortingProp = {
      [saveSorting ? 'sorting' : 'defaultSorting']:
        this.props.defaultSortingOrder
    };

    return (
      <Grid rows={this.props.rows ?? []} columns={this.props.columns}>
        <FilteringState
          defaultFilters={this.props.defaultFilters?.filter(Boolean) || []}
          filters={this.props.filtersState}
          onFiltersChange={(val) => {
            const trimVal = val.map((v) => ({ ...v, value: v.value?.trim() }));

            this.props.onFiltersChange?.(trimVal);
          }}
        />
        <SortingState onSortingChange={sortingChange} {...sortingProp} />
        <IntegratedSorting columnExtensions={this.props.getColumnCompare} />
        <GroupingState
          defaultGrouping={this.props.defaultGrouping || []}
          defaultExpandedGroups={this.props.defaultExpandedGroups || []}
        />
        <RowDetailState defaultExpandedRowIds={[]} />
        <PagingState
          onPageSizeChange={this.props.changePageSize}
          defaultCurrentPage={0}
          defaultPageSize={this.props.defaultPageSize || 50}
        />
        <IntegratedFiltering
          columnExtensions={
            this.props.integratedFilteringColumnExtensions ||
            trimFilters(this.props.columns)
          }
        />
        <IntegratedGrouping />
        {Object.keys(this.props.totalsMapping).length > 0 && (
          <GrandTotalsState totalsMapping={this.props.totalsMapping} />
        )}
        <IntegratedPaging />
        <SelectionState defaultSelection={[1, 3, 18]} />
        <DragDropProvider />
        <SelectedTable
          height={
            (!isMobileDevice() && this.props.height) || this.props.mobileHeight
          }
          columnExtensions={this.props.defaultColumnWidths}
          cellComponent={this.props.cellComponent}
          messages={{
            noData: this.props.loadingError ? (
              <div style={{ fontSize: '1rem' }}>
                <div style={{ textAlign: 'center' }}>
                  An unexpected response was received.
                </div>
                <div>Please try again or contact support@useotto.tech.</div>
              </div>
            ) : (
              'No data'
            )
          }}
        />
        <ColumnVirtualizationDisabler />
        <TableColumnReordering
          order={this.getColumnOrder()}
          onOrderChange={(update) => this.updateDataGrid('columnOrder', update)}
        />
        <TableHeaderRow
          showSortingControls
          allowDragging
          sortLabelComponent={SortLabelStyled}
        />
        <TableColumnVisibility
          onHiddenColumnNamesChange={(update) =>
            this.updateDataGrid('hideColumns', update)
          }
          defaultHiddenColumnNames={this.getDefaultHiddenColumns()}
        />
        {this.props.filters && <TableFilterRow />}
        <PagingPanel
          pageSizes={this.props.allowedPageSizes}
          containerComponent={PagingContainer}
        />
        {this.props.grouping && <TableGroupRow />}
        {(this.props.columnChooser || this.props.grouping) && <Toolbar />}
        {this.props.columnChooser && (
          <ColumnChooser itemComponent={ColumnChooserItem} />
        )}
        {this.props.grouping && !this.props.expandedGrouping && (
          <GroupingPanel showSortingControls allowDragging />
        )}
        {typeof this.props.renderRowDetail === 'function' &&
          this.props.renderRowDetail()}
        {Object.keys(this.props.totalsMapping).length > 0 && (
          <GrandTotals
            totalsMapping={this.props.totalsMapping}
            breakdownButton={this.props.breakdownButton}
            hoverTotals={this.props.hoverTotals}
          />
        )}
        {this.props.columnBands && (
          <TableBandHeader columnBands={this.props.columnBands} />
        )}
      </Grid>
    );
  };

  renderCombinedTableFilter = () => {
    if (this.props.loading) {
      return <LoaderComponent padding={this.props.loadingPadding || 150} />;
    }
    return (
      <div>
        {this.columnFilterWarning()}
        {isMobileDevice() && this.props.additionalHeader}
        {this.renderTable()}
      </div>
    );
  };

  render() {
    if (isMobileDevice() && !this.props.mobileVisible) {
      return (
        <div style={{ textAlign: 'center' }}>
          <ActionDrawer
            fullWidth={false}
            style={{ width: '50%' }}
            drawerMobTable
            buttonTitle="Show Report"
            drawerTitle={this.props.drawerTitle || 'Show Report'}
            content={this.renderCombinedTableFilter()}
            mobileButtonStyle
          />
        </div>
      );
    }
    return this.renderCombinedTableFilter();
  }
}

export default connect(
  (state) => ({
    dataGridCustomization: state.lenox.dataGridCustomization
  }),
  (dispatch) =>
    bindActionCreators(
      {
        handleChange
      },
      dispatch
    )
)(Table);
