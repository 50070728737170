import { memo } from 'react';
import { useSelector } from 'react-redux';
import LiveChat from '../Components/Utilities/LiveChat/LiveChat';
import { getFromLocalStorage } from '../Components/Utilities/saveStateHelper';

interface IStoreState {
  lenox: {
    loggedIn: boolean;
  };
}

const LIVE_CHAT_LICENSE = 10841197;

const getVisitorEmail = () => getFromLocalStorage('email');

export const LiveChatRoute = memo(() => {
  const loggedIn = useSelector((state: IStoreState) => {
    return state?.lenox?.loggedIn;
  });

  if (!loggedIn) {
    return null;
  }

  return (
    <LiveChat
      license={LIVE_CHAT_LICENSE}
      visitor={{ email: getVisitorEmail() }}
    />
  );
});
