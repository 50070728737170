import moment from 'moment';

const rangeOfYears = (start, end) =>
  Array(end - start + 1)
    .fill(start)
    .map((year, index) => year + index);

const getYearList = () => {
  const yearList = rangeOfYears(2020, moment().year());
  const yearListSorted = yearList.sort(function (a, b) {
    return b - a;
  });
  const formatYearList = yearListSorted.map((year) => ({
    display: year.toString(),
    value: year.toString()
  }));
  return formatYearList;
};

export const filters = () => ({
  year: {
    name: 'year',
    type: 'select',
    label: 'Year',
    options: () => getYearList(),
    value: moment().year().toString(),
    inputWidth: 3
  }
});
