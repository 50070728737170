/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import FormContainer from '../../../../FormContainer/FormContainer';
import { leadReturnsState } from '../defaultState/state';
import { handleFormObjectChange } from '../../../../Utilities/HandleFormObjectChange';
import { NetworkRequestMultipart } from '../../../../Utilities/NetworkRequests/NetworkRequests';
import { formatSubmitValues } from '../../../../Utilities/SubmitHelper/FormatForSubmit';
import { validateForSubmit } from '../../../../Utilities/ValidationHelper/ValidateForm';
import { buildFormFields } from '../../../../Utilities/formHelper';

export const buyerType = 1;

export default class LeadReturns extends Component {
  static defaultProps = {
    tableBuyer: ''
  };

  state = {
    formValues: leadReturnsState(buyerType),
    submitting: false,
    dialog: {
      message: '',
      type: 'error',
      title: 'Error'
    },
    tableBuyer: ''
  };

  componentDidMount() {
    this.setBuyerReportValues();
  }

  componentDidUpdate(prevProps) {
    const { tableBuyer, tableSiteFilter } = this.props;
    if (
      prevProps.tableBuyer !== tableBuyer ||
      prevProps.tableSiteFilter !== tableSiteFilter
    ) {
      this.setBuyerReportValues();
    }
  }

  setBuyerReportValues = () => {
    const { tableBuyer, tableSiteFilter } = this.props;
    if (!!tableBuyer && !!tableSiteFilter) {
      this.setState({ tableBuyer }, () => {
        const { formValues } = this.state;
        Object.assign(formValues, formValues, {
          buyer: {
            ...formValues.buyer,
            value: tableBuyer,
            hidden: true,
            sendHidden: true
          },
          site: {
            ...formValues.site,
            value: tableSiteFilter,
            hidden: true,
            sendHidden: true
          }
        });
        this.setState({ formValues });
      });
    }
  };

  handleChange = (field, value) => {
    let formValues = handleFormObjectChange(field, value, {
      ...this.state.formValues
    });
    if (field === 'method_of_return') {
      formValues = this.handleMethodChange(value, formValues);
    }
    this.setState({ formValues });
  };

  handleMethodChange = (methodValue, formValues) => {
    const newFormValues = {
      ...formValues,
      file: {
        ...formValues.file,
        label: methodValue === 'uid' ? 'Reference IDs' : 'Emails'
      }
    };
    return newFormValues;
  };

  toggleDialog = (message = '', type = 'error', title = 'Error') =>
    this.setState({
      dialog: { ...this.state.dialog, message, type, title }
    });

  handleSubmit = () => {
    const { valid, messages } = validateForSubmit(this.state.formValues);
    if (!valid) {
      this.toggleDialog(messages, 'error');
      return false;
    }
    this.handleSubmitRequest();
  };

  handleSubmitRequest = () => {
    this.setState({ submitting: true }, () =>
      NetworkRequestMultipart(
        'fileUpload',
        formatSubmitValues(this.state.formValues),
        ['file'],
        'saveLeadReturns'
      )
        .then(({ data }) => this.handleResponse(data))
        .catch(() => this.setState({ submitting: false }))
    );
  };

  handleResponse = (data) => {
    this.setState(
      { submitting: false, formValues: leadReturnsState(buyerType) },
      () => {
        this.toggleDialog(
          data.message,
          data.success ? 'success' : 'error',
          data.success ? 'Success' : 'Error'
        );
        this.setBuyerReportValues();
      }
    );
  };

  render() {
    const { formValues, submitting, dialog } = this.state;
    return (
      <FormContainer
        formValues={buildFormFields(formValues, this.state)}
        submitting={submitting}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        inputWidth={8}
        separateBlock
        dialog={dialog}
        toggleDialog={this.toggleDialog}
        toggleActionDrawer={this.props.toggleDrawer}
      />
    );
  }
}
