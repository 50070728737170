import { lazy } from 'react';
import { filters as statusFilter } from '../../Status/filters/filters';
import { type IRoute } from '../../../Utilities/types';

const StatusPage = lazy(() => import('../../Status/StatusPage'));

const statusPage = {
  displayName: '',
  content: <StatusPage />,
  filters: statusFilter(),
  collapsableSectionTitle: []
};

const statusPageList: Record<'statusPage', IRoute> = {
  statusPage
};

export const StatusPagePageList = () => statusPageList;

export const sections = () => [];
