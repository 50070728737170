import { lazy } from 'react';
import { Route } from 'react-router-dom';

const Login = lazy(() => import('../../Components/Login/Login'));
const ResetPassword = lazy(
  () =>
    import('../../Components/UserSettings/ResetPasswordUUID/ResetPasswordUUID')
);
const AffiliateSignup = lazy(
  () => import('../../Components/AffiliateSignup/AffiliateSignup')
);
const Logout = lazy(() => import('../../Components/Login/Logout'));

export const usePublicRoutes = () => {
  return [
    <Route key="Login" path="/Login" element={<Login />} />,
    <Route key="ResetPassword" path="/pwreset" element={<ResetPassword />} />,
    <Route
      key="AffiliateSignup"
      path="/AffiliateSignup"
      element={<AffiliateSignup />}
    />,
    <Route key="Logout" path="/Logout" element={<Logout />} />
  ];
};
