import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

/**
 * `withRouter` is a HOC for Class Component.
 *
 * If you want to use `withRouter` in Functional Component, then it’s better to use
 * `useLocation`, `useNavigate`, and `useParams` hooks instead of `withRouter`.
 *
 * @deprecated
 */
export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}
