export const formatAffiliateValue = (affiliateName) => {
  if (
    affiliateName.toLowerCase().includes('all ') &&
    !affiliateName.includes('(#')
  ) {
    return 'all';
  }
  let affiliateID = affiliateName.split('(#').pop();
  affiliateID = affiliateID.replace(/\)/g, '');
  return affiliateID;
};

export const formatAffiliateName = (affiliateName) => {
  const names = affiliateName.split('(#');
  if (names.length < 2) {
    return false;
  }
  let name = names.shift();
  name = name.trim();
  return !affiliateName.toLowerCase().includes('all ')
    ? name
    : 'All Affiliates';
};
