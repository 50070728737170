import { defaultInputProps } from '../../../../FormContainer/defaultFormStates';
import { siteOptions, affiliateID } from '../../../../common/commonRequests';
import {
  PIXEL_LOCATION,
  PIXEL_STEP,
  PIXEL_TYPES
} from '../../../../common/dropdownConstants';
import {
  checkAffiliate,
  checkAffiliateDropdown
} from '../../../../PermissionsWrappers/permissionChecks';
import ValidatePixel from '../validation/validation';

const pixelLocations = [...PIXEL_LOCATION];
pixelLocations.splice(1, 1);
export const getPixelLocations = (type) =>
  type === 'iframe' || type === 'img'
    ? pixelLocations.filter((elem) => elem.value !== 'head')
    : pixelLocations;
export const getPixelTypes = (location) =>
  location === 'head'
    ? PIXEL_TYPES.filter(
        (elem) => elem.value !== 'iframe' && elem.value !== 'img'
      )
    : PIXEL_TYPES;
export const getPixelSteps = (type) =>
  type === 'postback'
    ? PIXEL_STEP.filter(
        (elem) => elem.value !== 'presell' && elem.value !== 'landing'
      )
    : PIXEL_STEP;

const handleAllAffiliates = async () => {
  const affiliates = await affiliateID();
  return checkAffiliate()
    ? affiliates.filter((aff) => aff.value !== 'all')
    : affiliates;
};

export const addPixelState = () => ({
  affiliateID: {
    ...defaultInputProps,
    type: 'autocomplete',
    options: () => handleAllAffiliates(),
    value: '',
    name: 'affiliateID',
    label: 'Affiliate ID',
    hidden: checkAffiliateDropdown()
  },
  site: {
    ...defaultInputProps,
    type: 'select',
    options: () => siteOptions(),
    value: 2,
    defaultVal: 0,
    name: 'site',
    label: 'Vertical'
  },
  pixel_type: {
    ...defaultInputProps,
    type: 'select',
    options: (dependency) => getPixelTypes(dependency),
    value: '-1',
    defaultVal: '-1',
    name: 'pixel_type',
    label: 'Pixel Type',
    validate: (value, min, max, field, dependency) =>
      ValidatePixel.typeAndLocation(value, dependency),
    dependencyName: 'pixel_location'
  },
  pixel_location: {
    ...defaultInputProps,
    type: 'select',
    options: (dependency) => getPixelLocations(dependency),
    value: '-1',
    defaultVal: '-1',
    name: 'pixel_location',
    label: 'Pixel Locations',
    dependencyName: 'pixel_type',
    minMaxField: 'pixel_type',
    validate: () => ({
      valid: true,
      message: ''
    })
  },
  required_step: {
    ...defaultInputProps,
    type: 'select',
    options: (dependency) => getPixelSteps(dependency),
    value: '-1',
    defaultVal: '-1',
    name: 'required_step',
    label: 'Required Step',
    dependencyName: 'pixel_type'
  },
  reference_ids: {
    ...defaultInputProps,
    name: 'reference_ids',
    multiline: true,
    rows: '10',
    rowsMax: '10',
    label: 'Pixel',
    validate: (value, min, max, field, dependency) =>
      ValidatePixel.validatePixelString(value, dependency),
    caption: `Supported Tokens: #payout# #price# #uuid# #affid# #s1# #s2# #s3# #s4# #s5# #zip#
          Note: #zip# is not supported for Postback pixels. If zip is required, please use an iframe, image or javascript. `,
    captionStyle: {
      whiteSpace: 'pre-line'
    },
    dependencyName: 'pixel_type'
  }
});
