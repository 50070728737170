import Validation from '../../../../Utilities/ValidationHelper/Validation';
import {
  validationPayload,
  validateStringExists
} from '../../../../Utilities/ValidationHelper/validationHelpers';

export default class ValidatePhoneRevenue extends Validation {
  static nonNegativeNumber = (value) => {
    const valid = validateStringExists(value);

    if (!valid) {
      return validationPayload(false, 'Please enter a valid number');
    }

    if (Number.isNaN(value) || parseFloat(value) < 0) {
      return validationPayload(false, 'Please enter a positive number');
    }

    return validationPayload(valid, '');
  };
}
