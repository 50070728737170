export const filters = () => ({
  lead_id: {
    name: 'lead_id',
    type: 'input',
    label: 'Lead ID',
    value: '',
    inputWidth: 3,
    blur: true,
    includeSubmit: true
  }
});
