import { type Components, type Theme } from '@mui/material';

type MUIButtonType = Components<Omit<Theme, 'components'>>['MuiButton'];
type MUIButtonColorType = NonNullable<
  NonNullable<MUIButtonType>['defaultProps']
>['color'];

export const button: MUIButtonType = {
  defaultProps: {
    color: 'default' as MUIButtonColorType
  },
  styleOverrides: {
    root: ({ ownerState, theme: currentTheme }) => {
      if (
        ownerState.variant === 'outlined' &&
        (ownerState.color as string) === 'default'
      ) {
        return {
          color: currentTheme.palette.text.primary,
          borderColor: 'rgba(0, 0, 0, 0.23)',

          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
          }
        };
      }
    }
  }
};
