/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
import moment from 'moment';

const formatDate = (...args) => {
  let datesArray = [];
  if (typeof args === 'object') {
    args.forEach((date) => {
      date = date.toLowerCase().includes('pm')
        ? formatDatePM(date)
        : formatDateAM(date);
      datesArray.push(date);
    });
  } else {
    datesArray = args;
  }
  return datesArray;
};

const formatDatePM = (date) => {
  date = date.split(' ');
  let [hour, minutes] = date.pop().split(':');
  date = date.join(' ');
  hour = `${hour}` === '12' ? hour : parseInt(hour, 10) + 12;
  return `${date} ${hour}:${minutes.replace('PM', '')}`;
};

const formatDateAM = (date) => {
  date = date.split(' ');
  let [hour, minutes] = date.pop().split(':');
  date = date.join(' ');
  hour = `${hour}` === '12' ? '0' : parseInt(hour, 10);
  return `${date} ${hour}:${minutes.replace('AM', '')}`;
};

export const compareTimestamp = (a, b) => {
  [a, b] = formatDate(a, b);
  a = moment(a);
  b = moment(b);

  if (a === b) {
    return 0;
  }

  return a.isBefore(b) ? -1 : 1;
};
