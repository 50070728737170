import Validation from '../../../../Utilities/ValidationHelper/Validation';

export default class ValidateCSV extends Validation {
  static csv = (value) => {
    const result = { ...Validation.defaultReturn };
    const validTypes = ['text/csv', 'application/vnd.ms-excel'];
    if (value && !validTypes.includes(value.type)) {
      result.valid = false;
      result.message =
        'Wrong file format. Please make sure you upload a CSV file.';
    }

    return result;
  };
}
