import { useCallback, useMemo } from 'react';
import {
  type IBarDataItem,
  type BarChartDataType,
  type IBarChartComponent
} from './types';
import { convertToFormattedNumber } from '../../TableContainer/TableUtilities/columnFormatter';
import { CustomizedAxisTick } from './components/CustomizedAxisTick';

export const useBarUtils = (props: IBarChartComponent) => {
  const {
    data,
    barNameKey,
    customTick,
    setDomain,
    setDomainBy,
    showDollar,
    hideDecimal,
    formatXAxis,
    interval: propsInterval
  } = props;

  const interval = propsInterval || 0;

  const barChatData = useMemo(() => {
    return data.length ? data : [{}];
  }, [data]);

  const xAxisProps = useMemo(() => {
    return {
      dataKey: barNameKey,
      interval,
      height: customTick ? 60 : undefined,
      tick: customTick ? (
        <CustomizedAxisTick formatter={formatXAxis} />
      ) : undefined
    };
  }, [barNameKey, customTick, formatXAxis, interval]);

  const yAxisProps = useMemo(() => {
    return {
      ticks: setDomain ? getSticks(data, setDomainBy) : undefined,
      domain: setDomain
        ? [
            getNext500Multiple(getMin(data, setDomainBy)),
            getNext500Multiple(getMax(data, setDomainBy))
          ]
        : undefined
    };
  }, [data, setDomain, setDomainBy]);

  const tooltipFormatter = useCallback(
    (value: number, title: string) => {
      let stringValue = `${showDollar ? '$' : ''}${convertToFormattedNumber(
        value
      )}`;
      if (hideDecimal) {
        stringValue = stringValue.replace('.00', '');
      }
      if (stringValue.includes('-')) {
        stringValue = stringValue.replace('-', '');
        const titleAndValue = (
          <div style={{ color: 'red' }}>
            {title} : -{stringValue}
          </div>
        );
        return [titleAndValue, false];
      }
      return [stringValue, title];
    },
    [hideDecimal, showDollar]
  );

  const barTooltipFormatter = useCallback(
    (value: number, title: string) => {
      let stringValue = `${showDollar ? '$' : ''}${convertToFormattedNumber(
        value
      )}`;
      if (hideDecimal) {
        stringValue = stringValue.replace('.00', '');
        if (stringValue.includes('-')) {
          stringValue = stringValue.replace('-', '');
          const titleAndValue = (
            <div style={{ color: 'red' }}>
              {title} : -{stringValue}
            </div>
          );
          return [titleAndValue, false];
        }
        return [stringValue, title];
      }
    },
    [hideDecimal, showDollar]
  );

  return useMemo(() => {
    return {
      barChatData,
      xAxisProps,
      yAxisProps,
      tooltipFormatter,
      barTooltipFormatter
    };
  }, [
    barChatData,
    barTooltipFormatter,
    tooltipFormatter,
    xAxisProps,
    yAxisProps
  ]);
};

export const getNext500Multiple = (dividend: number) => {
  if (parseFloat(`${dividend}`) === 0.0) return 500;

  return dividend > 0
    ? (Math.floor(dividend / 500) + 1) * 500
    : (Math.ceil(dividend / 500) - 1) * 500;
};

export const getNext1000Multiple = (dividend: number) => {
  if (parseFloat(`${dividend}`) === 0.0) return 1000;

  return dividend > 0
    ? (Math.floor(dividend / 1000) + 1) * 1000
    : (Math.ceil(dividend / 1000) - 1) * 1000;
};

export const getMin = (data: BarChartDataType, criteria: string) => {
  return Math.min(
    ...data.map((x) => parseFloat(`${x[criteria as keyof IBarDataItem]}`))
  );
};

export const getMax = (data: BarChartDataType, criteria: string) => {
  return Math.max(
    ...data.map((x) => parseFloat(`${x[criteria as keyof IBarDataItem]}`))
  );
};

const getStickRange = (min: number, max: number) => {
  return getNext1000Multiple((max - min) / 6);
};

export const getSticks = (data: BarChartDataType, criteria: string) => {
  const min = getNext500Multiple(getMin(data, criteria));
  const max = getNext500Multiple(getMax(data, criteria));
  const range = getStickRange(min, max);
  const limit = getNext500Multiple(max);
  let zeroIncluded = false;
  let indicator = getNext500Multiple(min);
  const sticks = [];
  if (min > 0) {
    sticks.push(0);
  }
  sticks.push(indicator);
  while (indicator < limit) {
    indicator += range;
    if (min < 0 && !zeroIncluded && indicator > 0) {
      zeroIncluded = true;
      sticks.push(0);
    }
    sticks.push(indicator);
  }
  if (max <= 0) {
    sticks.push(0);
  }
  return sticks;
};
