import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { colors } from '../../../../Utilities/LenoxColors';
import { getBuyerReturnsBreakdown } from '../../requests';
import { convertToFormattedNumber } from '../../../TableContainer/TableUtilities/columnFormatter';
import LoaderComponent from '../../../Utilities/LoaderComponent';
import { formatAffiliateValue } from '../../../Utilities/getAffiliateIDFromName';

const useStyles = makeStyles()(() => ({
  container: {
    margin: '1rem'
  },
  tableCell: {
    width: '50%',
    wordWrap: 'break-word',
    paddingLeft: '12px',
    paddingRight: '12px'
  },
  tableCellHead: {
    fontWeight: 'bold'
  },
  tableContainer: {
    border: 'solid 1px rgba(20,20,20,.3)',
    borderRadius: '8px',
    margin: '1rem'
  },
  tableTitle: {
    fontSize: '1.25rem',
    textAlign: 'center',
    padding: '.5rem',
    backgroundColor: colors.lenoxDark2,
    color: colors.lenoxLight1,
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px'
  }
}));

const defaultParam = (value) =>
  value === 'none' || value === 'all' ? '-1' : value;
const buildTableParams = (params) => ({
  start_date: moment(params.startDate).format('YYYY-MM-DD'),
  end_date: moment(params.endDate).format('YYYY-MM-DD'),
  date_range: params.dateRange,
  site: params.site,
  buyer: params.buyerName,
  affiliate_id: defaultParam(formatAffiliateValue(params.affiliateID))
});

function ReturnsBreakdown(props) {
  const { classes } = useStyles();
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { startDate, endDate, dateRange } = useSelector(
    (state) => state.dateRange
  );
  const { site, affiliateID } = useSelector((state) => state.filters);
  const { buyerName } = props;

  React.useEffect(() => {
    async function makeRequest() {
      setLoading(true);
      const { data } = await getBuyerReturnsBreakdown(
        buildTableParams({
          buyerName,
          site,
          affiliateID,
          startDate,
          endDate,
          dateRange
        })
      );
      setRows(
        data.map((row) => ({
          ...row,
          return_amount: `$${convertToFormattedNumber(row.return_amount)}`
        }))
      );
      setLoading(false);
    }
    makeRequest();
  }, [buyerName, dateRange, endDate, startDate, site, affiliateID]);

  const renderTableCell = (item, className = 'tableCell') => (
    <TableCell className={classes[className]}>{item}</TableCell>
  );

  const renderRow = (row) => (
    <TableRow>
      {Object.keys(row).map((key) => renderTableCell(row[key]))}
    </TableRow>
  );

  const renderTable = () => (
    <Grid item xs={10} className={classes.tableContainer}>
      <div className={classes.tableTitle}>Returns Affiliate Breakdown</div>
      <div>
        <Table>
          <TableHead>
            <TableRow>
              {['Affiliate', 'Returns', 'Amount'].map((column) =>
                renderTableCell(column, 'tableCellHead')
              )}
            </TableRow>
          </TableHead>
          <TableBody>{rows.map((row) => renderRow(row))}</TableBody>
        </Table>
      </div>
    </Grid>
  );

  return (
    (loading && <LoaderComponent padding={150} />) || (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: '5rem' }}
      >
        {renderTable()}
      </Grid>
    )
  );
}

ReturnsBreakdown.propTypes = {
  buyerName: PropTypes.string.isRequired
};

export default ReturnsBreakdown;
