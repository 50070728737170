/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import { Grid, IconButton } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import Table from '../../../TableContainer/Table';
import { getColumnWidths } from '../../../TableContainer/TableUtilities/getColumnWidths';
import { getColumnCompare } from '../../../TableContainer/TableUtilities/columnFormatter';
import { getBuyerRestrictions } from '../../requests';
import { renderDefaultCell } from '../../../TableContainer/TableUtilities/defaultCells';
import { NetworkRequest } from '../../../Utilities/NetworkRequests/NetworkRequests';
import Toggle from '../../../FormContainer/FormComponents/Toggle';
import ActionDrawer from '../../../PageContainer/ActionDrawer';
import EditRestrictions from '../EditConstraints/render/EditConstraints';
import Dialog from '../../../Dialog';
import EditReasonsField from './EditReasonsField';
import AddBuyerConstraints from '../AddBuyerConstraints/render/AddBuyerConstraints';
import { logError } from '../../../../Utilities/logError';

const columns = [
  { name: 'buyer_name', title: 'Buyers', width: 325 },
  { name: 'reason', title: 'Reason', width: 450 },
  { name: 'status', title: 'Status', width: 100 },
  { name: 'settings', title: 'Settings', width: 200 },
  { name: 'actions', title: 'Actions', width: 100 }
];

export const expectArrayData = (data) =>
  data && typeof data === 'object' ? data : [];

export default class BuyerRestrictions extends Component {
  state = {
    rows: [],
    loading: true,
    deleting: false,
    confirmationDialog: {
      message: '',
      type: 'confirm',
      title: 'Confirm',
      buyerRestrictionID: false
    },
    dialog: {
      message: '',
      type: 'error',
      title: 'Error'
    }
  };

  componentDidMount() {
    this.getTableData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.site !== this.props.site ||
      prevProps.buyer !== this.props.buyer
    ) {
      this.getTableData();
    }
  }

  getTableData = () => {
    let params = { action: 'getBuyerConstraintsList' };
    if (this.props.site > 0) {
      params = { ...params, site: this.props.site };
    }
    if (this.props.buyer !== '-1') {
      params = { ...params, buyer_name: this.props.buyer };
    }
    this.setState({ loading: true });
    getBuyerRestrictions(params)
      .then(({ data }) => {
        this.setState({ rows: data, loading: false });
      })
      .catch((error) => logError(error));
  };

  updateRow = (row, name, value, callback = () => {}) =>
    this.setState(({ rows }) => {
      rows.forEach(({ id }, index) => {
        if (row.id === id) {
          rows[index][name] = value;
        }
      });
      return { rows };
    }, callback);

  handleToggle = async (row, name, value) => {
    this.updateRow(row, name, value);
    const { data } = await NetworkRequest('constraints', {
      action: 'update',
      status: value ? 1 : 0,
      id: +row.id,
      buyer_name: row.buyer_name
    });
    if (!data.success) {
      this.updateRow(row, name, !value);
    }
  };

  handleConfirm = (
    buyerRestrictionID,
    message = '',
    type = 'confirm',
    title = 'Confirm Delete'
  ) =>
    this.setState({
      confirmationDialog: {
        ...this.state.confirmationDialog,
        message,
        type,
        title,
        buyerRestrictionID
      }
    });

  toggleDialog = (message = '', type = 'success', title = 'Success') =>
    this.setState({
      dialog: { ...this.state.dialog, message, type, title }
    });

  deleteRow = async () => {
    const {
      rows,
      confirmationDialog: { buyerRestrictionID }
    } = this.state;
    this.setState({ deleting: true });
    const { data } = await NetworkRequest('constraints', {
      action: 'delete',
      id: buyerRestrictionID
    });
    this.handleConfirm(false);
    this.setState({ deleting: false });
    if (data.success) {
      this.toggleDialog('Buyer Restriction removed successfully');
      this.setState({
        rows: rows.filter((row) => row.id !== buyerRestrictionID)
      });
    } else {
      this.toggleDialog('An error happened.', 'error', 'Error');
    }
  };

  renderConfirmDialog = () => (
    <Dialog
      style={{ minHeight: '500px' }}
      title={this.state.confirmationDialog.title}
      children={this.state.confirmationDialog.message}
      open={!!this.state.confirmationDialog.message}
      loading={this.state.deleting}
      onRequestSave={this.deleteRow}
      onRequestClose={() => setTimeout(() => this.handleConfirm(false), 300)}
      type={this.state.confirmationDialog.type}
      saveButtonText="Confirm"
    />
  );

  renderDialog = () => {
    const open = !!this.state.dialog.message;
    let { title } = this.state.dialog;
    if (!title) {
      title = open ? 'Error' : '';
    }

    return (
      <Dialog
        title={title}
        children={this.state.dialog.message}
        open={open}
        onRequestClose={() => setTimeout(() => this.toggleDialog(), 300)}
        type={this.state.dialog.type}
      />
    );
  };

  renderToggle = (row, columnName) =>
    row.status !== undefined && (
      <Toggle
        value={row[columnName] === true || row[columnName].toString() === '1'}
        name={columnName}
        onChange={(name, value) => this.handleToggle(row, name, value)}
      />
    );

  renderConstraintsSettingsButton = (row) =>
    row.status !== undefined && (
      <div style={{ maxWidth: '10rem' }}>
        <ActionDrawer
          fullWidth
          drawerTitle="Settings"
          content={<EditRestrictions row={row} />}
        />
      </div>
    );

  renderDeleteButton = (row) => (
    <Grid container alignItems="right">
      <IconButton
        onClick={() =>
          this.handleConfirm(
            row.id,
            `Are you sure do you want to remove this buyer restriction ${row.buyer_name}?`
          )
        }
        size="large"
      >
        <DeleteIcon />
      </IconButton>
    </Grid>
  );

  submitReason = async (row, value) => {
    const { data } = await NetworkRequest('constraints', {
      action: 'update',
      id: +row.id,
      reason: value
    });
    if (data.success) {
      this.toggleDialog('Buyer Restriction reason changed successfully');
      this.updateRow(row, 'reason', value);
    } else {
      this.toggleDialog(
        'Buyer Restriction reason was not changed.',
        'error',
        'Error'
      );
    }
  };

  renderEditReason = (row) => (
    <EditReasonsField row={row} submitReason={this.submitReason} />
  );

  renderAddButton = () => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <ActionDrawer
        drawerTitle="Add Buyer Restriction"
        content={
          <AddBuyerConstraints
            updateTable={() => this.getTableData()}
            buyer={this.props.buyer}
            site={this.props.site}
          />
        }
      />
    </div>
  );

  cellComponent = ({ row, column }) => {
    switch (column.name) {
      case 'reason':
        return renderDefaultCell(this.renderEditReason(row));
      case 'status':
        return renderDefaultCell(this.renderToggle(row, 'status'));
      case 'settings':
        return renderDefaultCell(this.renderConstraintsSettingsButton(row));
      case 'actions':
        return renderDefaultCell(this.renderDeleteButton(row));
      default:
        return renderDefaultCell(row[column.name]);
    }
  };

  render() {
    const { loading, rows } = this.state;
    return (
      <div id="buyer_restrictions" style={{ textAlign: 'center' }}>
        {this.renderConfirmDialog()}
        {this.renderDialog()}
        <hr style={{ margin: '1rem' }} />
        <h3>Buyer Restrictions</h3>
        {this.renderAddButton()}
        <div
          id="buyer_restrictions_table"
          style={{
            margin: '2rem',
            boxShadow: '0 0 3px -1px',
            borderRadius: '8px'
          }}
        >
          <Table
            loading={loading}
            rows={rows}
            columns={columns}
            cellComponent={this.cellComponent}
            defaultColumnWidths={getColumnWidths(columns)}
            getColumnCompare={getColumnCompare(columns)}
            tableColumnLocalStorageName="buyer_restrictions"
            drawerTitle="Buyers Info"
          />
        </div>
      </div>
    );
  }
}
