import { type FC, memo, useEffect } from 'react';
import { redirect } from 'react-router-dom';
import { ROUTE_NAMES } from '../Components/NavBar/NavigationContainer';
import { config } from '../config';

interface IRedirectRoute {
  isLoadedConditional: boolean;
}

export const RedirectRoute: FC<IRedirectRoute> = memo(
  ({ isLoadedConditional }) => {
    useEffect(() => {
      const { href } = window.location;

      // Legacy: copy link gives string like
      // :domain:/Reporting&page=somePage.
      // We replace it with
      // :domain:/Reporting/somePage
      const subPageExist = href.includes('&page') && !href.includes('?');

      if (subPageExist) {
        const pageIndex = href.indexOf('&page=');
        const selectedSubPage = href.slice(pageIndex + 6);
        const route = window.location.href
          .slice(0, pageIndex)
          .split('/')
          .at(-1);

        // Handle the Dashboard&page=dashboard case
        if (route === ROUTE_NAMES.Dashboard) {
          window.location.assign(
            `${config.routeBaseName}/${ROUTE_NAMES.Dashboard}`
          );

          return;
        }

        window.location.assign(
          `${href.slice(0, pageIndex)}/${selectedSubPage}`
        );

        return;
      }

      if (isLoadedConditional) {
        redirect(`/Dashboard`);

        return;
      }

      redirect(`/Login`);
    }, [isLoadedConditional]);

    return null;
  }
);
