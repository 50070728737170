/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import { Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FormContainer from '../../../FormContainer/FormContainer';
import { handleFormObjectChange } from '../../../Utilities/HandleFormObjectChange';
import { validateForSubmit } from '../../../Utilities/ValidationHelper/ValidateForm';
import { NetworkRequest } from '../../../Utilities/NetworkRequests/NetworkRequests';
import { formatSubmitValues } from '../../../Utilities/SubmitHelper/FormatForSubmit';
import { buildFormFieldsWithStateForm } from '../../../Utilities/formHelper';
import { colors } from '../../../../Utilities/LenoxColors';
import Dialog from '../../../Dialog';
import LoaderComponent from '../../../Utilities/LoaderComponent';
import { automaticTestingState, verticalSelect } from './defaultState';
import { handleAutomaticTestingChange } from './actions';
import ManualAutomaticTesting from '../../../Reporting/Components/AffiliateFormConversion/ManualAutomaticTesting';
import {
  checkAdmin,
  checkAffiliateManager,
  checkPowerUser,
  checkTech
} from '../../../PermissionsWrappers/permissionChecks';
import { handleChange as updateFilters } from '../../../Filters/actions/actions';
import { saveToLocalStorage } from '../../../Utilities/saveStateHelper';

const styles = () => ({
  tableContainer: {
    border: 'solid 1px rgba(20,20,20,.3)',
    borderRadius: '8px',
    margin: '1rem'
  },
  tableTitle: {
    fontSize: '1.25rem',
    textAlign: 'center',
    padding: '.5rem',
    backgroundColor: colors.lenoxDark2,
    color: colors.lenoxLight1,
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    marginBottom: '1rem'
  }
});

class AutomaticTesting extends Component {
  state = {
    verticalID: verticalSelect(
      this.props.selectedPage === 'affiliate_list'
        ? this.props.automaticTestingTableSiteID
        : this.props.site
    ),
    formValues: automaticTestingState(),
    submitting: false,
    loading: true,
    dialog: {
      message: '',
      type: 'error',
      title: 'Error'
    },
    confirmationDialog: {
      message: '',
      type: 'confirm',
      title: 'Error'
    },
    confirmationResetAllDialog: {
      message: '',
      type: 'confirm',
      title: 'Error'
    }
  };

  componentDidMount() {
    this.loadData();
    this.props.selectedPage === 'affiliate_list'
      ? this.props.updateFilters('automaticTestingTableSiteID', 2)
      : this.props.updateFilters('site', this.props.site);
    saveToLocalStorage(
      'automaticTestingFormSiteID',
      this.state.verticalID.vertical.value
    );
  }

  componentWillUnmount() {
    this.props.handleAutomaticTestingChange(true);
    this.props.selectedPage === 'affiliate_list'
      ? this.props.updateFilters('automaticTestingTableSiteID', 2)
      : this.props.updateFilters('site', this.props.site);
  }

  loadData = async () => {
    const {
      row: { affiliate_id }
    } = this.props;
    this.setState({ loading: true });
    const { data } = await NetworkRequest(
      'affiliates',
      {
        vertical_id: this.props.automaticTestingTableSiteID || 2,
        affiliate_id
      },
      'getAutomaticTesting'
    );
    this.props.handleAutomaticTestingChange(Date.now());
    this.setState(({ formValues }) => ({
      formValues: {
        automaticTesting: {
          ...formValues.automaticTesting,
          value: !!+data.automatic_testing || false
        },
        automaticPercentage: {
          ...formValues.automaticPercentage,
          value: +data.automatic_percentage || 0
        },
        marginDifference: {
          ...formValues.marginDifference,
          value: +data.margin || 0
        },
        lookbackClicks: {
          ...formValues.lookbackClicks,
          value: +data.lookback_clicks || 0
        }
      },
      loading: false
    }));
  };

  handleChange = (field, value) => {
    const newFormValues = handleFormObjectChange(field, value, {
      ...this.state.formValues
    });
    this.setState({ formValues: newFormValues });
  };

  toggleDialog = (message = '', type = 'error', title = 'Error') =>
    this.setState({
      dialog: { ...this.state.dialog, message, type, title }
    });

  toggleConfirm = (message = '', type = 'confirm', title = 'Confirm Edit') =>
    this.setState({
      confirmationDialog: {
        ...this.state.confirmationDialog,
        message,
        type,
        title
      }
    });

  toggleResetAllConfirm = (
    message = '',
    type = 'confirm',
    title = 'Confirm Reset'
  ) =>
    this.setState({
      confirmationResetAllDialog: {
        ...this.state.confirmationResetAllDialog,
        message,
        type,
        title
      }
    });

  handleReset = () => {
    this.toggleResetAllConfirm(
      'Do you want to reset stats for automatic testing? All forms will be reset to be tested like they are brand new.'
    );
  };

  handleSubmit = () => {
    const { valid, messages } = validateForSubmit(this.state.formValues);
    if (!valid && this.state.formValues.automaticTesting.value) {
      this.toggleDialog(messages, 'error');
      return;
    }
    this.toggleConfirm(
      'Are you sure that you want to change automatic testing settings?'
    );
  };

  handleSubmitRequest = () => {
    const {
      row: { affiliate_id }
    } = this.props;
    const { vertical } = formatSubmitValues(this.state.verticalID);
    this.setState({ submitting: true }, () => {
      const {
        automaticTesting,
        automaticPercentage,
        marginDifference,
        lookbackClicks
      } = formatSubmitValues(this.state.formValues);
      const params = {
        affiliate_id,
        vertical_id: vertical,
        automatic_testing: automaticTesting,
        automatic_percentage: automaticPercentage,
        margin: marginDifference,
        lookback_clicks: lookbackClicks
      };
      this.setState({ submitting: true }, () =>
        NetworkRequest('affiliates', params, 'updateAutomaticTesting')
          .then(({ data }) => {
            this.props.handleAutomaticTestingChange(!!automaticTesting);
            this.handleResponse(data, automaticTesting);
          })
          .catch(() => this.setState({ submitting: false }))
      );
    });
  };

  handleResetRequest = () => {
    const {
      row: { affiliate_id }
    } = this.props;
    this.setState({ submitting: true }, () =>
      NetworkRequest('affiliates', { affiliate_id }, 'resetAutomaticForm')
        .then(({ data }) => {
          this.handleResetResponse(data);
        })
        .catch(() => this.setState({ submitting: false }))
    );
  };

  handleVerticalChange = (field, value) => {
    this.props.updateFilters('automaticTestingTableSiteID', value);
    const newValues = handleFormObjectChange(field, value, {
      ...this.state.verticalID
    });
    this.setState({ verticalID: newValues }, this.loadData);
    saveToLocalStorage(
      'automaticTestingFormSiteID',
      this.state.verticalID.vertical.value
    );
  };

  handleResponse = (data, automaticTesting) => {
    this.setState({ submitting: false }, () => {
      if (data.success) {
        this.props.onChange(
          this.props.row,
          'automatic_testing',
          automaticTesting
        );
      }
      this.toggleDialog(
        data.message,
        data.success ? 'success' : 'error',
        data.success ? 'Success' : 'Error'
      );
    });
  };

  handleResetResponse = (data) => {
    this.setState({ submitting: false }, () => {
      this.toggleDialog(
        data.message,
        data.success ? 'success' : 'error',
        data.success ? 'Success' : 'Error'
      );
    });
  };

  renderConfirmDialog = () => (
    <Dialog
      title={this.state.confirmationDialog.title}
      children={this.state.confirmationDialog.message}
      open={!!this.state.confirmationDialog.message}
      onRequestSave={async () => {
        await this.handleSubmitRequest();
        this.toggleConfirm('');
      }}
      onRequestClose={() => {
        setTimeout(() => this.toggleConfirm(), 300);
      }}
      type={this.state.confirmationDialog.type}
      saveButtonText="Submit"
    />
  );

  renderResetAllDialog = () => (
    <Dialog
      title={this.state.confirmationResetAllDialog.title}
      children={this.state.confirmationResetAllDialog.message}
      open={!!this.state.confirmationResetAllDialog.message}
      onRequestSave={async () => {
        await this.handleResetRequest();
        this.toggleResetAllConfirm('');
      }}
      onRequestClose={() => {
        this.toggleResetAllConfirm('');
      }}
      type={this.state.confirmationResetAllDialog.type}
      saveButtonText="Yes"
      cancelButtonText="No"
    />
  );

  renderVerticalSelect = () => (
    <FormContainer
      formValues={buildFormFieldsWithStateForm(
        this.state.verticalID,
        this.state.verticalID
      )}
      handleChange={this.handleVerticalChange}
      inputWidth={8}
      separateBlock
    />
  );

  renderFormContainer = (
    formValues,
    handleSubmitFunc,
    handleChange,
    submitting,
    dialog,
    title,
    handleReset
  ) => (
    <Grid item className={this.props.classes.tableContainer}>
      <div className={this.props.classes.tableTitle}>{title}</div>
      <>
        {this.renderVerticalSelect()}
        {this.state.loading ? (
          <LoaderComponent padding={150} />
        ) : (
          <FormContainer
            formValues={buildFormFieldsWithStateForm(formValues, formValues)}
            submitting={submitting}
            handleChange={(field, value) => handleChange(field, value)}
            handleSubmit={handleSubmitFunc}
            inputWidth={8}
            contentReset="Reset Stats"
            handleReset={handleReset}
            separateBlock
            dialog={dialog}
            toggleDialog={this.toggleDialog}
          />
        )}
      </>
    </Grid>
  );

  render() {
    const { formValues, submitting, dialog } = this.state;
    return (
      <>
        {this.renderFormContainer(
          formValues,
          this.handleSubmit,
          this.handleChange,
          submitting,
          dialog,
          'Automatic Testing',
          this.handleReset
        )}
        {this.renderConfirmDialog()}
        {this.renderResetAllDialog()}
        {this.props.row.automatic_testing &&
        this.props.selectedPage !== 'affiliate_list' &&
        (checkAdmin() ||
          checkAffiliateManager() ||
          checkTech() ||
          checkPowerUser()) ? (
          <ManualAutomaticTesting
            row={this.props.row}
            filters={this.props.filters}
          />
        ) : null}
        {this.props.selectedPage === 'affiliate_list' &&
        (checkAdmin() ||
          checkAffiliateManager() ||
          checkTech() ||
          checkPowerUser()) ? (
          <ManualAutomaticTesting
            row={this.props.row}
            filters={this.props.filters}
          />
        ) : null}
      </>
    );
  }
}

export default connect(
  (state) => ({
    site: state.filters.site,
    automaticTestingTableSiteID: state.filters.automaticTestingTableSiteID,
    selectedPage: state.pageReducer.selectedPage
  }),
  (dispatch) =>
    bindActionCreators(
      {
        handleAutomaticTestingChange,
        updateFilters
      },
      dispatch
    )
)(withStyles(AutomaticTesting, styles));
