import { defaultInputProps } from '../../../FormContainer/defaultFormStates';
import {
  AGE_GROUP_OPTIONS,
  CREDIT_RATING_OPTIONS,
  INSURANCE_OPTIONS,
  OWNERSHIP_OPTIONS,
  STATES_OPTIONS,
  MULTI_VEHICLE_OPTIONS,
  MULTI_DRIVER_OPTIONS
} from '../../../common/dropdownConstants';

export const filters = () => ({
  insured: {
    ...defaultInputProps,
    name: 'insured',
    type: 'multiselect',
    label: 'Insurance',
    options: () => [{ display: 'All', value: 'all' }, ...INSURANCE_OPTIONS],
    value: ['all'],
    allValues: true
  },
  homeOwner: {
    ...defaultInputProps,
    name: 'homeOwner',
    type: 'multiselect',
    label: 'Homeowner',
    options: () => [{ display: 'All', value: 'all' }, ...OWNERSHIP_OPTIONS],
    value: ['all'],
    allValues: true
  },
  multiVehicle: {
    ...defaultInputProps,
    name: 'multiVehicle',
    type: 'multiselect',
    label: 'Vehicles',
    options: () => [{ display: 'All', value: 'all' }, ...MULTI_VEHICLE_OPTIONS],
    value: ['all'],
    allValues: true
  },
  multipleDriver: {
    ...defaultInputProps,
    name: 'multipleDriver',
    type: 'multiselect',
    label: 'Drivers',
    options: () => [{ display: 'All', value: 'all' }, ...MULTI_DRIVER_OPTIONS],
    value: ['all'],
    allValues: true
  },
  ageGroup: {
    ...defaultInputProps,
    name: 'ageGroup',
    label: 'Select Age Group(s)',
    type: 'multiselect',
    options: () => [{ display: 'All', value: 'all' }, ...AGE_GROUP_OPTIONS],
    value: ['all'],
    allValues: true
  },
  creditRating: {
    ...defaultInputProps,
    name: 'creditRating',
    label: 'Select Credit Rating Status(es)',
    type: 'multiselect',
    options: () =>
      [
        { display: 'All', value: 'all' },
        { display: 'Below Average', value: 2 },
        ...CREDIT_RATING_OPTIONS
      ].sort((a, b) => a.value - b.value),
    value: ['all'],
    allValues: true
  },
  statesList: {
    ...defaultInputProps,
    name: 'statesList',
    label: 'Select State(s)',
    type: 'multiselect',
    options: () => [{ display: 'All', value: 'all' }, ...STATES_OPTIONS],
    value: ['all'],
    allValues: true
  }
});
