/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-nested-ternary */
/* eslint-disable default-param-last */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import cn from 'classnames';
import {
  DateRangePicker as DatePicker,
  createStaticRanges,
  DateRange,
  DefinedRange
} from 'react-date-range';
import Popover from '@mui/material/Popover';
import CalendarIcon from '@mui/icons-material/Today';
import PropTypes from 'prop-types';
import moment from 'moment';

import { PREDEFINED_RANGES_NAMES, PREDEFINED_RANGES } from './constants';
import { isMobileDevice } from '../common/utilities';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const defaultRanges = [
  PREDEFINED_RANGES[PREDEFINED_RANGES_NAMES.today],
  PREDEFINED_RANGES[PREDEFINED_RANGES_NAMES.yesterday],
  PREDEFINED_RANGES[PREDEFINED_RANGES_NAMES.daysAgo2],
  PREDEFINED_RANGES[PREDEFINED_RANGES_NAMES.last7Days],
  PREDEFINED_RANGES[PREDEFINED_RANGES_NAMES.thisMonth],
  PREDEFINED_RANGES[PREDEFINED_RANGES_NAMES.lastMonth],
  PREDEFINED_RANGES[PREDEFINED_RANGES_NAMES.allTime]
];

const newDefaultRanges = [
  PREDEFINED_RANGES[PREDEFINED_RANGES_NAMES.today],
  PREDEFINED_RANGES[PREDEFINED_RANGES_NAMES.yesterday],
  PREDEFINED_RANGES[PREDEFINED_RANGES_NAMES.daysAgo2],
  PREDEFINED_RANGES[PREDEFINED_RANGES_NAMES.last7Days],
  PREDEFINED_RANGES[PREDEFINED_RANGES_NAMES.last14Days],
  PREDEFINED_RANGES[PREDEFINED_RANGES_NAMES.last30Days],
  PREDEFINED_RANGES[PREDEFINED_RANGES_NAMES.thisMonth],
  PREDEFINED_RANGES[PREDEFINED_RANGES_NAMES.lastMonth],
  PREDEFINED_RANGES[PREDEFINED_RANGES_NAMES.allTime]
];

class DateRangePicker extends Component {
  static propTypes = {
    startDate: PropTypes.shape().isRequired,
    endDate: PropTypes.shape().isRequired,
    onChange: PropTypes.func.isRequired,
    range: PropTypes.string,
    peakSelected: PropTypes.string,
    classes: PropTypes.shape({}),
    filterByPeak: PropTypes.bool,
    newRanges: PropTypes.bool.isRequired
  };

  static defaultProps = {
    range: 'today',
    peakSelected: 'all',
    filterByPeak: true,
    classes: {}
  };

  constructor(props) {
    super(props);
    this.state = {
      openPicker: false,
      openPeak: false,
      anchorPeak: null,
      anchorPicker: null,
      startDate: props.startDate || null,
      endDate: props.endDate || null,
      dateRange: props.range || null,
      showRestriction: false,
      pickNumber: 1,
      custom: false
    };
  }

  UNSAFE_componentWillReceiveProps({ startDate, endDate, dateRange }) {
    if (
      !this.setMomentDate(this.props.startDate).isSame(
        this.setMomentDate(startDate)
      ) &&
      !this.setMomentDate(this.props.endDate).isSame(
        this.setMomentDate(endDate)
      ) &&
      dateRange !== this.state.dateRange
    ) {
      this.setState({ startDate, endDate, dateRange });
    } else if (dateRange !== 'custom') {
      this.setState({
        startDate: new Date(PREDEFINED_RANGES[dateRange].range().startDate),
        endDate: new Date(PREDEFINED_RANGES[dateRange].range().endDate),
        dateRange
      });
    }
  }

  setMomentDate = (date) => {
    const { isMoment } = moment;
    if (!isMoment(date)) {
      return moment(date);
    }
    return date;
  };

  setNonMomentDate = (date) => {
    const { isMoment } = moment;
    if (isMoment(date)) {
      return date.format('MM/DD/YYYY');
    }
    return date;
  };

  changeRange = (start, end, dateRange) => {
    if (this.props.hideBigRanges && end.diff(start, 'days') >= 1) {
      this.setState({ showRestriction: true });
    } else {
      this.props.onChange({
        startDate: start,
        endDate: end,
        dateRange: dateRange || 'custom'
      });
      this.handleShowPicker('Picker', false);
      this.handleShowPicker('Peak', false);
      this.setState({
        startDate: start,
        endDate: end,
        showRestriction: false,
        dateRange
      });
    }
  };

  onRangeChange = ({ selection: { startDate, endDate, range: dateRange } }) => {
    const start = this.setMomentDate(startDate);
    const end = this.setMomentDate(endDate);

    if (!dateRange) {
      if (this.state.pickNumber === 2) {
        this.changeRange(start, end, dateRange);
        this.setState({ pickNumber: 1 });
      } else {
        this.setState({ startDate: start, endDate: end, dateRange });
        this.setState({ pickNumber: 2 });
      }
    } else {
      this.changeRange(start, end, dateRange);
    }
  };

  getTitle = (ranges) => {
    const {
      startDate: start,
      endDate: end,
      dateRange,
      specialRange
    } = this.props;
    const rangeText = start.isSame(end)
      ? this.setNonMomentDate(start)
      : `${this.setNonMomentDate(start)} - ${this.setNonMomentDate(end)}`;

    const currentRange = ranges.find(
      (element) => dateRange === element.range().range
    );

    return dateRange === 'custom' || !currentRange || specialRange
      ? rangeText
      : currentRange.label;
  };

  handleShowPicker(filter = 'Picker', open, event = {}) {
    this.setState({
      [`open${filter}`]: open,
      custom: !open,
      [`anchor${filter}`]: event.currentTarget || null
    });
  }

  renderDatePicker = (
    ranges,
    anchorPicker,
    openPicker,
    classes,
    startDate,
    endDate
  ) => (
    <div className={classes.datePickerButtonContainer}>
      <div
        onClick={(event) => this.handleShowPicker('Picker', true, event)}
        className={`${cn(classes.flex, classes.label)} datePickerButton`}
      >
        <span className={classes.text}>{this.getTitle(ranges)}</span>
        <CalendarIcon className={classes.datePickerIcon} />
      </div>
      <Popover
        open={openPicker}
        anchorEl={anchorPicker}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
        onClose={(event) => this.handleShowPicker('Picker', false, event)}
      >
        {this.state.showRestriction && (
          <div style={{ textAlign: 'center' }}>
            <span style={{ color: 'red' }}>
              You can only view 1 day at a time.
            </span>
          </div>
        )}
        {!isMobileDevice() ? (
          <DatePicker
            onChange={this.onRangeChange}
            className={cn(classes.datePickerWrapper, 'DatePicker')}
            inputRanges={[]}
            style={{ overflow: 'auto' }}
            staticRanges={createStaticRanges(ranges)}
            minDate={
              this.props.dateRangeLimit
                ? new Date(this.props.dateRangeLimit)
                : new Date('1/1/2017')
            }
            maxDate={new Date()}
            ranges={[
              {
                startDate: new Date(this.setNonMomentDate(startDate)),
                endDate: new Date(this.setNonMomentDate(endDate)),
                key: 'selection'
              }
            ]}
          />
        ) : this.state.custom ? (
          <DateRange
            onChange={this.onRangeChange}
            className={cn(classes.datePickerWrapper, 'DatePicker')}
            inputRanges={[]}
            style={{ overflow: 'auto' }}
            staticRanges={createStaticRanges(ranges)}
            minDate={
              this.props.dateRangeLimit
                ? new Date(this.props.dateRangeLimit)
                : new Date('1/1/2017')
            }
            maxDate={new Date()}
            ranges={[
              {
                startDate: new Date(this.setNonMomentDate(startDate)),
                endDate: new Date(this.setNonMomentDate(endDate)),
                key: 'selection'
              }
            ]}
          />
        ) : (
          <div
            className={cn(
              classes.datePickerWrapper,
              'rdrDefinedRangesWrapper',
              'DatePicker'
            )}
          >
            <DefinedRange
              onChange={this.onRangeChange}
              className="DatePicker"
              inputRanges={[]}
              style={{ overflow: 'auto' }}
              staticRanges={createStaticRanges(ranges)}
              minDate={
                this.props.dateRangeLimit
                  ? new Date(this.props.dateRangeLimit)
                  : new Date('1/1/2017')
              }
              maxDate={new Date()}
              ranges={[
                {
                  startDate: new Date(this.setNonMomentDate(startDate)),
                  endDate: new Date(this.setNonMomentDate(endDate)),
                  key: 'selection'
                }
              ]}
            />
            <button
              type="button"
              onClick={() => this.setState({ custom: !this.state.custom })}
              className="rdrStaticRange"
            >
              <span className="rdrStaticRangeLabel">Custom</span>
            </button>
          </div>
        )}
      </Popover>
    </div>
  );

  // renderPeakDropdown = (peakSelected, anchorPeak, openPeak, dateRange, classes, startDate, endDate) => this.props.filterByPeak && (
  //   <div style={{marginRight: '1rem'}} className={classes.weekDayButtonContainer}>
  //     <div
  //       className={cn(classes.flex, classes.label) + ' weekDayButton'}
  //       onClick={event => this.handleShowPicker('Peak', true, event)}
  //     >
  //             <span className={classes.text}>
  //               {WEEK_DAYS_OPTIONS.find(item => item.value === peakSelected).label}
  //             </span>
  //       <KeyboardArrowDown className={classes.weekDayArrow}/>
  //     </div>
  //     <Menu
  //       id="weekDayMenu"
  //       anchorEl={anchorPeak}
  //       open={openPeak}
  //       onClose={() => this.handleShowPicker('Peak', false)}
  //     >
  //       {WEEK_DAYS_OPTIONS.map(item => (
  //         <MenuItem
  //           key={item.value}
  //           value={item.value}
  //           onClick={() => {
  //             this.handleShowPicker('Peak', false);
  //             this.props.onChange({
  //               startDate,
  //               endDate,
  //               dateRange,
  //               weekDay: item.value,
  //             });
  //           }}
  //         >
  //           {item.label}
  //         </MenuItem>
  //       ))}
  //     </Menu>
  //   </div>
  // )

  render() {
    const {
      classes,
      // peakSelected,
      hideBigRanges,
      newRanges,
      specialRange,
      hideAllTime
    } = this.props;

    const {
      // anchorPeak,
      anchorPicker,
      // openPeak,
      openPicker,
      startDate,
      endDate
      // dateRange,
    } = this.state;

    let ranges = newRanges ? [...newDefaultRanges] : [...defaultRanges];

    if (hideBigRanges) {
      ranges = ranges.slice(0, 4);
    }
    if (hideAllTime) {
      ranges = ranges.slice(0, 8);
    }
    if (specialRange === 'weekOverWeek') {
      ranges = [
        PREDEFINED_RANGES[PREDEFINED_RANGES_NAMES.weeks4],
        PREDEFINED_RANGES[PREDEFINED_RANGES_NAMES.weeks6],
        PREDEFINED_RANGES[PREDEFINED_RANGES_NAMES.weeks12],
        PREDEFINED_RANGES[PREDEFINED_RANGES_NAMES.weeks24]
      ];
    }

    return (
      <div id="dateRangePicker" className={cn(classes.flex, classes.root)}>
        {this.renderDatePicker(
          ranges,
          anchorPicker,
          openPicker,
          classes,
          startDate,
          endDate
        )}
        {/* {this.renderPeakDropdown(peakSelected, anchorPeak, openPeak, dateRange, classes, startDate, endDate)} */}
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    color: 'black'
  },
  flex: {
    display: 'inline'
  },
  label: {
    cursor: 'pointer',
    padding: `0px ${theme.spacing(1)}`
  },
  text: {
    paddingRight: theme.spacing(1)
  },

  datePickerIcon: {
    verticalAlign: 'middle',
    color: '#3a4750'
  },
  datePickerButtonContainer: {
    display: 'inline-block',
    color: '#3a4750'
  },
  weekDayButtonContainer: {
    display: 'inline-block',
    padding: '1rem',
    color: '#3a4750'
  },
  weekDayArrow: {
    verticalAlign: 'middle'
  },

  datePickerWrapper: {
    '& .rdrInRange': {
      backgroundColor: '#3a4750'
    },
    '& .rdrEndEdge': {
      backgroundColor: '#3a4750'
    },
    '& .rdrStartEdge': {
      backgroundColor: '#3a4750'
    },
    '& .rdrDayHovered': {
      borderColor: '#3a4750 !important',
      outlineColor: '#3a4750 !important'
    },
    '& .rdrStaticRangeSelected': {
      color: 'white !important',
      backgroundColor: '#3a4750'
    }
  }
});

export default withStyles(DateRangePicker, styles);
