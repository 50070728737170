/* eslint-disable react/static-property-placement */
import React from 'react';
import { TableCell } from '@mui/material';
import Checkbox from '../FormContainer/FormComponents/Checkbox';

export default class RevShareControl extends React.Component {
  static defaultProps = {
    value: false
  };

  componentDidMount() {
    const { value, index, updateAdornmentOverride } = this.props;

    setTimeout(
      () =>
        updateAdornmentOverride(
          index,
          this.setAdornment(value),
          this.setAdornmentPosition(value)
        ),
      parseInt(`${index}00`, 10)
    );
  }

  setAdornment = (value) => (value ? '%' : false);

  setAdornmentPosition = (value) => (value ? 'end' : false);

  render() {
    const { value, onChange, updateAdornmentOverride, index } = this.props;
    return (
      <TableCell className="center-cell">
        <Checkbox
          name="is_revshare"
          value={value}
          onChange={(name, value) => {
            onChange(name, value);
            updateAdornmentOverride(
              index,
              this.setAdornment(value),
              this.setAdornmentPosition(value)
            );
          }}
        />
      </TableCell>
    );
  }
}
