/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/static-property-placement */
import React, { Component, memo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { billingValues } from '../defaultState/state';
import {
  TextField,
  Select,
  SubmitButton
} from '../../../../FormContainer/FormComponents/index';
import { handleFormObjectChange } from '../../../../Utilities/HandleFormObjectChange';
import { validateForSubmit } from '../../../../Utilities/ValidationHelper/ValidateForm';
import { NetworkRequest } from '../../../../Utilities/NetworkRequests/NetworkRequests';
import Dialog from '../../../../Dialog/Dialog';
import { formatSubmitValues } from '../../../../Utilities/SubmitHelper/FormatForSubmit';
import LoaderComponent from '../../../../Utilities/LoaderComponent';

const styles = () => ({
  container: {
    marginBottom: '5rem'
  },
  cardContainer: {
    height: '3rem',
    padding: '1rem',
    marginBottom: '1rem'
  },
  cardComponent: {
    padding: '1.5rem',
    border: 'solid 1px rgba(0,0,0,0.2)',
    borderRadius: '8px'
  }
});

class Billing extends Component {
  static propTypes = {
    classes: PropTypes.object
  };

  state = {
    formValues: { ...billingValues },
    submitting: false,
    dialog: {
      message: '',
      type: 'error',
      title: 'Error'
    },
    dataLoading: true
  };

  componentDidMount() {
    this.getAgentCustomCriteria();
  }

  getAgentCustomCriteria = async () => {
    this.setState({ dataLoading: true });
    const { data } = await NetworkRequest('billing', {
      action: 'getBillingInfo',
      email: localStorage.getItem('email')
    });

    if (data && (Boolean(data.length) || Boolean(Object.keys(data).length))) {
      const currentFormValues = { ...this.state.formValues };
      const formValues = Object.keys(currentFormValues).reduce(
        (newFormValues, key) => {
          return handleFormObjectChange(
            key,
            data[key] || currentFormValues[key].value,
            currentFormValues
          );
        },
        currentFormValues
      );
      this.setState({ formValues });
    }
    this.setState({ dataLoading: false });
  };

  handleChange = async (field, value) => {
    this.defaultFieldChange(field, value);
  };

  defaultFieldChange = (field, value) => {
    const formValues = handleFormObjectChange(field, value, {
      ...this.state.formValues
    });
    this.setState({ formValues });
  };

  renderTextField = (input) => (
    <TextField {...input} onChange={this.handleChange} />
  );

  renderSelect = (input) => <Select {...input} onChange={this.handleChange} />;

  renderInput = (input) =>
    input.type === 'select'
      ? this.renderSelect(input)
      : this.renderTextField(input);

  renderInputContainer = (input) =>
    !input.hidden && (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={7}>
          {this.renderInput(input)}
        </Grid>
      </Grid>
    );

  toggleDialog = (message = '', type = 'error', title = 'Error') =>
    this.setState({
      dialog: { ...this.state.dialog, message, type, title }
    });

  renderDialog = () => {
    const open = !!this.state.dialog.message;
    let { title } = this.state.dialog;
    if (!title) {
      title = open ? 'Error' : '';
    }

    return (
      <Dialog
        title={title}
        children={this.state.dialog.message}
        open={open}
        onRequestClose={() => setTimeout(() => this.toggleDialog(), 300)}
        type={this.state.dialog.type}
      />
    );
  };

  handleSubmit = async () => {
    const { formValues } = this.state;
    const { valid, messages } = validateForSubmit(formValues);
    if (!valid) {
      this.toggleDialog(messages, 'error');
      return false;
    }

    this.setState({ submitting: true });

    // Use elements.getElement to get a reference to the mounted Element.
    const cardElement = this.props.elements.getElement(CardElement);

    const { token } = await this.props.stripe.createToken(cardElement, {
      name: 'Name'
    });

    if (token === undefined) {
      this.setState({ submitting: false });
      this.toggleDialog('Invalid credit card information', 'error');
      return false;
    }

    const formattedSubmitValues = formatSubmitValues(formValues);
    formattedSubmitValues.email = localStorage.getItem('email');

    if (token) {
      formattedSubmitValues.stripeToken = token.id;
      formattedSubmitValues.last_four = token.card.last4;
      formattedSubmitValues.card_id = token.card.id;
    } else {
      formattedSubmitValues.stripeToken = '';
      formattedSubmitValues.last_four = '';
      formattedSubmitValues.card_id = '';
    }

    this.submitRequest(formattedSubmitValues);
  };

  submitRequest = (formattedSubmitValues) =>
    NetworkRequest('billing', formattedSubmitValues, 'update_billing_info')
      .then(({ data }) =>
        this.setState({ submitting: false }, () => {
          if (data) {
            this.toggleDialog(
              data.status ? 'Billing Info Successfully Updated' : data.message,
              data.status ? 'success' : 'error',
              data.status ? 'Success' : 'Error'
            );
            if (
              data.status &&
              typeof this.props.updatedBilling === 'function'
            ) {
              this.props.updatedBilling('billingComplete');
            }
            return;
          }
          this.toggleDialog(
            'Unable to save billing information',
            'error',
            'Error'
          );
        })
      )
      .catch(() => this.setState({ submitting: false }));

  renderSubmitButton = () => (
    <SubmitButton
      content="Update"
      handleSubmit={this.handleSubmit}
      submitting={this.state.submitting}
    />
  );

  renderCardInfo = (classes) => (
    <Grid
      className={classes.cardContainer}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid className={classes.cardComponent} item xs={7}>
        <CardElement
          options={{
            base: {
              style: {
                width: '100%'
              }
            }
          }}
        />
      </Grid>
    </Grid>
  );

  renderChargeNotice = () => (
    <Grid
      style={{ fontSize: '.75rem' }}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        style={{ padding: '5px', color: 'red', textAlign: 'center' }}
        item
        xs={7}
      >
        Your will not be charged when updating your billing information
      </Grid>
    </Grid>
  );

  renderLoader = () =>
    this.state.dataLoading && <LoaderComponent padding={150} />;

  renderBillingForm = (formValues, classes) => (
    <Grid
      className={classes.container}
      container
      justifyContent="center"
      alignItems="center"
    >
      {this.renderInputContainer(formValues.first_name)}
      {this.renderInputContainer(formValues.last_name)}
      {this.renderInputContainer(formValues.address)}
      {this.renderInputContainer(formValues.city)}
      {this.renderInputContainer(formValues.state)}
      {this.renderCardInfo(classes)}
      {this.renderSubmitButton()}
      {this.renderChargeNotice()}
      {this.renderDialog()}
    </Grid>
  );

  render() {
    const { formValues, dataLoading } = this.state;
    const { classes } = this.props;
    if (dataLoading) return this.renderLoader();

    return this.renderBillingForm(formValues, classes);
  }
}

const ComponentWithStyles = withStyles(Billing, styles);

const ComponentWithStripe = memo((props) => {
  const stripe = useStripe();
  const elements = useElements();

  return <ComponentWithStyles stripe={stripe} elements={elements} {...props} />;
});

export default ComponentWithStripe;
