import { type Components, type Theme, switchClasses } from '@mui/material';
import { grey, pink } from '@mui/material/colors';
import { colors } from '../../Utilities/LenoxColors';

export const switchOverride: Components<
  Omit<Theme, 'components'>
>['MuiSwitch'] = {
  defaultProps: {
    color: 'default'
  },
  styleOverrides: {
    switchBase: ({ ownerState }) => {
      if (ownerState.color !== 'default') {
        return;
      }

      const { checked } = ownerState;

      return {
        color: checked ? colors.lenoxSuccess1 : colors.lenoxDark2,

        [`&.${switchClasses.disabled}`]: {
          color: checked ? colors.lenoxSuccess1 : grey[400]
        }
      };
    },
    track: ({ ownerState }) => {
      if (ownerState.color !== 'default') {
        return;
      }

      const { checked, disabled } = ownerState;
      return {
        backgroundColor: checked && !disabled ? pink.A400 : undefined
      };
    }
  }
};
