/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import { comparePriority } from './comparePriority';
import { compareTimestamp } from './compareTimestamp';
import { comparePriorityWithUndefined } from './comparePriorityWithUndefined';

export const numberWithCommas = (number) => {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const convertToFormattedNumber = (value) => {
  if (!Number.isNaN(+value))
    return `${numberWithCommas(parseFloat(value).toFixed(2))}`;
  return value;
};

export const getColumnCompare = (columns) => {
  const columnCompare = [];
  columns.map(({ name, compare }) => {
    if (compare) {
      columnCompare.push({
        columnName: name,
        compare:
          typeof compare === 'function'
            ? compare
            : compare === 'priority'
            ? comparePriority
            : compareTimestamp
      });
    }
    return true;
  });
  return columnCompare;
};

export const getColumnCompareWithUndefined = (columns) => {
  const columnCompare = [];
  columns.map(({ name, compare }) => {
    if (compare) {
      columnCompare.push({
        columnName: name,
        compare:
          typeof compare === 'function'
            ? compare
            : compare === 'priority'
            ? comparePriorityWithUndefined
            : compareTimestamp
      });
    }
    return true;
  });
  return columnCompare;
};

export const convertToTime = (value) => {
  if (value.toString().length < 6) {
    return `00:${value.toString().replace(/(..?)/g, '$1:').slice(0, -1)}`;
  }
  return value.toString().replace(/(..?)/g, '$1:').slice(0, -1);
};

export const convertToMinutes = (value) => {
  const hours = Math.floor(value / 3600);
  value -= hours * 3600;
  const minutes = Math.floor(value / 60);
  const seconds = Math.round(value - minutes * 60);
  return `${
    (hours > 0 ? `${hours}:` : '') + (minutes < 10 ? 0 : '') + minutes
  }:${seconds < 10 ? 0 : ''}${seconds}`;
};
