import React from 'react';
import { Grid } from '@mui/material';
import Dialog from '../../../../../../../../Dialog/Dialog';
import FormCreator from '../../../../../../../../FormContainer/FormCreator';
import { SubmitButton } from '../../../../../../../../FormContainer/FormComponents';
import Pill from './Pill';

export default function AffiliateAllowListForm({
  formValues,
  handleChange,
  formTitle,
  handleSubmit,
  content,
  submitting,
  dialog,
  toggleDialog,
  removePill,
  pillValues
}) {
  const renderPills = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center'
        }}
      >
        {pillValues.map((value) => (
          <Pill
            affiliate={value}
            removePill={removePill}
            submitting={submitting}
          />
        ))}
      </div>
    );
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ flexDirection: 'column' }}
    >
      <div style={{ textAlign: 'center', textTransform: 'capitalize' }}>
        {formTitle}
      </div>
      <FormCreator
        formValues={formValues}
        handleChange={handleChange}
        inputWidth={8}
      />
      <div style={{ display: 'block' }}>{renderPills()}</div>
      <div style={{ display: 'block' }}>
        <SubmitButton
          handleSubmit={handleSubmit}
          submitting={submitting}
          content={content}
        />
      </div>
      <Dialog dialog={dialog} toggleDialog={toggleDialog} />
    </Grid>
  );
}
