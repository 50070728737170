import { defaultInputProps } from '../../../../FormContainer/defaultFormStates';
import { getValidKeys } from '../../../../common/commonRequests';

const constraintStatus = [
  { value: 0, display: 'Off' },
  { value: 1, display: 'On' }
];

const operatorOptions = [
  { value: '-1', display: 'Select an operator.' },
  { value: '===', display: '===' },
  { value: '!==', display: '!==' },
  { value: '>', display: '>' },
  { value: '>=', display: '>=' },
  { value: '<', display: '<' },
  { value: '<=', display: '<=' }
];

export const addBuyerConstraintState = () => ({
  reason: {
    ...defaultInputProps,
    name: 'reason',
    label: 'Reason For Constraint'
  },
  status: {
    ...defaultInputProps,
    name: 'status',
    label: 'Status',
    type: 'select',
    validate: () => {},
    options: () => constraintStatus,
    value: 1
  }
});

export const addConstraintElementState = (buyer) => ({
  key: {
    ...defaultInputProps,
    name: 'key',
    label: 'Select Key',
    type: 'select',
    options: () => getValidKeys(buyer),
    value: '0',
    defaultVal: '0'
  },
  operator: {
    ...defaultInputProps,
    name: 'operator',
    label: 'Select Operator',
    type: 'select',
    options: () => operatorOptions,
    value: '-1',
    defaultVal: '-1'
  },
  value: {
    ...defaultInputProps,
    name: 'value',
    label: 'Add Value'
  }
});
