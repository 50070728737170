/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Grid } from '@mui/material';
import DropzoneComponent from 'react-dropzone-component';
import Dialog from '../../Dialog/Dialog';
import '../../../assets/dropzone.min.css';
import '../../../assets/remove-dropzone-borders.css';

const componentConfig = {
  autoDiscover: false,
  iconFiletypes: ['.jpg', '.png', '.gif'],
  showFiletypeIcon: true,
  postUrl: 'no-url'
};

const previewTemplate = (
  <div
    style={{ backgroundColor: 'transparent', margin: '0 auto' }}
    className="dz-preview dz-file-preview"
  >
    <div
      style={{ margin: '0 auto', textAlign: 'center!important' }}
      className="dz-image"
    >
      <img
        style={{ height: '100%', width: '100%' }}
        alt=""
        data-dz-thumbnail="true"
      />
    </div>
    <div className="dz-progress">
      <span className="dz-upload" data-dz-uploadprogress="true" />
    </div>
  </div>
);

const djsConfig = {
  addRemoveLinks: true,
  dictDefaultMessage:
    'Drop files here to upload. <br> Images must be no larger than 496px x 240px.',
  previewTemplate: ReactDOMServer.renderToStaticMarkup(previewTemplate)
};

const style = {
  labelStyle: {
    marginLeft: '10px',
    marginRight: '45px',
    padding: '4px',
    fontSize: '1rem',
    color: 'rgba(0, 0, 0, 0.54)',
    backgroundColor: 'white',
    position: 'absolute',
    top: '-8px',
    left: '-20px',
    transform: 'translate(14px, -6px) scale(0.75)'
  },
  boxContainer: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    width: '100%',
    paddingTop: '20px',
    paddingBottom: '20px',
    paddingLeft: '40px',
    paddingRight: '40px',
    margin: '8px',
    borderRadius: '4px',
    position: 'relative'
  },
  boxContainerHover: {
    border: '1px solid rgba(0, 0, 0, 0.54)',
    width: '100%',
    paddingTop: '20px',
    paddingBottom: '20px',
    paddingLeft: '40px',
    paddingRight: '40px',
    margin: '8px',
    borderRadius: '4px',
    position: 'relative'
  }
};

export default class ImageUpload extends Component {
  state = {
    hover: false,
    eventHandlers: {
      addedfile: (file) => this.props.onChange(this.props.name, file),
      init: (dropzone) => {
        const maxImageWidth = 496;
        const maxImageHeight = 240;
        if (this.props.value) {
          this.preloadImages(dropzone);
        }

        dropzone.on('thumbnail', (file) => {
          if (file.width > maxImageWidth || file.height > maxImageHeight) {
            this.props.onChange(this.props.name, '');
            dropzone.removeFile(file);
            this.setState({ dialogOpen: true });
          }
        });
      },
      removedfile: () => this.props.onChange(this.props.name, '')
    },
    dialogOpen: false
  };

  toggleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  preloadImages = (dropzone) => {
    const name = 'image.jpg';
    const file = { url: this.props.value, name };
    dropzone.emit('addedfile', file);
    dropzone.emit('thumbnail', file, this.props.value);
    dropzone.emit('complete', file);
  };

  renderDialog = () => (
    <Dialog
      title="Error"
      children="The image you requested to upload was too big. Please check the dimensions and try again."
      open={!!this.state.dialogOpen}
      onRequestClose={() =>
        setTimeout(() => this.setState({ dialogOpen: false }), 300)
      }
      type="error"
    />
  );

  render() {
    const { hover, eventHandlers } = this.state;
    let boxStyle;
    if (hover) {
      boxStyle = style.boxContainerHover;
    } else {
      boxStyle = style.boxContainer;
    }
    return (
      <Grid container align="center" justifyContent="left">
        <div
          style={boxStyle}
          align="center"
          justify="center"
          onMouseEnter={this.toggleHover}
          onMouseLeave={this.toggleHover}
        >
          <label style={style.labelStyle}>{this.props.label}</label>
          <DropzoneComponent
            config={componentConfig}
            eventHandlers={eventHandlers}
            djsConfig={djsConfig}
          />
          {this.renderDialog()}
        </div>
      </Grid>
    );
  }
}
