import { affiliateID } from '../../../common/commonRequests';
import { defaultInputProps } from '../../../FormContainer/defaultFormStates';
import {
  AGE_GROUP_OPTIONS,
  CREDIT_RATING_OPTIONS,
  INSURANCE_OPTIONS,
  OWNERSHIP_OPTIONS,
  PRESETS_OPTIONS,
  STATES_OPTIONS,
  VEHICLE_OPTIONS,
  DEVICES
} from '../../../common/dropdownConstants';

export const filters = () => ({
  affiliateID: {
    name: 'affiliateID',
    type: 'autocomplete',
    label: 'Affiliate',
    options: () => affiliateID(),
    value: 'all'
  },
  insurance: {
    ...defaultInputProps,
    name: 'insurance',
    type: 'select',
    label: 'Insurance',
    options: () => INSURANCE_OPTIONS,
    value: '1'
  },
  homeOwnership: {
    ...defaultInputProps,
    name: 'homeOwnership',
    type: 'select',
    label: 'Homeowner',
    options: () => OWNERSHIP_OPTIONS,
    value: '1'
  },
  multipleVehicle: {
    ...defaultInputProps,
    name: 'multipleVehicle',
    type: 'select',
    label: 'Drivers/Vehicles',
    options: () => VEHICLE_OPTIONS,
    value: '0'
  },
  ageGroup: {
    ...defaultInputProps,
    name: 'ageGroup',
    label: 'Select Age Group(s)',
    type: 'multiselect',
    options: () => [{ display: 'All', value: 'all' }, ...AGE_GROUP_OPTIONS],
    value: ['all'],
    allValues: true
  },
  creditRating: {
    ...defaultInputProps,
    name: 'creditRating',
    label: 'Select Credit Rating Status(es)',
    type: 'multiselect',
    options: () => [{ display: 'All', value: 'all' }, ...CREDIT_RATING_OPTIONS],
    value: ['all'],
    allValues: true
  },
  statesList: {
    ...defaultInputProps,
    name: 'statesList',
    label: 'Select State(s)',
    type: 'multiselect',
    options: () => [{ display: 'All', value: 'all' }, ...STATES_OPTIONS],
    value: ['all'],
    allValues: true
  },
  presets: {
    ...defaultInputProps,
    name: 'presets',
    type: 'select',
    label: 'Presets',
    options: () => PRESETS_OPTIONS,
    value: '0'
  },
  device: {
    name: 'device',
    type: 'select',
    label: 'Device',
    options: () => DEVICES,
    value: '0',
    defaultVal: '0'
  }
});
