/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-alert */
/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { RemoveCircle } from '@mui/icons-material';
import { withStyles } from 'tss-react/mui';
import { hoursList } from '../../../defaultState/state';
import { numberWithCommas } from '../../../../../../../TableContainer/TableUtilities/columnFormatter';
import { CircularProgress } from '../../../../../../../Utilities/CircularProgress';

const styles = () => ({
  row: {
    display: 'flex'
  },
  rowCell: {
    flex: '1 1',
    '&:nth-child(1)': {
      whiteSpace: 'nowrap'
    },
    '&:nth-child(2)': {
      flex: '3 3',
      wordBreak: 'break-word'
    },
    '&:nth-child(4)': {
      flexBasis: 14
    }
  },
  bodyCell: {
    paddingLeft: '12px'
  }
});

const getReadableTime = (time) =>
  hoursList
    .filter(({ value }) => value === time)
    .map(({ display }) => display.replace(':00 ', ''))[0] || time;

class RowFormatter {
  static formatAffiliate = (affiliate) => affiliate;

  static formatLeads = (leads) => numberWithCommas(leads);

  static formatTime = (start_time, end_time) =>
    start_time && start_time
      ? `${getReadableTime(start_time)}-${getReadableTime(end_time)}`
      : 'All Day';

  static renderLoader = () => CircularProgress(30);

  static formatRemove = (affiliate, onRemove, database_id) => (
    <IconButton onClick={() => onRemove(affiliate, database_id)} size="large">
      <RemoveCircle />
    </IconButton>
  );
}

const tableColumns = [
  { name: 'affiliate_id', title: 'Affiliate ID' },
  { name: 'affiliateName', title: 'Affiliate Name' },
  { name: 'lead_cap', title: 'Lead Cap' },
  { name: 'time', title: 'Time Range' },
  { name: 'remove', title: 'Remove' }
];

class AddBuyerAffiliateTable extends Component {
  state = {
    processingIDs: []
  };

  buildRows = () => {
    const { data } = { ...this.props };

    return this.formatRows(data);
  };

  removeAffiliateCap = async (affiliate, database_id) => {
    const agree = window.confirm(
      'Are you sure that you want to remove this affiliate cap?'
    );
    if (agree) {
      const { processingIDs } = JSON.parse(JSON.stringify(this.state));
      processingIDs.push(database_id);
      this.setState({ processingIDs }, () =>
        this.props.requestRemoveAffiliate(affiliate, database_id).then(() =>
          this.setState({
            processingIDs: this.state.processingIDs.filter(
              (id) => id !== database_id
            )
          })
        )
      );
    }
  };

  formatRows = (tableData) => {
    const returnRows = [];

    tableData.map(
      ({
        affiliate_id,
        lead_cap,
        start_time,
        end_time,
        affiliate_name,
        id: database_id
      }) => {
        const row = {};

        row.affiliate_id = RowFormatter.formatAffiliate(affiliate_id);
        row.lead_cap =
          lead_cap.toString() === '-1'
            ? 'Denylisted'
            : RowFormatter.formatLeads(lead_cap);
        row.time = RowFormatter.formatTime(start_time, end_time);
        row.remove = RowFormatter.formatRemove(
          affiliate_id,
          this.removeAffiliateCap,
          database_id
        );

        row.affiliateName = RowFormatter.formatAffiliate(affiliate_name);

        row.remove = this.state.processingIDs.includes(database_id)
          ? RowFormatter.renderLoader()
          : RowFormatter.formatRemove(
              affiliate_id,
              this.removeAffiliateCap,
              database_id
            );

        returnRows.push(row);

        return true;
      }
    );
    return returnRows;
  };

  renderTableHeader = () => {
    const { row, rowCell } = this.props.classes;

    return (
      <TableHead>
        <TableRow className={row}>
          {tableColumns.map(({ title, name }) => (
            <TableCell key={name} className={rowCell}>
              {' '}
              {title}{' '}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  renderBodyRows = (rows) => {
    const { row: rowClassName } = this.props.classes;

    return rows.map((row) => (
      <TableRow key={row.affiliate_id} className={rowClassName}>
        {this.renderBodyRow(row)}
      </TableRow>
    ));
  };

  renderBodyRow = (row) => {
    const { rowCell, bodyCell } = this.props.classes;

    return tableColumns.map((column) => (
      <TableCell key={column.name} className={`${rowCell} ${bodyCell}`}>
        {' '}
        {row[column.name] || 'NA'}{' '}
      </TableCell>
    ));
  };

  renderTableBody = () => (
    <TableBody>{this.renderBodyRows(this.buildRows())}</TableBody>
  );

  renderTableContainer = () => (
    <Table>
      {this.renderTableHeader()}
      {this.renderTableBody()}
    </Table>
  );

  render() {
    return this.renderTableContainer();
  }
}

export default withStyles(AddBuyerAffiliateTable, styles);
