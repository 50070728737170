import React from 'react';
import {
  IconButton,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress
} from '@mui/material';
import { RemoveCircle } from '@mui/icons-material';

export default function AffiliateAllowListTable({
  rows,
  handleRemoveAffiliate,
  rowRemoved
}) {
  const columns = [
    { name: 'affiliate_name', title: 'Affiliate' },
    { name: 'remove', title: 'Remove' }
  ];

  const buildRows = () => {
    return formatRows(rows);
  };

  const renderRemoveRowButton = (row) => (
    <Grid container alignItems="right">
      <IconButton onClick={() => handleRemoveAffiliate(row)} size="large">
        {rowRemoved === row.id ? (
          <CircularProgress size={20} />
        ) : (
          <RemoveCircle />
        )}
      </IconButton>
    </Grid>
  );

  const formatRows = (rows) => {
    const returnRows = [];
    rows.map(({ affiliate_name, affiliate_id, id }) => {
      const row = {};
      row.id = id;
      row.affiliate_id = affiliate_id;
      row.affiliate_name = affiliate_name;
      row.remove = renderRemoveRowButton(row);
      returnRows.push(row);
      return true;
    });
    return returnRows;
  };

  const renderTableHeader = () => (
    <TableHead>
      {columns.map(({ title, name }) => (
        <TableCell key={name}> {title} </TableCell>
      ))}
    </TableHead>
  );

  const renderBodyRow = (row) =>
    columns.map((column) => (
      <TableCell style={{ paddingLeft: '12px', paddingRight: '5px' }}>
        {' '}
        {row[column.name] || 'NA'}{' '}
      </TableCell>
    ));

  const renderBodyRows = (rows) =>
    rows.map((row) => <TableRow key={row.name}>{renderBodyRow(row)}</TableRow>);

  const renderTableBody = () => (
    <TableBody>{renderBodyRows(buildRows())}</TableBody>
  );

  const renderTableContainer = () => (
    <Table>
      {renderTableHeader()}
      {renderTableBody()}
    </Table>
  );

  return renderTableContainer();
}
