import { defaultInputProps } from '../../../FormContainer/defaultFormStates';
import { siteOptions } from '../../../common/commonRequests';

export const verticalSelect = (value) => ({
  vertical: {
    ...defaultInputProps,
    type: 'select',
    options: () => siteOptions(),
    value,
    defaultVal: value,
    name: 'vertical',
    label: 'Vertical'
  }
});

export const automaticTestingState = () => ({
  automaticTesting: {
    ...defaultInputProps,
    type: 'checkbox',
    value: true,
    name: 'automaticTesting',
    label: 'Automated Testing',
    centered: true
  },
  automaticPercentage: {
    ...defaultInputProps,
    inputType: 'number',
    name: 'automaticPercentage',
    message:
      'The percentage of traffic that will be used for testing and not showing the winning form.',
    label: 'Test Traffic Percentage',
    adornmentPosition: 'end',
    adornment: '%'
  },
  marginDifference: {
    ...defaultInputProps,
    inputType: 'number',
    name: 'marginDifference',
    message:
      'The % difference in EPC between the form being checked and the best form.',
    label: 'Margin of Difference'
  },
  lookbackClicks: {
    ...defaultInputProps,
    inputType: 'number',
    name: 'lookbackClicks',
    message: 'How many clicks we look back over to judge a form’s performance.',
    label: 'LookBack Clicks'
  }
});
