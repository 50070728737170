/* eslint-disable react/no-children-prop */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import { Add } from '@mui/icons-material';
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import FormContainer from '../../../../FormContainer/FormContainer';
import {
  addBuyerConstraintState,
  addConstraintElementState
} from '../defaultState/state';
import { handleFormObjectChange } from '../../../../Utilities/HandleFormObjectChange';
import { formatSubmitValues } from '../../../../Utilities/SubmitHelper/FormatForSubmit';
import { validateForSubmit } from '../../../../Utilities/ValidationHelper/ValidateForm';
import { buildFormFields } from '../../../../Utilities/formHelper';
import { getBuyerRestrictions } from '../../../requests';
import { Select, TextField } from '../../../../FormContainer/FormComponents';
import { colors } from '../../../../../Utilities/LenoxColors';
import Dialog from '../../../../Dialog';

export const styles = () => ({
  tableCellHead: {
    fontWeight: 'bold',
    textAlign: 'center'
  },
  tableCell: {
    textAlign: 'center'
  },
  tableContainer: {
    border: 'solid 1px rgba(20,20,20,.3)',
    borderRadius: '8px',
    margin: '1rem'
  },
  tableTitle: {
    fontSize: '1.25rem',
    textAlign: 'center',
    padding: '.5rem',
    backgroundColor: colors.lenoxDark2,
    color: colors.lenoxLight1,
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px'
  },
  constraintsControlTitle: {
    marginBottom: '1.5rem'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  button: {
    width: '75%',
    color: '#00adb5',
    border: '1px solid #00adb5',
    marginLeft: '1rem'
  }
});

class AddBuyerConstraints extends Component {
  constructor(props) {
    super(props);
    const formValues = addBuyerConstraintState();
    const constraintFormValues = addConstraintElementState(props.buyer);
    this.state = {
      formValues,
      constraintFormValues,
      constraint_elements: [],
      submitting: false,
      loading: false,
      dialog: {
        message: '',
        type: 'error',
        title: 'Error'
      }
    };
  }

  handleChange = (field, value) => {
    const formValues = handleFormObjectChange(field, value, {
      ...this.state.formValues
    });
    this.setState({ formValues });
  };

  toggleDialog = (
    message = '',
    type = 'error',
    title = 'Error',
    closeDrawer = false
  ) => {
    this.setState({
      dialog: { ...this.state.dialog, message, type, title }
    });
    if (closeDrawer) {
      this.setState({
        formValues: addBuyerConstraintState(),
        constraintFormValues: addConstraintElementState(this.props.buyer),
        constraint_elements: []
      });
      this.props.updateTable();
    }
  };

  handleSubmit = () => {
    const { formValues, constraint_elements } = this.state;
    const { valid, messages } = validateForSubmit(formValues);
    if (!valid) {
      this.toggleDialog(messages, 'error');
      return false;
    }
    if (!constraint_elements.length) {
      this.toggleDialog(['Please add at least 1 constraint.'], 'error');
      return false;
    }
    this.setState({ submitting: true }, () => {
      const submitValues = formatSubmitValues(formValues);
      submitValues.status = parseInt(submitValues.status, 10);
      getBuyerRestrictions({
        buyer_name: this.props.buyer,
        reason: submitValues.reason,
        status: +submitValues.status,
        constraint_elements,
        action: 'create'
      })
        .then(({ data }) => {
          this.setState({ submitting: false });
          if (data && data.success) {
            this.toggleDialog(
              'Buyer restriction added successfully',
              'success',
              'Success'
            );
          } else {
            this.toggleDialog(data.message, 'error', 'Error');
          }
        })
        .catch(() => this.setState({ submitting: false }));
    });
  };

  handleCurrentConstraintChange = (field, value) => {
    const { constraintFormValues } = this.state;
    const newConstraintFormValues = {
      ...constraintFormValues,
      [field]: { ...constraintFormValues[field], value }
    };
    this.setState({ constraintFormValues: newConstraintFormValues });
  };

  formatFormValues = (formValues) => {
    formValues = { ...this.state.constraintFormValues[formValues.name] };
    formValues.onChange = this.handleCurrentConstraintChange;
    return formValues;
  };

  handleAddNewConstraint = () => {
    const { constraintFormValues, constraint_elements } = this.state;
    const { valid, messages } = validateForSubmit(constraintFormValues);
    if (!valid) {
      this.toggleDialog(messages, 'error');
      return false;
    }
    const key_type = constraintFormValues.key.value.split('!_!');
    const newConstraint = {
      key: key_type[0],
      operator: constraintFormValues.operator.value,
      value: constraintFormValues.value.value,
      type: key_type[1]
    };
    this.setState({
      constraint_elements: [...constraint_elements, newConstraint]
    });
  };

  renderRow = (row, index) => (
    <TableRow key={`row_${index}`}>
      {Object.keys(row).map((item) => (
        <TableCell className={this.props.classes.tableCell}>
          {' '}
          {row[item]}{' '}
        </TableCell>
      ))}
    </TableRow>
  );

  renderTable = (title, headers, data) => (
    <Grid className={this.props.classes.tableContainer}>
      <div className={this.props.classes.tableTitle}>{title}</div>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell className={this.props.classes.tableCellHead}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => this.renderRow(row, index))}
        </TableBody>
      </Table>
    </Grid>
  );

  renderAddButton = () => (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      className={this.props.classes.button}
      onClick={() => this.handleAddNewConstraint()}
    >
      <Add />
      Add
    </Button>
  );

  renderDialog = () => {
    const open = !!this.state.dialog.message;
    let { title } = this.state.dialog;
    if (!title) {
      title = open ? 'Error' : '';
    }

    return (
      <Dialog
        title={title}
        children={this.state.dialog.message}
        open={open}
        onRequestClose={() =>
          setTimeout(
            () =>
              this.toggleDialog(
                '',
                this.state.dialog.type,
                this.state.dialog.title,
                this.state.dialog.type === 'success'
              ),
            300
          )
        }
        type={this.state.dialog.type}
      />
    );
  };

  renderAddConstraintsControl = () => (
    <Grid
      container
      className={this.props.classes.tableContainer}
      style={{ width: '96%' }}
    >
      <Grid
        item
        md={12}
        className={`${this.props.classes.tableTitle} ${this.props.classes.constraintsControlTitle}`}
      >
        Add Constraints Elements
      </Grid>
      <Grid item md={4} sm={4} lg={4}>
        <Select
          {...this.formatFormValues(this.state.constraintFormValues.key)}
          loading={this.state.loading}
        />
      </Grid>
      <Grid item md={3} sm={3} lg={3}>
        <Select
          {...this.formatFormValues(this.state.constraintFormValues.operator)}
        />
      </Grid>
      <Grid item md={3} sm={3} lg={3}>
        <TextField
          {...this.formatFormValues(this.state.constraintFormValues.value)}
        />
      </Grid>
      <Grid
        item
        md={2}
        sm={2}
        lg={2}
        className={this.props.classes.buttonContainer}
      >
        {this.renderAddButton()}
      </Grid>
    </Grid>
  );

  render() {
    const { formValues, submitting, constraint_elements } = this.state;
    const headers = ['Key', 'Operator', 'Value', 'Type'];
    return (
      <>
        <FormContainer
          formValues={buildFormFields(formValues, this.state)}
          submitting={submitting}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          inputWidth={8}
          separateBlock
        />
        {this.renderTable('Constraint Elements', headers, constraint_elements)}
        {this.renderAddConstraintsControl()}
        {this.renderDialog()}
      </>
    );
  }
}

export default withStyles(AddBuyerConstraints, styles);
