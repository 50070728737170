import { createRoot } from 'react-dom/client';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';

import { type FC } from 'react';
import { App } from './App';
import reduxStore from './reduxStore';
import * as serviceWorker from './serviceWorker';
import { theme } from './theme/theme';

const Root: FC = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={reduxStore}>
          <App />
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(<Root />);
}

serviceWorker.unregister();
