import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash';
import moment from 'moment';
import Table from '../../../TableContainer/Table';
import { getColumnWidths } from '../../../TableContainer/TableUtilities/getColumnWidths';
import { getColumnCompare } from '../../../TableContainer/TableUtilities/columnFormatter';
import { getReportReports } from '../../requests';
import { getTableDataWithMostUpdated } from '../../../TableContainer/TableUtilities/buildTableRequest';
import {
  renderDefaultCell,
  renderNumberCell
} from '../../../TableContainer/TableUtilities/defaultCells';
import {
  handleGetDataChange,
  handleLastUpdatedChange
} from '../../../lenox/actions';
import CSVButton from '../../../TableContainer/TableUtilities/CSVButton';
import { logError } from '../../../../Utilities/logError';

const columns = [
  {
    name: 'data_partner',
    title: 'Data Partner',
    compare: 'priority',
    width: 270
  },
  { name: 'leads_sent', title: 'Leads Sent', compare: 'priority' },
  { name: 'accepted', title: 'Leads Accepted', compare: 'priority' },
  { name: 'rejected', title: 'Leads Rejected', compare: 'priority' }
];

const buildTableParams = (action, props) => ({
  action,
  start_date: moment(props.startDate).format('YYYY-MM-DD'),
  end_date: moment(props.endDate).format('YYYY-MM-DD'),
  site_id: props.siteUUID,
  date_range: props.dateRange
});

const csvColumns = columns.slice();

class ExternalDataReport extends Component {
  state = {
    rows: [],
    loading: true,
    loadingError: false
  };

  requestTime = React.createRef();

  getRequestTime = () => this.requestTime.current.toString();

  componentDidMount() {
    this.getData();
    this.props.handleGetDataChange(this.getData);
  }

  componentWillUnmount() {
    this.props.handleLastUpdatedChange('');
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(
        buildTableParams('getExternalDataReport', this.props),
        buildTableParams('getExternalDataReport', prevProps)
      )
    ) {
      this.getData();
      this.props.handleLastUpdatedChange('');
    }
  }

  getData = () => {
    const requestTime = new Date().getTime() / 1000;
    this.requestTime.current = requestTime;
    this.setState({ loading: true, loadingError: false }, () => {
      getTableDataWithMostUpdated(
        buildTableParams('getExternalDataReport', this.props),
        getReportReports,
        (values) => {
          if (requestTime.toString() === this.getRequestTime()) {
            this.setState(values);
            const { lastUpdated } = values;
            this.props.handleLastUpdatedChange(lastUpdated);
          }
        }
      ).catch((error) => {
        logError(error);
        this.setState({ rows: [], loading: false, loadingError: true });
      });
    });
  };

  cellComponent = ({ row, column }) => {
    switch (column.name) {
      case 'data_partner':
        return renderDefaultCell(row[column.name]);
      case 'leads_sent':
      case 'accepted':
      case 'rejected':
        return renderNumberCell(row[column.name]);
      default:
        return renderDefaultCell(row[column.name]);
    }
  };

  render() {
    const { rows, loading, loadingError } = this.state;
    return (
      <div>
        <CSVButton
          params={this.props}
          columns={csvColumns}
          filename="External Data"
          data={rows}
          marginTop="2rem"
          showButton={Boolean(rows.length) && !loading}
        />
        <Table
          loading={loading}
          rows={rows}
          columns={columns}
          cellComponent={this.cellComponent}
          defaultColumnWidths={getColumnWidths(columns)}
          getColumnCompare={getColumnCompare(columns)}
          tableColumnLocalStorageName="external_report"
          defaultSortingOrder={[
            { columnName: 'leads_sent', direction: 'desc' }
          ]}
          drawerTitle="External Report"
          loadingError={loadingError}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    startDate: state.dateRange.startDate,
    endDate: state.dateRange.endDate,
    dateRange: state.dateRange.dateRange,
    siteUUID: state.filters.siteUUID
  }),
  (dispatch) =>
    bindActionCreators(
      {
        handleGetDataChange,
        handleLastUpdatedChange
      },
      dispatch
    )
)(ExternalDataReport);
