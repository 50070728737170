/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import {
  Card,
  CardContent,
  IconButton,
  Typography,
  Button
} from '@mui/material';
import Assignment from '@mui/icons-material/Assignment';
import { withStyles } from 'tss-react/mui';

import { colors } from '../../../../Utilities/LenoxColors';

const styles = () => ({
  autosuggestOptions: {
    fontSize: '1rem',
    padding: 10,
    width: '92%',
    wordWrap: 'break-word'
  },

  '&:hover': {
    backgroundColor: `rgba(0,0,0,0.2)`
  }
});

class OptionsList extends React.Component {
  state = {
    display: false
  };

  toggleCard = () => {
    this.setState({ display: !this.state.display });
  };

  handleChange = (value) => {
    this.props.handleChange(value);
    this.toggleCard();
  };

  renderCardContent = () => (
    <CardContent style={{ padding: 0 }}>
      <Typography variant="body1">
        {this.props.options.map((option) => {
          return (
            <div
              className={this.props.classes.autosuggestOptions}
              style={{
                padding: '.25rem!important',
                textAlign: 'left',
                cursor: 'pointer'
              }}
              onClick={() => this.handleChange(option.display)}
            >
              {option.display}
            </div>
          );
        })}
      </Typography>
    </CardContent>
  );

  renderCloseButton = () => (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <Button
        style={{
          margin: '2rem',
          backgroundColor: colors.lenoxDark2,
          color: 'white'
        }}
        onClick={() => this.toggleCard()}
      >
        Close
      </Button>
    </div>
  );

  renderCard = () => (
    <Card
      id="analysis-card"
      style={{
        position: 'absolute',
        zIndex: 1500,
        marginLeft: '-7rem',
        marginTop: '2rem',
        width: '17rem',
        height: '15rem',
        overflow: 'auto'
      }}
    >
      {this.renderCardContent()}
      {this.renderCloseButton()}
    </Card>
  );

  renderOpenButton = () => (
    <IconButton
      style={{
        padding: 0,
        height: '35px',
        width: '35px',
        display: 'inline-flex'
      }}
      onClick={this.toggleCard}
      aria-expanded={this.state.hidden}
      aria-label="Show Analysis"
      disabled={this.props.disabled}
      size="large"
    >
      <Assignment />
    </IconButton>
  );

  render() {
    return (
      <div
        style={{
          display: 'inline-flex',
          justifyContent: 'center',
          position: 'relative'
        }}
      >
        {this.renderOpenButton()}
        {this.state.display && this.renderCard()}
      </div>
    );
  }
}

export default withStyles(OptionsList, styles);
