/* eslint-disable no-param-reassign */
/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { handleChange as handleFilterChange } from './actions/actions';
import {
  saveToLocalStorage,
  getFromLocalStorage
} from '../Utilities/saveStateHelper';
import FormContainer from '../FormContainer/FormContainer';
import { checkAffiliateDropdown } from '../PermissionsWrappers/permissionChecks';

const handleIncludedStatesFilter = (props, values) => {
  const includedStatesHadAll = props.includedStates.indexOf('all') > -1;
  const newValueHasAll = values.indexOf('all') > -1;
  const noOptionSelected = values.length === 0;
  const moreThanOne = values.length > 1;
  if (noOptionSelected || (newValueHasAll && !includedStatesHadAll)) {
    return ['all'];
  }
  if (includedStatesHadAll && moreThanOne) {
    return values.filter((value) => value !== 'all');
  }
  return values;
};

class FilterContainer extends Component {
  state = {
    hiddenFilters: []
  };

  static propTypes = {
    filterList: PropTypes.object.isRequired,
    page: PropTypes.string.isRequired
  };

  componentDidMount() {
    this.loadLocalStorageFilters(this.props);
    if (checkAffiliateDropdown()) {
      this.hideFilter('affiliateID', true);
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.page !== nextProps.page) {
      this.loadLocalStorageFilters(nextProps);
    }
  }

  loadLocalStorageFilters = (props) => {
    Object.keys(props.filterList).forEach((filterName) => {
      if (
        props.filterList[filterName].value === this.props.filters[filterName] &&
        !(this.checkBuyerReports(props) && filterName === 'affiliateID')
      ) {
        const localStorageName = `${filterName}_filter`;
        const value = getFromLocalStorage(
          localStorageName,
          props.filterList[filterName].value
        );
        props.handleFilterChange(filterName, value);
      }
    });
    this.forceUpdate();
  };

  checkBuyerReports = (props) =>
    props.breadCrumbs.length > 0 &&
    ((props.page === 'buyer_report' &&
      props.breadCrumbs[props.breadCrumbs.length - 1].path ===
        'hourlyBuyerTotals') ||
      (props.page === 'hourlyBuyerTotals' &&
        props.breadCrumbs[props.breadCrumbs.length - 1].path ===
          'buyer_report'));

  handleFilterUpdate = (filter, value) => {
    if (filter === 'includedStates') {
      const newValues = handleIncludedStatesFilter(this.props, value);
      this.props.handleFilterChange(filter, newValues);
    } else {
      this.props.handleFilterChange(filter, value);
      saveToLocalStorage(`${filter}_filter`, value);
      if (filter === 'site' && this.props.filterList.formID !== undefined) {
        this.handleFormDefault(value);
      }
      if (
        filter === 'rotationLocation' &&
        this.props.filterList.rotationAssetType
      ) {
        this.props.handleFilterChange('rotationAssetType', 'all');
      }
    }
  };

  handleFormDefault = async (value) => {
    const formOptions = await this.props.filterList.formID.options(value);
    const formDefault = formOptions[formOptions.length - 1].value;
    this.props.handleFilterChange('formID', formDefault);
    saveToLocalStorage(`${this.props.page}formID`, formDefault);
  };

  hideFilter = (filterName, hide = false) => {
    const { hiddenFilters } = this.state;

    if (hide) {
      hiddenFilters.push(filterName);
    } else {
      hiddenFilters.splice(hiddenFilters.indexOf(filterName), 1);
    }

    this.setState({ hiddenFilters });
  };

  // TODO: We mutate filters in this method. It can be reason of strange bugs. Refactor this function into immutable way
  buildFilters = (filters, props) => {
    const { hiddenFilters } = this.state;

    Object.keys(filters).map((filterName) => {
      filters[filterName].dependency =
        (filters[filterName].dependencyName &&
          props.filters[filters[filterName].dependencyName]) ||
        false;
      filters[filterName].secondDependency =
        (filters[filterName].secondDependencyName &&
          props.filters[filters[filterName].secondDependencyName]) ||
        false;
      filters[filterName].value = props.filters[filterName];

      if (hiddenFilters.includes(filterName)) {
        filters[filterName].hidden = true;
        filters[filterName].triggerHidden = true;
      } else if (
        filters[filterName].triggerHidden === true &&
        !hiddenFilters.includes(filterName)
      ) {
        filters[filterName].hidden = false;
        filters[filterName].triggerHidden = false;
      }
      return true;
    });

    return filters;
  };

  render() {
    return (
      <FormContainer
        formValues={this.buildFilters({ ...this.props.filterList }, this.props)}
        handleChange={this.handleFilterUpdate}
        hideFilter={this.hideFilter}
        handleSubmit={false}
        inputWidth={5}
        mobileInputWidth={12}
        separateBlock
        hiddenFilterList={this.props.hiddenFilterList}
        {...this.props}
      />
    );
  }
}

export default connect(
  (state) => ({
    filters: state.filters,
    site: state.filters.site,
    includedStates: state.filters.includedStates,
    rotationLocation: state.filters.rotationLocation,
    breadCrumbs: state.pageReducer.breadCrumbs,
    hiddenFilterList: state.filters.hiddenFilterList
  }),
  (dispatch) =>
    bindActionCreators(
      {
        handleFilterChange
      },
      dispatch
    )
)(FilterContainer);
