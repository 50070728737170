import { TableCell, Tooltip } from '@mui/material';
import { Help } from '@mui/icons-material';
import {
  convertToFormattedNumber,
  numberWithCommas,
  convertToTime,
  convertToMinutes
} from './columnFormatter';
import ProfitFormatter from './ProfitFormatter';
import ToggleFormatter from './ToggleFormatter';

export const renderDefaultCell = (
  value,
  anotherValue,
  additionalStyles = {},
  noWrap = false
) => (
  <TableCell
    style={{
      ...additionalStyles,
      padding: '4px 10px',
      height: 39,
      ...(noWrap ? {} : { wordBreak: 'break-word', wordWrap: 'break-word' })
    }}
  >
    {value}
    {anotherValue}
  </TableCell>
);

export const renderUnsetCharactersCell = (value, anotherValue) => (
  <TableCell
    style={{
      padding: '4px 10px',
      textTransform: 'none',
      wordBreak: 'break-word',
      wordWrap: 'break-word',
      height: 39
    }}
  >
    {value}
    {anotherValue}
  </TableCell>
);

export const renderEmptyProfit = (value, dollar = '', percent = '') => {
  return (
    <ProfitFormatter
      value={`${dollar}${convertToFormattedNumber(value)}${percent}`}
    />
  );
};

export const renderProfit = (value, dollar = '', percent = '') => {
  return renderDefaultCell(renderEmptyProfit(value, dollar, percent));
};

export const renderNumberCell = (value, anotherValue) =>
  renderDefaultCell(`${numberWithCommas(value)}`, anotherValue);

export const renderFloatCell = (value, anotherValue) => {
  return renderNumberCell(parseFloat(value ?? 0).toFixed(2), anotherValue);
};

export const renderCurrencyCell = (value, noWrap) =>
  renderDefaultCell(`$${convertToFormattedNumber(value)}`, '', {}, noWrap);

export const renderTimeCell = (value) =>
  renderDefaultCell(`${convertToTime(value)}`);

export const renderMinutesCell = (value) =>
  renderDefaultCell(`${convertToMinutes(value.toString()?.replace(/,/g, ''))}`);

export const renderPercentCell = (value, profit = false) =>
  renderDefaultCell(
    profit ? (
      <ProfitFormatter value={`${convertToFormattedNumber(value)}%`} />
    ) : (
      `${convertToFormattedNumber(value)}%`
    )
  );

export const renderToggleCell = (value, onChange, id) =>
  renderDefaultCell(
    <ToggleFormatter value={value} onChange={onChange} id={id} />
  );

export const columnTooltip = (columnName, text) => (
  <span style={{ display: 'flex', alignItems: 'center' }}>
    {columnName}
    <Tooltip title={text} placement="top">
      <Help fontSize="small" style={{ paddingLeft: 3 }} />
    </Tooltip>
  </span>
);
