import { configureStore } from '@reduxjs/toolkit';
// import { createLogger } from 'redux-logger';

import { reducers } from './reducers';

const middleware = [];

// Uncomment the following line to log actions in development

// if (process.env.NODE_ENV !== 'production') {
//   middleware.push(createLogger());
// }

export default configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(middleware)
});
