/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import FormContainer from '../../../../../FormContainer/FormContainer';
import { editBuyerState } from '../defaultState/state';
import { handleFormObjectChange } from '../../../../../Utilities/HandleFormObjectChange';
import { validateForSubmit } from '../../../../../Utilities/ValidationHelper/ValidateForm';
import { NetworkRequest } from '../../../../../Utilities/NetworkRequests/NetworkRequests';
import AddBuyerAffiliateCap from '../BuyerAffiliateCaps/form/render/AddBuyerAffiliateCap';
import BuyerRestrictions from '../../../BuyerConstraints/BuyerRestrictions';
import AddAffiliateAllowList from '../AddAfiliateAllowList/form/render/AddAffiliateAllowList';
import { useEffectOnce } from '../../../../../../Utilities/hooks/useEffectOnce';
import { usePreviousProps } from '../../../../../Utilities/hooks/usePreviousProps';

const DIALOG_SUCCESS_TYPE = 'success';

const baseDialogProps = {
  message: '',
  type: 'error',
  title: 'Error'
};

const EditBuyer = ({ row, site, onChange }) => {
  const [formValues, setFormValues] = useState(editBuyerState());
  const [submitting, setSubmitting] = useState(false);
  const [dialog, setDialog] = useState(baseDialogProps);
  const prevDialog = usePreviousProps(dialog, baseDialogProps);

  useEffectOnce(() => {
    loadFormValues();
  });

  useEffect(() => {
    const prevDialogIsSuccess =
      prevDialog.type === DIALOG_SUCCESS_TYPE && prevDialog.message;
    const confirmDialogButtonClick =
      dialog.type === DIALOG_SUCCESS_TYPE && !dialog.message;

    if (prevDialogIsSuccess && confirmDialogButtonClick) {
      Object.keys(formValues).map((key) =>
        onChange(row, key, formValues[key].value)
      );
    }
  }, [
    dialog.message,
    dialog.type,
    formValues,
    prevDialog.message,
    prevDialog.type,
    onChange,
    row
  ]);

  const loadFormValues = () => {
    const updatedFormValues = { ...formValues };

    [
      'lead_cap',
      'start_time',
      'end_time',
      'weekend_start_time',
      'weekend_end_time',
      'is_exclusive_buyer',
      'threshold',
      'shared_threshold',
      'legs_floor',
      'allow_weekend_traffic'
    ].forEach((column) => {
      let value = row[column] || updatedFormValues[column].value;

      if (value === '0') {
        value = false;
      }

      updatedFormValues[column] = {
        ...updatedFormValues[column],
        value
      };
    });

    if (row.buyer_type === '2' && row.accepts_listicle !== undefined) {
      updatedFormValues.accepts_listicle.value =
        row.accepts_listicle === true ||
        row.accepts_listicle.toString() === '1';
      updatedFormValues.accepts_listicle.hidden = false;
    }

    setFormValues(updatedFormValues);
  };

  const handleChange = (field, value) => {
    setFormValues((prevFormValues) =>
      handleFormObjectChange(field, value, { ...prevFormValues })
    );
  };

  const toggleDialog = (message = '', type = 'error', title = 'Error') => {
    setDialog((prevDialog) => ({
      ...prevDialog,
      message,
      type,
      title
    }));
  };

  const handleSubmit = () => {
    const { valid, messages } = validateForSubmit(formValues);

    if (!valid) {
      toggleDialog(messages, 'error');
      return false;
    }

    handleSubmitRequest();
  };

  const handleSubmitRequest = () => {
    setSubmitting(true);

    (async () => {
      try {
        // Getting the values from the form.
        const formDataValues = Object.fromEntries(
          Object.keys(formValues).map((key) => {
            return [key, formValues[key].value];
          })
        );

        const newData = { ...row, ...formDataValues };

        const data = await NetworkRequest('buyers', {
          action: 'update',
          id: newData.buyer_id,
          threshold: newData.threshold,
          shared_threshold: newData.shared_threshold,
          legs_floor: newData.legs_floor,
          lead_cap: newData.lead_cap,
          start_hours: newData.start_time,
          end_hours: newData.end_time,
          weekend_start_hours: newData.weekend_start_time,
          weekend_end_hours: newData.weekend_end_time,
          is_exclusive_buyer: newData.is_exclusive_buyer,
          buyer_type: newData.buyer_type,
          accepts_listicle: newData.accepts_listicle,
          allow_weekend_traffic: newData.allow_weekend_traffic
        });

        handleResponse(data);
      } catch (error) {
        setSubmitting(false);
      }
    })();
  };

  const handleResponse = (data) => {
    setSubmitting(false);

    if (data) {
      toggleDialog(
        'Buyer successfully updated',
        DIALOG_SUCCESS_TYPE,
        'Success'
      );
    } else {
      toggleDialog('An error has happened', 'error', 'Error');
    }
  };

  const buildFormValues = (fields, row) => {
    if (row.buyer_type === '9') {
      fields.is_exclusive_buyer.hidden = true;
      fields.threshold.label = 'Exclusive Threshold';
      fields.legs_floor.hidden = false;
      fields.shared_threshold.hidden = true;

      return fields;
    }

    Object.keys(fields).forEach((fieldName) => {
      if (fieldName === 'threshold') {
        if (fields.is_exclusive_buyer.value.toString() === '1') {
          fields[fieldName].hidden = false;
          fields.shared_threshold.hidden = true;
        } else {
          fields[fieldName].hidden = true;
          fields.shared_threshold.hidden = false;
        }
      }
    });

    return fields;
  };

  return (
    <Grid>
      <FormContainer
        submitting={submitting}
        inputWidth={8}
        dialog={dialog}
        formTitle={row.buyer}
        formValues={buildFormValues(formValues, row)}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        toggleDialog={toggleDialog}
        separateBlock
      />
      <AddBuyerAffiliateCap row={row} />
      <BuyerRestrictions buyer={row.buyer} site={site} />
      <AddAffiliateAllowList buyerID={row.buyer_id} />
    </Grid>
  );
};

export default EditBuyer;
