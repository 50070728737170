import React, { useState, useMemo } from 'react';
import { tss } from 'tss-react/mui';
import { Box } from '@mui/material';
import FormContainer from '../../../FormContainer/FormContainer';
import { updatePasswordState } from '../defaultState/state';
import { createNewForm } from '../../../Utilities/HandleFormObjectChange';
import { NetworkRequest } from '../../../Utilities/NetworkRequests/NetworkRequests';
import { formatSubmitValues } from '../../../Utilities/SubmitHelper/FormatForSubmit';
import { validateForSubmit } from '../../../Utilities/ValidationHelper/ValidateForm';
import { buildFormFields } from '../../../Utilities/formHelper';

export const useUpdatePasswordStyles = tss.create(
  ({ theme: { breakpoints } }) => ({
    form: {
      [breakpoints.down(960)]: {
        '#formContainer': {
          flexDirection: 'column',
          '& > *': {
            width: '100%'
          }
        }
      }
    }
  })
);

export default function UpdatePassword() {
  const [formValues, setFormValues] = useState(updatePasswordState());
  const [submitting, setSubmitting] = useState(false);
  const [dialog, setDialog] = useState({
    message: '',
    type: 'error',
    title: 'Error'
  });
  const { classes } = useUpdatePasswordStyles();

  const handleChange = (field, value) => {
    const newFormValues = createNewForm(field, value, { ...formValues });
    setFormValues(newFormValues);
  };

  const toggleDialog = (message = '', type = 'error', title = 'Error') => {
    setDialog({ ...dialog, message, type, title });
  };

  const handleSubmit = () => {
    const { valid, messages } = validateForSubmit(formValues);
    if (!valid) {
      toggleDialog(messages, 'error');
      return false;
    }
    handleSubmitRequest();
  };

  const handleSubmitRequest = () => {
    const values = formatSubmitValues(formValues);
    values.email = localStorage.getItem('email');

    setSubmitting(true);
    NetworkRequest('users', values, 'updatePassword')
      .then(({ data }) => handleResponse(data))
      .catch(() => setSubmitting(false));
  };

  const handleResponse = (data) => {
    setSubmitting(false);
    toggleDialog(
      data.message || '',
      data.success === true ? 'success' : 'error',
      data.success === true ? 'Success' : 'Error'
    );
  };

  const formFields = useMemo(() => {
    return buildFormFields(formValues, {
      submitting,
      dialog,
      formValues
    });
  }, [dialog, formValues, submitting]);

  return (
    <Box className={classes.form}>
      <FormContainer
        formValues={formFields}
        submitting={submitting}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        inputWidth={8}
        separateBlock
        dialog={dialog}
        toggleDialog={toggleDialog}
        content="Update Password"
      />
    </Box>
  );
}
