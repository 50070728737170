import { defaultInputProps } from '../../../../../FormContainer/defaultFormStates';
import ValidateBuyerSetup from '../validation/validation';

const exclusivity = [
  { value: '0', display: 'Shared' },
  { value: '1', display: 'Exclusive' }
];

export const hoursList = [
  { value: null, display: 'Select a time / no custom time' },
  { value: '00:00:00', display: '12:00 AM' },
  { value: '01:00:00', display: '1:00 AM' },
  { value: '02:00:00', display: '2:00 AM' },
  { value: '03:00:00', display: '3:00 AM' },
  { value: '04:00:00', display: '4:00 AM' },
  { value: '05:00:00', display: '5:00 AM' },
  { value: '06:00:00', display: '6:00 AM' },
  { value: '07:00:00', display: '7:00 AM' },
  { value: '08:00:00', display: '8:00 AM' },
  { value: '09:00:00', display: '9:00 AM' },
  { value: '10:00:00', display: '10:00 AM' },
  { value: '11:00:00', display: '11:00 AM' },
  { value: '12:00:00', display: '12:00 PM' },
  { value: '13:00:00', display: '1:00 PM' },
  { value: '14:00:00', display: '2:00 PM' },
  { value: '15:00:00', display: '3:00 PM' },
  { value: '16:00:00', display: '4:00 PM' },
  { value: '17:00:00', display: '5:00 PM' },
  { value: '18:00:00', display: '6:00 PM' },
  { value: '19:00:00', display: '7:00 PM' },
  { value: '20:00:00', display: '8:00 PM' },
  { value: '21:00:00', display: '9:00 PM' },
  { value: '22:00:00', display: '10:00 PM' },
  { value: '23:00:00', display: '11:00 PM' },
  { value: '23:59:59', display: '11:59 PM' }
];

export const editBuyerState = () => ({
  lead_cap: {
    ...defaultInputProps,
    name: 'lead_cap',
    label: 'Daily Lead Cap (0 = No Cap)',
    adornmentPosition: 'start',
    sendHidden: true,
    validate: () => {}
  },
  start_time: {
    ...defaultInputProps,
    name: 'start_time',
    type: 'select',
    label: 'Start Time',
    validate: ValidateBuyerSetup.validateStartTime,
    options: () => hoursList,
    value: null,
    minMaxField: 'end_time'
  },
  end_time: {
    ...defaultInputProps,
    name: 'end_time',
    type: 'select',
    label: 'End Time',
    validate: ValidateBuyerSetup.validateEndTime,
    options: () => hoursList,
    value: null,
    minMaxField: 'start_time'
  },
  weekend_start_time: {
    ...defaultInputProps,
    name: 'weekend_start_time',
    type: 'select',
    label: 'Weekend Start Time',
    validate: ValidateBuyerSetup.validateStartTime,
    options: () => hoursList,
    value: null,
    minMaxField: 'weekend_end_time'
  },
  weekend_end_time: {
    ...defaultInputProps,
    name: 'weekend_end_time',
    type: 'select',
    label: 'Weekend End Time',
    validate: ValidateBuyerSetup.validateEndTime,
    options: () => hoursList,
    value: null,
    minMaxField: 'weekend_start_time'
  },
  is_exclusive_buyer: {
    ...defaultInputProps,
    name: 'is_exclusive_buyer',
    type: 'select',
    label: 'Priority',
    validate: () => {},
    options: () => exclusivity,
    value: '0'
  },
  threshold: {
    ...defaultInputProps,
    name: 'threshold',
    label: 'Threshold',
    adornmentPosition: 'start',
    adornment: '$',
    validate: () => {}
  },
  shared_threshold: {
    ...defaultInputProps,
    name: 'shared_threshold',
    label: 'Shared Threshold',
    adornmentPosition: 'start',
    adornment: '$',
    validate: () => {}
  },
  legs_floor: {
    ...defaultInputProps,
    name: 'legs_floor',
    label: 'Legs Threshold',
    adornmentPosition: 'start',
    hidden: true,
    adornment: '$',
    validate: () => {}
  },
  accepts_listicle: {
    ...defaultInputProps,
    type: 'toggle',
    name: 'accepts_listicle',
    label: 'Accepts Listicle',
    value: false,
    hidden: true
  },
  allow_weekend_traffic: {
    ...defaultInputProps,
    type: 'toggle',
    name: 'allow_weekend_traffic',
    label: 'Accepts Weekend Traffic',
    value: false
  }
});

const {
  weekend_start_time: _weekend_start_time,
  weekend_end_time: _weekend_end_time,
  allow_weekend_traffic: _allow_weekend_traffic,
  shared_threshold: _shared_threshold,
  threshold: _threshold,
  is_exclusive_buyer: _is_exclusive_buyer,
  legs_floor: _legs_floor,
  ...editBuyerCapStateResult
} = editBuyerState();

export const editBuyerCapState = () => ({ ...editBuyerCapStateResult });

export const editBuyerNoteState = () => ({
  note: {
    ...defaultInputProps,
    name: 'note',
    label: 'Note',
    multiline: true,
    value: '',
    validate: () => {}
  }
});
