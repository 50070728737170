import { agentFilter } from '../../../../common/commonRequests';

const chargeTypeFilter = [
  { value: 'all', display: 'All' },
  { value: '1', display: 'Stripe' }
];

export const filters = () => ({
  agentFilter: {
    name: 'agentFilter',
    type: 'select',
    label: 'Agent',
    options: () => agentFilter(),
    value: '',
    inputWidth: 3
  },
  chargeType: {
    name: 'chargeType',
    type: 'select',
    label: 'Charge Type',
    options: () => chargeTypeFilter,
    value: null,
    inputWidth: 3
  }
});
