import { Getter, Plugin } from '@devexpress/dx-react-core';

const recalculateViewport = ({ viewport, tableColumns }) => {
  return {
    ...viewport,
    columns: [[0, tableColumns.length - 1]]
  };
};

/**
 * This Plugin for disabling column virtualization. It renders only in tests.
 *
 * Table does't render content of cells which out of viewport.
 * This plugin disable this optimization. Use it only for tests.
 * Order of plugin in JSX is important. Use it after `<VirtualTable />` component.
 */
export const ColumnVirtualizationDisabler = () => {
  if (!process.env.TEST_ENV) {
    return null;
  }

  return (
    <Plugin>
      <Getter name="viewport" computed={recalculateViewport} />
    </Plugin>
  );
};
