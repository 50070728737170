import React from 'react';
import { Typography } from '@mui/material';
import { siteOptions, buyerList } from '../../../../common/commonRequests';
import { defaultInputProps } from '../../../../FormContainer/defaultFormStates';
import ValidateCSV from '../../../../Sites/Components/UploadRetreaverRevenue/validation/validation';
import UserIdExample from '../../../../../assets/LeadReturnsExampleCSV/UserIdExample.csv';
import EmailExample from '../../../../../assets/LeadReturnsExampleCSV/EmailExample.csv';

const methodOfReturnOptions = [
  { value: 'uid', display: 'Reference IDs' },
  { value: 'email', display: 'Emails' }
];

const fileFormatExample = () => {
  return (
    <Typography>
      <div style={{ fontWeight: 'bold' }}>User ID/Reference ID</div>
      <ul
        style={{
          backgroundColor: '#EBECF0',
          listStyleType: 'none'
        }}
      >
        <li>abc7a7ae-c5bb-4c4c-aaf9-af3d67775a25</li>
        <li>0cc8fe68-ad20-49be-a735-5adfe34d31fa</li>
        <li>2523597c-8800-4e17-9055-e8e9346b9e67</li>
        <li>7608a1e4-6414-4122-aaaf-627134b69684</li>
      </ul>
      <a
        download="UserIdExample.csv"
        href={UserIdExample}
        style={{ color: 'inherit' }}
      >
        Download Example CSV File
      </a>
      <div style={{ fontWeight: 'bold', marginTop: '5px' }}>Emails</div>
      <ul
        style={{
          backgroundColor: '#EBECF0',
          listStyleType: 'none'
        }}
      >
        <li>person@ottoquotes.com</li>
        <li>another@ottoquotes.com</li>
        <li>me@gmail.com</li>
        <li>them@hotmail.com</li>
      </ul>
      <a
        download="EmailExample.csv"
        href={EmailExample}
        style={{ color: 'inherit' }}
      >
        Download Example CSV File
      </a>
    </Typography>
  );
};

export const leadReturnsState = (buyerType = false) => ({
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    validate: () => {},
    options: () => siteOptions(),
    value: '0',
    defaultVal: '0',
    error: false,
    message: ''
  },
  buyer: {
    name: 'buyer',
    type: 'select',
    label: 'Buyer',
    validate: () => {},
    options: (dependency) => buyerList(dependency, false, buyerType),
    value: '0',
    defaultVal: '0',
    error: false,
    message: '',
    dependencyName: 'site'
  },
  method_of_return: {
    name: 'method_of_return',
    type: 'select',
    label: 'Method Of Return',
    validate: () => {},
    options: () => methodOfReturnOptions,
    value: 'uid',
    error: false,
    message: ''
  },
  file: {
    name: 'file',
    label: 'Reference IDs',
    fullwidth: 'true',
    type: 'csv',
    inputType: 'file',
    validate: ValidateCSV.csv,
    value: '',
    defaultVal: '',
    error: false,
    exampleFormat: true,
    example: fileFormatExample(),
    message: '',
    caption: `Files uploaded should contain at least 1 line with a user id/reference id or an email address.
        Please do not mix user ids with email address.
        User IDs are strongly preferred.
        It is a rare occurrence to need to remove all returns for specific users.`
  },
  remove_returns: {
    ...defaultInputProps,
    name: 'remove_returns',
    label: 'Remove existing returns',
    labelInline: true,
    type: 'checkbox',
    value: false
  }
});
