import Validation from '../../../../../../../Utilities/ValidationHelper/Validation';

export default class ValidateAddBuyerAffiliateCap extends Validation {
  static validateAffiliateDropdown = (value) => {
    const result = { ...Validation.defaultReturn };
    if (!value || value === '-1' || value === 'Select an Affiliate') {
      result.valid = false;
      result.message = 'Please select a valid affiliate to add a cap for.';
    }
    return result;
  };
}
