import { DEVICES } from '../../../common/dropdownConstants';

export const filters = () => ({
  device: {
    name: 'device',
    type: 'select',
    label: 'Device',
    options: () => DEVICES,
    value: 'all'
  }
});
