import { defaultInputProps } from '../../../../../../FormContainer/defaultFormStates';
import { affiliateID } from '../../../../../../common/commonRequests';

export const editAffiliateAllowListState = () => ({
  affiliates: {
    ...defaultInputProps,
    name: 'affiliates',
    type: 'autocomplete',
    label: 'Affiliates',
    options: () => affiliateID(true),
    value: ''
  }
});
