import {
  siteOptions,
  affiliateID,
  getTrafficSources
} from '../../../../common/commonRequests';
import {
  checkAccounting,
  checkAdmin,
  checkAffiliateManager,
  checkPowerUser,
  checkAmb,
  checkTech
} from '../../../../PermissionsWrappers/permissionChecks';

const type = [
  { value: 'all', display: 'All' },
  { value: 'external', display: 'External' },
  { value: 'internal', display: 'Internal' }
];

export const filters = () => ({
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions(true),
    value: '0',
    inputWidth: 3
  },
  affiliateID: {
    name: 'affiliateID',
    type: 'autocomplete',
    label: 'Affiliate',
    options: () => affiliateID(),
    value: 'all',
    inputWidth: 3
  },
  affiliateType: {
    name: 'affiliateType',
    type: 'select',
    label: 'Affiliate Type',
    options: () => type,
    value: 'all',
    inputWidth: 3,
    hidden:
      !(checkAdmin() || checkAccounting() || checkPowerUser() || checkTech()) ||
      checkAffiliateManager()
  },
  trafficSource: {
    name: 'trafficSource',
    type: 'select',
    label: 'Traffic Source',
    defaultVal: '0',
    value: '14',
    inputWidth: 3,
    options: () => getTrafficSources(),
    hidden: checkAmb()
  }
});
