export const validateStringExists = (value) =>
  !!value && typeof value === 'string' && value.length > 0;

export const validationPayload = (valid, message) => ({ valid, message });

export const validateUSZipCode = (value) =>
  /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);

export const validateEmailCharacters = (value, valid) => {
  if (valid) {
    return value.includes('@') && value.includes('.');
  }
  return valid;
};

export const emailIsValid = (value) => {
  return value.includes('@') && value.includes('.');
};
