import { defaultInputProps } from '../../../../FormContainer/defaultFormStates';
import ValidateAdvertiserRevenue from '../validation/validation';
import { buyerList, siteOptions } from '../../../../common/commonRequests';

export const addAdvertiserRevenue = () => ({
  site: {
    ...defaultInputProps,
    name: 'site',
    label: 'Site',
    type: 'select',
    options: () => siteOptions(),
    value: '2',
    defaultVal: 0
  },
  buyer: {
    ...defaultInputProps,
    name: 'buyer',
    label: 'Buyer',
    type: 'select',
    options: (dependency) =>
      buyerList(dependency, false, false, true, false, true, true),
    value: '-1',
    defaultVal: '-1',
    dependencyName: 'site'
  },
  date_start: {
    ...defaultInputProps,
    name: 'date_start',
    type: 'input',
    inputType: 'date',
    label: 'Start Date',
    placeholder: 'mm/dd/yyyy',
    validate: ValidateAdvertiserRevenue.date
  },
  date_end: {
    ...defaultInputProps,
    name: 'date_end',
    type: 'input',
    inputType: 'date',
    label: 'End Date',
    placeholder: 'mm/dd/yyyy',
    validate: ValidateAdvertiserRevenue.date
  },
  date: {
    ...defaultInputProps,
    name: 'date',
    type: 'input',
    inputType: 'date',
    label: 'Date',
    placeholder: 'mm/dd/yyyy',
    validate: ValidateAdvertiserRevenue.date,
    hidden: true
  },
  revenue: {
    name: 'revenue',
    label: 'Revenue',
    type: 'input',
    inputType: 'number',
    value: 0,
    defaultVal: 0,
    error: false,
    message: '',
    validate: ValidateAdvertiserRevenue.revenue
  },
  name: {
    ...defaultInputProps,
    name: 'name',
    label: 'Custom Name',
    hidden: true
  }
});
