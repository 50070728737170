import { type FC, type ReactNode, memo } from 'react';

interface ICustomizedAxisTick {
  x?: number;
  y?: number;
  formatter: (value: number | undefined) => ReactNode | null;
  payload?: {
    value: number;
  };
}

export const CustomizedAxisTick: FC<ICustomizedAxisTick> = memo(
  ({ x, y, formatter, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {formatter(payload?.value)}
        </text>
      </g>
    );
  }
);
