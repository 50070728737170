/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import { Box } from '@mui/system';
import FormContainer from '../../../../FormContainer/FormContainer';
import { addAdvertiserRevenue } from '../defaultState/state';
import { handleFormObjectChange } from '../../../../Utilities/HandleFormObjectChange';
import { NetworkRequest } from '../../../../Utilities/NetworkRequests/NetworkRequests';
import { formatSubmitValues } from '../../../../Utilities/SubmitHelper/FormatForSubmit';
import { validateForSubmit } from '../../../../Utilities/ValidationHelper/ValidateForm';
import { validateEndDate } from '../validation/validation';
import { buildFormFields } from '../../../../Utilities/formHelper';

const styles = ({ breakpoints }) => ({
  formContainer: {
    '#formContainer': {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        margin: '5px 0',
        width: '100%'
      }
    },
    [breakpoints.down(960)]: {
      '#formContainer': {
        flexDirection: 'column',
        '& > *': {
          width: '100%'
        }
      }
    }
  }
});

class AddAdvertiserRevenue extends Component {
  state = {
    formValues: addAdvertiserRevenue(),
    submitting: false,
    dialog: {
      message: '',
      type: 'error',
      title: 'Error'
    }
  };

  handleChange = (field, value) => {
    const formValues = handleFormObjectChange(field, value, {
      ...this.state.formValues
    });
    this.setState({ formValues });
    this.validateEndDate(field);
    this.validateBuyer(field);
  };

  validateEndDate = (field) => {
    const { formValues } = this.state;
    if (
      (field === 'date_end' || field === 'date_start') &&
      !formValues[field].error
    ) {
      const isEndDateValid = validateEndDate(
        formValues.date_end.value,
        formValues.date_start.value
      );
      this.setState({
        formValues: {
          ...formValues,
          date_end: {
            ...formValues.date_end,
            error: !isEndDateValid.valid,
            message: isEndDateValid.message
          }
        }
      });
    }
  };

  validateBuyer = (field) => {
    const { formValues } = this.state;
    if (field === 'buyer') {
      this.setState({
        formValues: {
          ...formValues,
          name: {
            ...formValues.name,
            hidden: formValues[field].value !== 'custom'
          },
          date: {
            ...formValues.date,
            hidden: formValues[field].value !== 'custom'
          },
          date_start: {
            ...formValues.date_start,
            hidden: formValues[field].value === 'custom'
          },
          date_end: {
            ...formValues.date_end,
            hidden: formValues[field].value === 'custom'
          }
        }
      });
    }
  };

  toggleDialog = (message = '', type = 'error', title = 'Error') =>
    this.setState({
      dialog: { ...this.state.dialog, message, type, title }
    });

  handleSubmit = () => {
    const { valid, messages } = validateForSubmit(this.state.formValues);
    const endDateValidation = validateEndDate(
      this.state.formValues.date_end.value,
      this.state.formValues.date_start.value
    );
    if (!valid || !endDateValidation.valid) {
      if (endDateValidation.message.trim() !== '') {
        messages.push(endDateValidation.message);
      }
      this.toggleDialog(messages, 'error');
      return false;
    }
    this.setState({ submitting: true }, () =>
      NetworkRequest(
        'revenueAdjustments',
        formatSubmitValues(this.state.formValues),
        this.state.formValues.buyer.value === 'custom' ? 'custom' : 'advertiser'
      )
        .then(({ data }) => this.handleResponse(data))
        .catch(() => this.setState({ submitting: false }))
    );
  };

  handleResponse = (data) => {
    const state = { submitting: false };
    if (data.success) {
      Object.assign(state, { formValues: addAdvertiserRevenue() });
    }
    this.setState(state, () =>
      this.toggleDialog(
        data.message ? data.message : 'An error has happened',
        data.success ? 'success' : 'error',
        data.success ? 'Success' : 'Error'
      )
    );
  };

  render() {
    const { formValues, submitting, dialog } = this.state;

    return (
      <Box className={this.props.classes.formContainer}>
        <FormContainer
          formValues={buildFormFields(formValues, this.state)}
          submitting={submitting}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          inputWidth={8}
          separateBlock
          dialog={dialog}
          toggleDialog={this.toggleDialog}
        />
      </Box>
    );
  }
}

export default withStyles(AddAdvertiserRevenue, styles);
