import React, { Component } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { colors } from '../../../../Utilities/LenoxColors';

const styles = () => ({
  container: {
    margin: '1rem'
  },
  tableCell: {
    width: '50%',
    wordWrap: 'break-word',
    paddingLeft: '12px',
    paddingRight: 0
  },
  tableCellHead: {
    fontWeight: 'bold'
  },
  tableContainer: {
    border: 'solid 1px rgba(20,20,20,.3)',
    borderRadius: '8px',
    margin: '1rem'
  },
  tableTitle: {
    fontSize: '1.25rem',
    textAlign: 'center',
    padding: '.5rem',
    backgroundColor: colors.lenoxDark2,
    color: colors.lenoxLight1,
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px'
  }
});

class SkippedReasons extends Component {
  renderTableCell = (data, className = 'tableCell') => (
    <TableCell className={this.props.classes[className]}>{data}</TableCell>
  );

  renderRow = (row) => (
    <TableRow>{row.map((item) => this.renderTableCell(item))}</TableRow>
  );

  renderTable = () => (
    <Grid item xs={10} className={this.props.classes.tableContainer}>
      <div className={this.props.classes.tableTitle}>Skipped Reasons</div>
      <div>
        <Table>
          <TableHead>
            <TableRow>
              {['Reason', 'Count'].map((column) =>
                this.renderTableCell(column, 'tableCellHead')
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.data.map((row) => this.renderRow(row))}
          </TableBody>
        </Table>
      </div>
    </Grid>
  );

  render() {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: '5rem' }}
      >
        {this.renderTable()}
      </Grid>
    );
  }
}

export default withStyles(SkippedReasons, styles);
