import { providerID, siteOptions } from '../../common/commonRequests';
import { PROVIDER_TYPES } from '../../common/dropdownConstants';

const disableProviderFilter = (options: Array<unknown>) => {
  return options.length < 2;
};

export const filters = () => ({
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions(true),
    value: '2',
    inputWidth: 3
  },
  providerID: {
    name: 'providerID',
    type: 'select',
    label: 'Provider',
    dependencyName: 'site',
    secondDependencyName: 'providerType',
    options: (site?: string, providerType?: string) =>
      providerID(site, providerType),
    value: 'all',
    disabled: disableProviderFilter,
    inputWidth: 3,
    nameRelevant: true
  },
  providerType: {
    name: 'providerType',
    type: 'select',
    label: 'Provider Type',
    options: () => PROVIDER_TYPES,
    value: 'all',
    inputWidth: 3
  }
});
