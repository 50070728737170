/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { withStyles } from 'tss-react/mui';
import { Close } from './Icons/Close';

const styles = () => ({
  pillContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid rgb(48, 56, 65)',
    borderRadius: '32px',
    padding: '10px',
    marginRight: '4px',
    marginBottom: '4px'
  },
  closeButton: {
    display: 'flex',
    alignContent: 'center',
    backgroundColor: 'white',
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0,0,0,0.04)'
    }
  },
  affiliate: {
    marginbottom: '2px'
  }
});

function Pill(props) {
  const { classes } = props;

  return (
    <div className={classes.pillContainer}>
      <div className={classes.affiliate}>{props.affiliate}</div>
      {!props.submitting ? (
        <button
          className={classes.closeButton}
          onClick={() => props.removePill(props.affiliate)}
          disabled={props.submitting}
        >
          <Close />
        </button>
      ) : (
        ''
      )}
    </div>
  );
}

export default withStyles(Pill, styles);
