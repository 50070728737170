/* eslint-disable no-param-reassign */
export const HANDLE_CHANGE = 'HANDLE_PAGE_CHANGE';
export const HANDLE_RESET_STATE = 'HANDLE_RESET_STATE';
export const UPDATE_BREADCRUMBS = 'UPDATE_BREADCRUMBS';

export const handleChange = (state, value) => {
  return {
    state,
    type: HANDLE_CHANGE,
    value
  };
};

const checkForUniqueCrumb = (breadCrumbs, path, route) => {
  let uniqueCrumb = true;
  breadCrumbs.map((crumb) => {
    if (crumb.path === path && crumb.route === route) {
      uniqueCrumb = false;
    }
    return true;
  });
  return uniqueCrumb;
};

const checkForCurrentCrumb = (breadCrumbs, path, route, nextPath) => {
  let currentCrumb = false;
  let crumbIndex = 0;
  breadCrumbs.map((crumb, index) => {
    if (crumb.path === nextPath) {
      currentCrumb = true;
      crumbIndex = index;
    }
    return true;
  });
  return { currentCrumb, crumbIndex };
};

export const updateBreadCrumbs = (
  oldBreadCrumbs,
  route,
  path,
  name,
  nextPath
) => {
  const breadCrumbs = [...oldBreadCrumbs];

  if (checkForUniqueCrumb(breadCrumbs, path, route)) {
    if (breadCrumbs && breadCrumbs.length === 3) {
      breadCrumbs.splice(0, 1);
    }
    breadCrumbs.push({ route, path, name, timeAdded: Date.now() });
  }

  const { currentCrumb, crumbIndex } = checkForCurrentCrumb(
    breadCrumbs,
    path,
    route,
    nextPath
  );
  if (currentCrumb) {
    breadCrumbs.splice(crumbIndex, 1);
  }

  breadCrumbs.sort((a, b) => a.timeAdded - b.timeAdded);

  return {
    type: UPDATE_BREADCRUMBS,
    breadCrumbs
  };
};

export const resetState = (state, value) => {
  return {
    state,
    type: HANDLE_RESET_STATE,
    value
  };
};
