import { affiliateID, buyersByTypeList } from '../../../common/commonRequests';
import { DEVICES, PLACEMENT_SOURCES } from '../../../common/dropdownConstants';

export const filters = () => ({
  affiliateID: {
    name: 'affiliateID',
    type: 'autocomplete',
    label: 'Affiliate',
    options: () => affiliateID(),
    value: 'all'
  },
  buyer: {
    name: 'buyer',
    label: 'Buyers',
    type: 'select',
    options: () => buyersByTypeList(),
    value: 'all'
  },
  device: {
    name: 'device',
    type: 'select',
    label: 'Device',
    options: () => DEVICES,
    value: 'all'
  },
  source: {
    name: 'source',
    type: 'select',
    label: 'Source',
    value: 'all',
    options: () => PLACEMENT_SOURCES
  }
});
