import { type Dispatch } from 'redux';
import { checkToken } from '../../Components/Login/requests/requests';
import { NetworkRequest } from '../../Components/Utilities/NetworkRequests/NetworkRequests';
import { getFromLocalStorage } from '../../Components/Utilities/saveStateHelper';
import { logError } from '../logError';
import { handleChange } from '../../Components/lenox/actions';
import { handleChange as handleFilterChange } from '../../Components/Filters/actions/actions';
import {
  checkAffiliateDropdown,
  checkAgent
} from '../../Components/PermissionsWrappers/permissionChecks';
import { AFF_ID } from '../../Components/common/permissionConstants';
import { config } from '../../config';

export const excludeLocation = (location: string) =>
  !window.location.href.toLowerCase().includes(location);

export const excludeLoginLogout = () => {
  return (
    excludeLocation('login') &&
    excludeLocation('logout') &&
    excludeLocation('affiliatesignup') &&
    excludeLocation('pwreset')
  );
};

export const callCheckToken = async () => {
  const tokenExist = !!(await checkToken());

  if (!tokenExist && excludeLoginLogout()) {
    window.location.assign(`${config.routeBaseName}/Logout`);
  }

  return tokenExist;
};

export const getUserSettings = async (dispatch: Dispatch) => {
  if (!localStorage.getItem('token')) {
    return;
  }
  const { data } = await NetworkRequest(
    'tableSettings',
    {},
    'getTableSettings'
  );
  if (data && data[0] && data[0].datagrid_customization) {
    if (
      data[0].datagrid_customization &&
      typeof data[0].datagrid_customization === 'string'
    ) {
      try {
        dispatch(
          handleChange(
            'dataGridCustomization',
            JSON.parse(data[0].datagrid_customization)
          )
        );
      } catch {
        logError(`App error ${data[0].datagrid_customization}`);
      }
    }
  }
};

export const redirectToAgentSetup = (redirectToWelcome: boolean) => {
  if (redirectToWelcome && !window.location.href.includes('Welcome')) {
    window.location.assign(`${config.routeBaseName}/Welcome`);
  }
};

export const setFilters = (dispatch: Dispatch) => {
  if (checkAffiliateDropdown()) {
    dispatch(handleFilterChange('affiliateID', getFromLocalStorage(AFF_ID)));

    return;
  }

  if (checkAgent()) {
    dispatch(handleFilterChange('agentFilter', getFromLocalStorage('00734')));
  }
};
