/* eslint-disable no-unused-expressions */
/* eslint-disable no-empty */
const returnOnlyValidRows = (rows) =>
  rows.filter((row) => row.exclude_totals !== true);

export const calculateTotals = (totals, data, totalMapping, callback) => {
  const rows = returnOnlyValidRows(data);
  if (rows && Array.isArray(rows) && rows.length > 0 && rows[0] !== undefined) {
    Object.keys(totalMapping).forEach((totalMappingKey) => {
      const value = totalMapping[totalMappingKey];

      if (!value?.hardCodeTotals) {
        return;
      }

      Object.assign(totals, totals, {
        [totalMappingKey]: value.hardCodeTotals(rows)
      });
    });

    rows.forEach((valueObj) => {
      Object.keys(valueObj).forEach((objKey) => {
        const value = valueObj[objKey]
          ? valueObj[objKey].toString().replace(/[^\d.-]/g, '')
          : '';

        if (totalMapping[objKey]?.hardCodeTotals) {
          return;
        }

        if (
          totalMapping[objKey] &&
          totalMapping[objKey].type &&
          totalMapping[objKey].type !== 'header'
        ) {
          if (
            totalMapping[objKey].type.toString().includes('average') ||
            totalMapping[objKey].type.toString().includes('percent')
          ) {
          } else {
            Object.assign(totals, totals, {
              [objKey]: (totals[objKey] || 0) + (value ? parseFloat(value) : 0)
            });
          }
        } else {
          Object.assign(totals, totals, { [objKey]: false });
        }
      });
    });
  }

  Object.keys(totalMapping).map((key) => {
    if (totalMapping[key].hardCodeTotals) {
      return;
    }

    let dividend =
      typeof totals[totalMapping[key].dividend] !== 'undefined'
        ? totals[totalMapping[key].dividend]
        : null;
    let divisor =
      typeof totals[totalMapping[key].divisor] !== 'undefined'
        ? totals[totalMapping[key].divisor]
        : null;
    const min = totalMapping[key].min || null;
    const sum = (accumulator, currentValue) => accumulator + currentValue;

    if (
      dividend === null &&
      totalMapping[key].dividend &&
      totalMapping[key].dividend.constructor === Array
    ) {
      const aux = totalMapping[key].dividend.map(
        (dividendKey) => totals[dividendKey]
      );
      dividend = aux.reduce(sum);
    }
    if (
      divisor === null &&
      totalMapping[key].divisor &&
      totalMapping[key].divisor.constructor === Array
    ) {
      const aux = totalMapping[key].divisor.map(
        (divisorKey) => totals[divisorKey]
      );
      divisor = aux.reduce(sum);
    }
    if (
      divisor === null &&
      totalMapping[key].divisor &&
      typeof totalMapping[key].divisor === 'function'
    ) {
      divisor = totalMapping[key].divisor(rows);
    }
    if (
      dividend === null &&
      totalMapping[key].dividend &&
      typeof totalMapping[key].dividend === 'function'
    ) {
      dividend = totalMapping[key].dividend(rows);
    }
    if (
      totalMapping[key] &&
      totalMapping[key].type.toString().includes('average')
    ) {
      divisor === 0
        ? Object.assign(totals, totals, { [key]: 0 })
        : Object.assign(totals, totals, { [key]: dividend / divisor });
    } else if (
      totalMapping[key] &&
      totalMapping[key].type.toString().includes('percent') &&
      min
    ) {
      const value =
        (dividend / divisor) * 100 > 100 ? 100 : (dividend / divisor) * 100;
      divisor === 0
        ? Object.assign(totals, totals, { [key]: 0 })
        : Object.assign(totals, totals, { [key]: value < min ? min : value });
    } else if (
      totalMapping[key] &&
      totalMapping[key].type.toString().includes('percent')
    ) {
      divisor === 0
        ? Object.assign(totals, totals, { [key]: 0 })
        : Object.assign(totals, totals, {
            [key]:
              (dividend / divisor) * 100 > 100 &&
              !totalMapping[key].allowOver100
                ? 100
                : (dividend / divisor) * 100
          });
    }
    if (totalMapping[key] && totalMapping[key].overrideValue) {
      const overrideVal = parseFloat(totalMapping[key].overrideValue(rows));
      Object.assign(totals, totals, { [key]: overrideVal });
    }
    return true;
  });

  Object.assign(totals, totals, { timestamp: new Date().getTime() });

  callback(totals);
};
