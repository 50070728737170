import {
  affiliateID,
  buyerList,
  siteOptions
} from '../../../common/commonRequests';

export const filters = () => ({
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions(),
    value: '2',
    defaultVal: '2',
    inputWidth: 3
  },
  affiliateID: {
    name: 'affiliateID',
    type: 'autocomplete',
    label: 'Affiliate',
    options: () => affiliateID(),
    value: 'all'
  },
  buyer: {
    name: 'buyer',
    label: 'Buyers',
    type: 'select',
    options: (dependency) => buyerList(dependency, false, false, false, true),
    value: 'all'
  }
});
