import { NetworkRequest } from '../Utilities/NetworkRequests/NetworkRequests';

export const getBuyerReport = async (params) =>
  NetworkRequest('buyers', params);

export const getBuyerRestrictions = async (params) =>
  NetworkRequest('constraints', params);

export const getBuyerReturnsBreakdown = async (params) =>
  NetworkRequest('buyersReturnsBreakdown', params);

export const getAffiliateAllowList = async (params) =>
  NetworkRequest('buyerAffiliates', params);
