import { typedObjectKeys } from '../../Utilities/typeGuards/typedObjectKeys';
import { type PageListType } from '../../Utilities/types';

export default function alphabetizeMenuItems(list: PageListType) {
  const ordered = {} as PageListType;

  typedObjectKeys(list)
    .sort((a, b) => {
      const firstName = (list[a] as PageListType)?.displayName;
      const secondName = (list[b] as PageListType)?.displayName;

      if (firstName < secondName) {
        return -1;
      }

      if (firstName > secondName) {
        return 1;
      }

      return 0;
    })
    .forEach((key) => {
      ordered[key] = list[key];
    });
  return ordered;
}
