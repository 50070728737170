import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { KeyboardArrowDown } from '@mui/icons-material';

const useStyles = makeStyles()(() => ({
  sectionTitle: {
    padding: '15px 0',
    opacity: '.6',
    fontSize: '14px',
    fontWeight: 600,
    display: 'inline-flex',
    alignItems: 'center'
  },
  collapsible: {
    position: 'relative',
    width: '100%'
  },
  collapseButton: {
    position: 'absolute',
    right: '0',
    top: '0'
  },
  collapseArrow: {
    transform: 'rotate(0deg)',
    transition: 'all ease-in-out .25s'
  },
  collapsed: {
    transform: 'rotate(-180deg)'
  }
}));

function CollapseArrow({ setCollapsed, collapsed }) {
  const { classes } = useStyles();
  return (
    <IconButton
      className={classes.collapseButton}
      onClick={() => setCollapsed(!collapsed)}
      size="large"
    >
      <KeyboardArrowDown
        className={`${classes.collapseArrow}  ${
          collapsed && classes.collapsed
        }`}
      />
    </IconButton>
  );
}

const SectionTitle = React.memo(
  ({ title, setCollapsed, collapsed, collapsible, titleIcon }) => {
    const { classes } = useStyles();

    return !title ? (
      false
    ) : (
      <div
        className={`${classes.sectionTitle} ${
          collapsible && classes.collapsible
        }`}
      >
        {titleIcon && titleIcon}
        {title}
        {collapsible && (
          <CollapseArrow setCollapsed={setCollapsed} collapsed={collapsed} />
        )}
      </div>
    );
  }
);

SectionTitle.propTypes = {
  title: PropTypes.string,
  setCollapsed: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  collapsible: PropTypes.bool.isRequired,
  titleIcon: PropTypes.element
};

SectionTitle.defaultProps = {
  title: '',
  titleIcon: false
};

CollapseArrow.propTypes = {
  setCollapsed: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired
};

export default SectionTitle;
