import { siteOptions } from '../../../../common/commonRequests';
import { EXCLUDE_BUYER_TYPE_OPTIONS } from '../../../../common/dropdownConstants';

export const filters = () => ({
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions(),
    value: '2',
    inputWidth: 3
  },
  excludeBuyerType: {
    name: 'excludeBuyerType',
    label: 'Type',
    type: 'select',
    options: () => EXCLUDE_BUYER_TYPE_OPTIONS,
    value: 0
  }
});
