/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Grid,
  Slider,
  Typography,
  TextField,
  Box,
  InputAdornment
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { colors } from '../../../Utilities/LenoxColors';

const styles = () => ({
  formControl: {
    marginTop: '.5rem',
    marginBottom: '.5rem',
    position: 'relative'
  },
  margin: {
    marginRight: '1rem'
  },
  checkbox: {
    color: colors.lenoxDark2
  },
  colorPrimary: {
    color: `${colors.lenoxDark2}!important`
  },
  inline: {
    display: 'inline !important'
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  slider: {
    '& .MuiSlider-rail': {
      background: 'linear-gradient(to left, red 91%, green 9%)',
      opacity: 1
    },
    '& .MuiSlider-thumb': {
      border: ' 1px solid rgb(110 110 110)',
      boxShadow: 'none'
    }
  }
});

const Scale = (props) => {
  const [value, setValue] = React.useState(0);
  const sliderRef = useRef(null);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const numberPattern = /^-?\d*$/;

    if (inputValue !== '' && !numberPattern.test(inputValue)) {
      return;
    }

    const numberValue = Number(inputValue) || 0;
    if (numberValue >= -100 && numberValue <= 1000) {
      setValue(inputValue);
      props.onChange(props.name, numberValue);
    }
  };

  const valueLabelFormat = (value) => {
    const redIntensity = Math.max(30, -value);
    const greenIntensity = Math.max(300, value);
    const red = `rgba(255, 0, 0, ${redIntensity / 100})`;
    const green = `rgba(0, 255, 0, ${greenIntensity / 1000})`;

    return { red, green };
  };

  useEffect(() => {
    setValue(Math.round(props.value));
  }, [props.value]);

  useEffect(() => {
    if (sliderRef.current) {
      const { red, green } = valueLabelFormat(value);

      const parsedValue = Number(value) || 0;
      if (!value || parsedValue === 0) {
        sliderRef.current.querySelector(
          '.MuiSlider-rail'
        ).style.background = `linear-gradient(to left, green 91%, red 9%)`;
        sliderRef.current.querySelector('.MuiSlider-rail').style.opacity =
          '0.38';
        sliderRef.current.querySelector('.MuiSlider-thumb').style.color =
          'transparent';
      } else {
        sliderRef.current.querySelector(
          '.MuiSlider-rail'
        ).style.background = `linear-gradient(to left, ${green} 91%, ${red} 9%)`;
        sliderRef.current.querySelector('.MuiSlider-rail').style.opacity = '1';
        sliderRef.current.querySelector('.MuiSlider-thumb').style.color =
          value > 0 ? `${green}` : `${red}`;
      }
    }
  }, [value]);

  const renderScale = (classes) => {
    const { adornmentPosition, adornment, name, onChange, defaultVal } = props;
    return (
      <Box>
        <Typography id="input-slider" gutterBottom>
          {props.label}
          {props.subLabel && (
            <Typography variant="caption" style={{ marginLeft: '15px' }}>
              {props.subLabel}
            </Typography>
          )}
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Slider
              ref={sliderRef}
              value={value}
              track={false}
              defaultValue={defaultVal}
              min={-100}
              max={1000}
              size="small"
              onChange={handleSliderChange}
              onChangeCommitted={() => onChange(name, value)}
              aria-labelledby="input-slider"
              className={classes.slider}
            />
          </Grid>
          <Grid item>
            <TextField
              variant="standard"
              value={value}
              onChange={handleInputChange}
              style={{ width: '70px' }}
              inputProps={{
                // ...props.
                type: 'text',
                'aria-labelledby': 'input-slider'
              }}
              InputProps={{
                startAdornment:
                  adornmentPosition === 'start' ? (
                    <InputAdornment position={adornmentPosition}>
                      {adornment || ''}
                    </InputAdornment>
                  ) : null,
                endAdornment:
                  adornmentPosition === 'end' ? (
                    <InputAdornment position={adornmentPosition}>
                      {adornment || ''}
                    </InputAdornment>
                  ) : null
              }}
            />
          </Grid>
        </Grid>
        {props.subSubLabel && (
          <Typography variant="caption" style={{ marginLeft: '15px' }}>
            {props.subSubLabel}
          </Typography>
        )}
      </Box>
    );
  };

  const { classes, name, centered } = props;
  return (
    <Grid key={name} container justifyContent="center" alignItems="center">
      <Grid
        item
        xs={12}
        className={classNames(
          classes.margin,
          classes.formControl,
          centered ? classes.centered : ''
        )}
      >
        {renderScale(classes)}
      </Grid>
    </Grid>
  );
};

Scale.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

Scale.defaultProps = {
  label: '',
  error: false
};

export default withStyles(Scale, styles);
