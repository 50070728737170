import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useSubPages = () => {
  const location = useLocation();

  return useMemo(() => {
    // to remove first empty string
    const pagesArray = location.pathname.split('/').slice(1);
    const subPageExist = pagesArray.length > 1;

    return {
      pages: [...pagesArray].reverse(),
      route: pagesArray[0],
      subPage: subPageExist ? pagesArray.at(-1) : undefined
    };
  }, [location.pathname]);
};
