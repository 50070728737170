import { tss } from 'tss-react';
import { colors } from '../../Utilities/LenoxColors';

export const useBreadCrumbsStyles = tss.create(() => ({
  crumbContainer: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '39px'
  },
  crumbsContainer: {
    display: 'inline-flex',
    borderBottom: `solid 1px ${colors.lenoxDark1}`,
    paddingTop: '0'
  },
  button: {
    fontSize: '.75rem',
    color: 'rgba(0, 0, 0, 0.87)'
  }
}));
