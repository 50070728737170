/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  IconButton,
  CircularProgress
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import _ from 'lodash';
import { Done } from '@mui/icons-material';
import { NetworkRequest } from '../Utilities/NetworkRequests/NetworkRequests';
import { TextField } from './FormComponents';
import { colors } from '../../Utilities/LenoxColors';
import LoaderComponent from '../Utilities/LoaderComponent';
import Dialog from '../Dialog';

const styles = () => ({
  tableCell: {
    width: '50%'
  },
  tableContainer: {
    border: 'solid 1px rgba(20,20,20,.3)',
    borderRadius: '8px',
    margin: '1rem'
  },
  table: {},
  tableTitle: {
    fontSize: '1.25rem',
    textAlign: 'center',
    padding: '.5rem',
    backgroundColor: colors.lenoxDark2,
    color: colors.lenoxLight1,
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px'
  }
});

class FormSingleSubmit extends Component {
  static propTypes = {
    loadEndpoint: PropTypes.string.isRequired,
    loadEndpointParams: PropTypes.object,
    loadEndpointAction: PropTypes.string.isRequired,
    submitEndpoint: PropTypes.string.isRequired,
    submitEndpointAction: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    additionalSubmitFields: PropTypes.array,
    columnMapping: PropTypes.array.isRequired,
    title: PropTypes.string,
    hideHeaders: PropTypes.bool,
    confirmDialog: PropTypes.bool,
    confirmCallback: PropTypes.func,
    confirmText: PropTypes.func
  };

  static defaultProps = {
    confirmDialog: false,
    confirmCallback: () => {},
    confirmText: () => {},
    additionalSubmitFields: []
  };

  state = {
    valuesLoading: true,
    values: [],
    valuesOrig: [],
    adornmentOverride: {},
    adornmentPositionOverride: {},
    openConfirmDialog: false,
    updatingIndex: -1
  };

  componentDidMount() {
    this.loadValues();
  }

  loadValues = async () => {
    const { loadEndpoint, loadEndpointParams, loadEndpointAction } = this.props;
    this.setState({ valuesLoading: true });

    const { data } = await NetworkRequest(
      loadEndpoint,
      loadEndpointParams,
      loadEndpointAction
    );

    !!data &&
      this.setState({
        values: data,
        valuesOrig: data,
        valuesLoading: false
      });
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, () => {
        resolve();
      });
    });
  }

  updateAdornmentOverride = (index, adornment, adornmentPosition) => {
    const adornmentOverride = {
      ...this.state.adornmentOverride,
      [index]: adornment
    };
    const adornmentPositionOverride = {
      ...this.state.adornmentPositionOverride,
      [index]: adornmentPosition
    };
    this.setStateAsync({ adornmentOverride, adornmentPositionOverride });
  };

  valueChange = (index) => (name, value) => {
    this.setState({
      values: this.state.values.map((stateValue, _index) => {
        if (_index !== index) return stateValue;
        const returningObject = { ...stateValue };
        returningObject[name] = value;
        return returningObject;
      })
    });
  };

  preSubmitCheck = (idx) => {
    const { confirmDialog, confirmCallback } = this.props;
    if (confirmDialog && confirmCallback(this.state.values[idx])) {
      this.setState(
        {
          updatingIndex: idx
        },
        () => this.setState({ openConfirmDialog: true })
      );
    } else {
      this.submitValue(idx);
    }
  };

  submitValue = async (index) => {
    this.closeConfirmDialog();
    const {
      submitEndpoint,
      submitEndpointAction,
      fieldName,
      additionalSubmitFields
    } = this.props;
    if (this.state.values[index].saving) {
      return;
    }

    this.setState({
      values: this.state.values.map((value, _index) => {
        if (_index !== index) return value;
        return { ...value, saving: true };
      })
    });

    // make request
    const params = { id: this.state.values[index].id };
    params[fieldName] = this.state.values[index][fieldName];
    if (additionalSubmitFields[0]) {
      additionalSubmitFields.forEach((field) => {
        if (this.state.values[index][field] !== undefined) {
          params[field] = this.state.values[index][field];
        }
      });
    }
    await NetworkRequest(submitEndpoint, params, submitEndpointAction);

    this.setState({
      values: this.state.values.map((value, _index) => {
        if (_index !== index) return value;
        return { ...value, saving: null };
      }),
      valuesOrig: this.state.valuesOrig.map((value, _index) => {
        if (_index !== index) return value;
        const returningObj = { ...value };
        returningObj[fieldName] = this.state.values[index][fieldName];
        return returningObj;
      })
    });
  };

  closeConfirmDialog = () =>
    this.setState({ openConfirmDialog: false, updatingIndex: -1 });

  renderValueSubmit = (idx) => () =>
    (
      <IconButton
        className={this.props.classes.submit}
        onClick={() => this.preSubmitCheck(idx)}
        size="large"
      >
        {this.state.values[idx].saving != null ? (
          <CircularProgress />
        ) : (
          <Done />
        )}
      </IconButton>
    );

  renderRow = (values) => (
    <TableRow>
      {values.map((value) => (
        <TableCell align="left">{value}</TableCell>
      ))}
    </TableRow>
  );

  rowFormatter = (column, value, index) => {
    if (column.name === this.props.fieldName) {
      return this.renderValueFieldCell(
        index,
        value[this.props.fieldName],
        this.props.fieldName,
        this.props.inputType,
        column.adornment,
        column.adornmentPosition
      );
    }
    if (column.customRender) {
      return column.customRender({
        index,
        value: value[column.name],
        onChange: this.valueChange(index),
        updateAdornmentOverride: this.updateAdornmentOverride
      });
    }
    return value[column.name];
  };

  renderTable = (values) =>
    values.length > 0 && (
      <Grid item xs={12} className={this.props.classes.tableContainer}>
        <div className={this.props.classes.tableTitle}>{this.props.title}</div>
        <Table className={this.props.classes.table}>
          {!this.props.hideHeaders && (
            <TableHead>
              {this.renderRow(
                this.props.columnMapping.map((column) => column.display)
              )}
            </TableHead>
          )}
          <TableBody>
            {values.map((value, index) =>
              this.renderRow([
                ...this.props.columnMapping
                  .filter((column) => column.name)
                  .map((column) => this.rowFormatter(column, value, index))
              ])
            )}
          </TableBody>
        </Table>
        {this.renderConfirmDialog()}
      </Grid>
    );

  renderConfirmDialog = () =>
    this.props.confirmDialog && (
      <Dialog
        title="Please Confirm"
        children={
          this.state.updatingIndex !== -1
            ? this.props.confirmText(
                this.state.values[this.state.updatingIndex][
                  this.props.fieldName
                ]
              )
            : ''
        }
        open={this.state.openConfirmDialog}
        onRequestSave={() => this.submitValue(this.state.updatingIndex)}
        onRequestClose={() => setTimeout(() => this.closeConfirmDialog(), 300)}
        type="confirm"
        saveButtonText="Confirm"
      />
    );

  renderValueFieldCell = (
    index,
    fieldValue,
    field,
    inputType,
    adornment,
    adornmentPosition
  ) => (
    <div>
      <TextField
        value={fieldValue}
        key={`${field}_${index}`}
        name={field}
        inputType={inputType}
        adornment={this.state.adornmentOverride[index] || adornment || false}
        adornmentPosition={
          this.state.adornmentPositionOverride[index] ||
          adornmentPosition ||
          false
        }
        includeSubmit={
          !_.isEqual(this.state.values[index], this.state.valuesOrig[index])
        }
        onChange={this.valueChange(index)}
        submitRender={this.renderValueSubmit(index)}
      />
    </div>
  );

  render() {
    const { values, valuesLoading } = this.state;
    return valuesLoading ? (
      <LoaderComponent padding={150} />
    ) : (
      this.renderTable(values)
    );
  }
}

export default withStyles(FormSingleSubmit, styles);
