/* eslint-disable default-param-last */
import moment from 'moment';
import { HANDLE_CHANGE } from '../actions/actions';
import { getLocalStorageDateRangeValues } from '../Utilities/getLocalStorageDateRangeValues';

export const initialState = {
  startDate: getLocalStorageDateRangeValues().startDate || moment(),
  endDate: getLocalStorageDateRangeValues().endDate || moment(),
  dateRange: getLocalStorageDateRangeValues().dateRange || 'today',
  peak: getLocalStorageDateRangeValues().peak || 'all',
  hide: false
};

export const dateRange = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_CHANGE:
      return {
        ...state,
        [action.state]: action.value
      };
    default:
      return state;
  }
};
