export const HANDLE_CHANGE = 'HANDLE_LENOX_CHANGE';
export const HANDLE_GET_DATA_CHANGE = 'HANDLE_GETDATA_CHANGE';
export const HANDLE_LAST_UPDATED_CHANGE = 'HANDLE_LAST_UPDATED_CHANGE';
export const HANDLE_UPDATE_DATA_CALL_CHANGE = 'HANDLE_UPDATE_DATA_CALL_CHANGE';

export const handleChange = (state, value) => {
  return {
    state,
    type: HANDLE_CHANGE,
    value
  };
};

export const handleGetDataChange = (getData) => {
  return {
    getData,
    type: HANDLE_GET_DATA_CHANGE
  };
};

export const handleLastUpdatedChange = (lastUpdated) => {
  return {
    lastUpdated,
    type: HANDLE_LAST_UPDATED_CHANGE
  };
};

export const handleUpdateDataCallChange = () => {
  return {
    type: HANDLE_UPDATE_DATA_CALL_CHANGE
  };
};
