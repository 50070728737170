import { defaultInputProps } from '../../../../FormContainer/defaultFormStates';
import Validation from '../../AgentList/validation/validation';

export const billingValues = {
  first_name: {
    ...defaultInputProps,
    name: 'first_name',
    label: 'First Name',
    validate: Validation.first
  },
  last_name: {
    ...defaultInputProps,
    name: 'last_name',
    label: 'Last Name',
    validate: Validation.last
  },
  address: {
    ...defaultInputProps,
    name: 'address',
    label: 'Billing Address'
  },
  city: {
    ...defaultInputProps,
    name: 'city',
    label: 'City'
  },
  state: {
    ...defaultInputProps,
    name: 'state',
    label: 'State'
  }
};
