import {
  validationPayload,
  validateStringExists
} from '../../../Utilities/ValidationHelper/validationHelpers';

export default class LoginValidation {
  static password = (value, min) => {
    const valid = validateStringExists(value);
    if (!valid || value.length < min) {
      return validationPayload(
        false,
        min
          ? `Please enter a password at least ${min} characters long`
          : 'the field cannot be empty'
      );
    }
    return validationPayload(valid, '');
  };

  static confirmPassword = (value, min, max, password) => {
    const valid = validateStringExists(value);
    if (!valid || value.length < min) {
      return validationPayload(
        false,
        min
          ? `Please enter a password at least ${min} characters long`
          : 'the field cannot be empty'
      );
    }

    if (value !== password.value) {
      return validationPayload(
        false,
        'Please make sure that your confirmed password matches your new password'
      );
    }

    return validationPayload(valid, '');
  };
}
