/* eslint-disable react/default-props-match-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Grid,
  TextField as Field,
  InputAdornment,
  IconButton,
  Tooltip
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { ChevronRight, InfoOutlined } from '@mui/icons-material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { colors } from '../../../Utilities/LenoxColors';

const styles = ({ spacing, breakpoints }) => ({
  margin: {
    margin: spacing(1)
  },
  textField: {
    flexBasis: 200,
    width: '100%',
    color: `${colors.lenoxLight1}!important`,
    [breakpoints.down(960)]: {
      [`input[type="date"]::-webkit-calendar-picker-indicator,
      input[type="date"]::-webkit-calendar-picker-indicator,
      input[type="date"]::-webkit-inner-spin-button,
      input[type="date"]::-webkit-calendar-picker-indicator`]: {
        display: 'none',
        '-webkit-appearance': 'none !important',
        opacity: 0
      },
      'input[type="date"]': {
        '-webkit-appearance': 'none !important'
      }
    },
    [breakpoints.up(959)]: {
      'input[type="date"] + svg': {
        display: 'none'
      }
    }
  },
  submit: {
    display: 'inline-flex'
  },
  tooltip: {
    display: 'flex',
    alignItems: 'center',
    gap: 4
  }
});

class TextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
    if (this.state.value !== this.props.value && !this.props.blur) {
      this.setState({ value: this.props.value });
    }
  }

  handleChange = ({ target: { value } }) => {
    this.setState({ value });
    if (!this.props.blur) {
      this.globalChange(value);
    }
  };

  globalChange = (value) => this.props.onChange(this.props.name, value);

  renderField = () => {
    const {
      classes,
      adornmentPosition,
      adornment,
      inputType,
      message,
      blur,
      tooltipMessage,
      label
    } = this.props;

    let endAdornment = null;

    if (adornmentPosition === 'end') {
      endAdornment = (
        <InputAdornment position={adornmentPosition}>
          {adornment || ''}
        </InputAdornment>
      );
    } else if (inputType === 'date') {
      endAdornment = <CalendarTodayIcon fontSize="10px" />;
    }

    return (
      <Field
        label={
          tooltipMessage ? (
            <div className={this.props.classes.tooltip}>
              {label}
              <Tooltip title={tooltipMessage} placement="bottom">
                <InfoOutlined style={{ height: '.7em', width: '.7em' }} />
              </Tooltip>
            </div>
          ) : (
            label
          )
        }
        multiline={this.props.multiline}
        rows={this.props.rows}
        rowsMax={this.props.rowsMax}
        fullwidth={this.props.fullwidth}
        inputProps={this.props.inputProps}
        error={this.props.error}
        id={label}
        className={classNames(classes.textField)}
        variant="outlined"
        InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
        helperText={tooltipMessage ? '' : message || ''}
        placeholder={this.props.placeholder || ''}
        type={inputType || 'text'}
        value={this.state.value || ''}
        onChange={this.handleChange}
        onWheel={inputType === 'number' ? (e) => e.target.blur() : undefined}
        disabled={this.props.disabled}
        onBlur={
          blur ? ({ target: { value } }) => this.globalChange(value) : () => {}
        }
        // eslint-disable-next-line
        InputProps={{
          endAdornment,
          startAdornment:
            adornmentPosition === 'start' ? (
              <InputAdornment position={adornmentPosition}>
                {adornment || ''}
              </InputAdornment>
            ) : null,
          style: this.props.style || {}
        }}
      />
    );
  };

  renderSubmitButton = () => (
    <IconButton className={this.props.classes.submit} size="large">
      <ChevronRight />
    </IconButton>
  );

  render() {
    return (
      <Grid
        key={this.props.name}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} className={classNames(this.props.classes.margin)}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              item
              xs={this.props.includeSubmit ? this.props.fieldGrid || 10 : 12}
            >
              {this.renderField()}
            </Grid>
            {this.props.includeSubmit && (
              <Grid item xs={this.props.buttonsGrid || 2}>
                {this.props.submitRender
                  ? this.props.submitRender()
                  : this.renderSubmitButton()}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

TextField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

TextField.defaultProps = {
  label: '',
  multiline: false,
  rows: '',
  rowsMax: '',
  fullwidth: 'false',
  error: false,
  inputProps: {},
  includeSubmit: false
};

export default withStyles(TextField, styles);
