/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import { Grid } from '@mui/material';
import FormContainer from '../../../../../FormContainer/FormContainer';
import { editBuyerNoteState } from '../defaultState/state';
import { handleFormObjectChange } from '../../../../../Utilities/HandleFormObjectChange';
import { NetworkRequest } from '../../../../../Utilities/NetworkRequests/NetworkRequests';

export default class EditBuyerNotes extends Component {
  state = {
    formValues: editBuyerNoteState(),
    submitting: false,
    dialog: {
      message: '',
      type: 'error',
      title: 'Error'
    }
  };

  componentDidMount() {
    this.loadFormValues();
  }

  loadFormValues = () => {
    const { row } = this.props;
    this.setState((prevState) => ({
      formValues: {
        note: {
          ...prevState.formValues.note,
          value: row.note || prevState.formValues.note.value
        }
      }
    }));
  };

  handleChange = (field, value) => {
    const formValues = handleFormObjectChange(field, value, {
      ...this.state.formValues
    });
    this.setState({ formValues });
  };

  toggleDialog = (message = '', type = 'error', title = 'Error') =>
    this.setState({
      dialog: { ...this.state.dialog, message, type, title }
    });

  handleSubmit = () => {
    const { row } = this.props;
    let action = null;
    if (!!row.note && !!this.state.formValues.note.value) {
      action = 'update';
    }
    if (!!this.state.formValues.note.value && !row.note) {
      action = 'insert';
    }
    if (
      (!!row.note && !this.state.formValues.note.value) ||
      (!row.note && !this.state.formValues.note.value)
    ) {
      action = 'delete';
      this.setState((prevState) => ({
        formValues: {
          note: {
            ...prevState.formValues.note,
            value: null
          }
        }
      }));
    }
    this.handleSubmitRequest(action);
  };

  handleSubmitRequest = (action) => {
    const { row, onChange, getData } = this.props;
    Object.keys(this.state.formValues).map((key) =>
      onChange(
        row,
        key,
        action === 'delete' ? null : this.state.formValues[key].value
      )
    );
    this.setState({ submitting: true }, () =>
      NetworkRequest('buyerNotes', {
        action,
        buyer_id: row.buyer_id,
        note: this.state.formValues.note.value
      })
        .then(({ data }) => {
          this.handleResponse(data);
          if (action === 'insert' || action === 'delete') {
            getData();
          }
        })
        .catch(() => this.setState({ submitting: false }))
    );
  };

  handleResponse = (data) => {
    this.setState({ submitting: false });
    if (data && data === true) {
      this.toggleDialog('Buyer successfully updated', 'success', 'Success');
    } else {
      this.toggleDialog('An error has happened', 'error', 'Error');
    }
  };

  render() {
    const { formValues, submitting, dialog } = this.state;
    const { row } = this.props;
    return (
      <Grid>
        <FormContainer
          formTitle={row.buyer}
          formValues={formValues}
          submitting={submitting}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          inputWidth={10}
          separateBlock
          dialog={dialog}
          toggleDialog={this.toggleDialog}
          rows={8}
        />
      </Grid>
    );
  }
}
