import { useContext } from 'react';
import { BreadCrumbsContext } from './BreadCrumbsProvider';

export const useBreadCrumbs = () => {
  const value = useContext(BreadCrumbsContext);

  if (!value) {
    throw new Error('Wrap the component in breadCrumbsContext');
  }

  return value;
};
