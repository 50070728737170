import { validateFormValue } from './ValidationHelper/ValidateForm';

export const createNewForm = (field, value, formValues) => {
  const { valid, message } = validateFormValue(value, field, formValues);

  Object.assign(formValues[field], formValues[field], {
    value,
    error: !valid,
    message
  });

  return formValues;
};

export const handleFormObjectChange = (field, value, formValues) => {
  let returnValues = createNewForm(field, value, formValues);
  if (formValues[field].minMaxField) {
    returnValues = createNewForm(
      formValues[field].minMaxField,
      formValues[formValues[field].minMaxField].value,
      formValues
    );
  }

  return returnValues;
};
