import { siteOptions } from '../../../../common/commonRequests';

function generateYearOptions() {
  const currentYear = new Date().getFullYear();
  const startYear = 2020;
  const yearOptions = [];

  for (let year = currentYear; year >= startYear; year -= 1) {
    yearOptions.push({ value: year, display: year.toString() });
  }

  return yearOptions;
}

const monthOptions = [
  { value: '1', display: 'January' },
  { value: '2', display: 'February' },
  { value: '3', display: 'March' },
  { value: '4', display: 'April' },
  { value: '5', display: 'May' },
  { value: '6', display: 'June' },
  { value: '7', display: 'July' },
  { value: '8', display: 'August' },
  { value: '9', display: 'September' },
  { value: '10', display: 'October' },
  { value: '11', display: 'November' },
  { value: '12', display: 'December' }
];

export const filters = () => ({
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions(true),
    value: '2',
    inputWidth: 3
  },
  year: {
    name: 'year',
    type: 'select',
    label: 'Year',
    options: () => generateYearOptions(),
    defaultVal: '2020',
    inputWidth: 3
  },
  startMonth: {
    name: 'startMonth',
    type: 'select',
    label: 'From Month',
    options: () => monthOptions,
    defaultVal: '1',
    inputWidth: 3
  },
  endMonth: {
    name: 'endMonth',
    type: 'select',
    label: 'To Month',
    options: () => monthOptions,
    defaultVal: '1',
    inputWidth: 3
  }
});
