const localStorageEmail = () => localStorage.getItem('email');

export const saveToLocalStorage = (stateName, stateValue) => {
  const email = localStorageEmail();

  if (email) {
    localStorage.setItem(
      email,
      JSON.stringify({
        ...JSON.parse(localStorage.getItem(email)),
        [stateName]: stateValue
      })
    );
  }
  return stateValue;
};

export const getFromLocalStorage = (stateName, defaultValue) => {
  const email = localStorageEmail();

  if (
    !!email &&
    !!localStorage.getItem(email) &&
    JSON.parse(localStorage.getItem(email))[stateName] !== undefined
  ) {
    return JSON.parse(localStorage.getItem(email))[stateName];
  }
  if (localStorage.getItem(stateName)) {
    return localStorage.getItem(stateName);
  }

  return defaultValue;
};
