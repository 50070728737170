import { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { colors } from '../../../../Utilities/LenoxColors';
import Table from '../../../TableContainer/Table';
import { trimPredicate } from '../../../TableContainer/TableContainer/TableContainer';
import LoaderComponent from '../../../Utilities/LoaderComponent';
import { NetworkRequest } from '../../../Utilities/NetworkRequests/NetworkRequests';
import { getColumnWidths } from '../../../TableContainer/TableUtilities/getColumnWidths';
import { getColumnCompare } from '../../../TableContainer/TableUtilities/columnFormatter';
import { isMobileDevice } from '../../../common/utilities';
import CSVButton from '../../../TableContainer/TableUtilities/CSVButton';

const useStyles = makeStyles()(() => ({
  container: {
    margin: '1rem'
  },
  tableCell: {
    width: '50%',
    wordWrap: 'break-word',
    paddingLeft: '12px',
    paddingRight: '12px'
  },
  tableCellHead: {
    fontWeight: 'bold'
  },
  tableContainer: {
    border: 'solid 1px rgba(20,20,20,.3)',
    borderRadius: '8px',
    margin: '1rem'
  },
  tableTitle: {
    fontSize: '1.25rem',
    textAlign: 'center',
    padding: '.5rem',
    backgroundColor: colors.lenoxDark2,
    color: colors.lenoxLight1,
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px'
  }
}));

function BuyerAffiliatesBreakdown(props) {
  const { classes } = useStyles();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    params,
    buyer,
    columns,
    cellComponent,
    endpoint,
    sortingColumn,
    csv
  } = props;
  const csvColumns = [...columns];
  csvColumns[1] = { ...csvColumns[1], title: 'Estimated Revenue' };

  useEffect(() => {
    async function makeRequest() {
      setLoading(true);
      const { data } = await NetworkRequest(endpoint, {
        ...params,
        buyer
      });
      setRows(data ? data.rows || data : []);
      setLoading(false);
    }
    makeRequest();
    // eslint-disable-next-line
  }, [buyer]);

  const newFilters = useMemo(() => {
    return columns.map(({ name }) => {
      if (name === 'affiliate_name') {
        return {
          columnName: name,
          predicate: (_, filter, row) => {
            const newVal = `${row.affiliate_name} (#${row.affiliate_id})`;

            return trimPredicate(newVal, filter, row);
          }
        };
      }

      return {
        columnName: name,
        predicate: trimPredicate
      };
    });
  }, [columns]);

  const renderTable = () => (
    <Grid item xs={10} className={classes.tableContainer}>
      <div className={classes.tableTitle}>Buyer Affiliates Breakdown</div>
      <div>
        {!isMobileDevice() && csv && (
          <CSVButton
            params={params}
            columns={csvColumns}
            filename="Buyer Affiliates Breakdown"
            data={rows
              .map((row) => ({
                ...row,
                affiliate_name: `${row.affiliate_name} (#${row.affiliate_id})`
              }))
              .sort((a, b) => a.revenue_final - b.revenue_final)
              .reverse()}
            marginTop="1rem"
            breakdown
            showButton={Boolean(rows.length) && !loading}
          />
        )}
        <Table
          loading={loading}
          rows={rows}
          columns={columns}
          cellComponent={cellComponent}
          defaultColumnWidths={getColumnWidths(columns)}
          getColumnCompare={getColumnCompare(columns)}
          tableColumnLocalStorageName="buyer_affiliate_breakdown"
          integratedFilteringColumnExtensions={newFilters}
          defaultSortingOrder={[
            { columnName: sortingColumn, direction: 'desc' }
          ]}
        />
      </div>
    </Grid>
  );

  return (
    (loading && <LoaderComponent padding={150} />) || (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: '3rem' }}
      >
        {renderTable()}
      </Grid>
    )
  );
}

BuyerAffiliatesBreakdown.propTypes = {
  buyer: PropTypes.string.isRequired,
  columns: PropTypes.instanceOf(Object).isRequired,
  params: PropTypes.instanceOf(Object).isRequired,
  cellComponent: PropTypes.func.isRequired,
  csv: PropTypes.bool
};

BuyerAffiliatesBreakdown.defaultProps = {
  csv: false
};

export default BuyerAffiliatesBreakdown;
