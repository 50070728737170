import { affiliateID, siteOptions } from '../../../../common/commonRequests';
import {
  getPixelSteps,
  getPixelLocations,
  getPixelTypes
} from '../../AddPixel/defaultState/state';

export const filters = () => ({
  affiliateID: {
    name: 'affiliateID',
    type: 'autocomplete',
    label: 'Affiliate ID',
    options: () => affiliateID(),
    value: 'none',
    inputWidth: 6
  },
  affiliateSite: {
    name: 'affiliateSite',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions(),
    value: 'none',
    inputWidth: 6
  },
  pixelType: {
    name: 'pixelType',
    type: 'select',
    label: 'Pixel Type',
    options: (dependency) => getPixelTypes(dependency),
    value: '-1',
    inputWidth: 6,
    dependencyName: 'pixelLocation'
  },
  pixelLocation: {
    name: 'pixelLocation',
    type: 'select',
    label: 'Pixel Location',
    options: (dependency) => getPixelLocations(dependency),
    value: '-1',
    inputWidth: 6,
    dependencyName: 'pixelType'
  },
  pixelStep: {
    name: 'pixelStep',
    type: 'select',
    label: 'Pixel Step',
    options: (dependency) => getPixelSteps(dependency),
    value: '-1',
    inputWidth: 6,
    dependencyName: 'pixelType'
  }
});
