/* eslint-disable default-param-last */
import { HANDLE_AUTOMATIC_TESTING_CHANGE } from './actions';

export const initialState = {
  automaticTesting: Date.now()
};

export const automaticTesting = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_AUTOMATIC_TESTING_CHANGE:
      return {
        ...state,
        automaticTesting: action.value
      };
    default:
      return state;
  }
};
