/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from '@mui/material/Button';
import _noop from 'lodash/noop';
import { colors } from '../../Utilities/LenoxColors';

class ErrorDialog extends Component {
  static propTypes = {
    confirmButtonText: PropTypes.string,
    contentAlign: PropTypes.oneOf(['center', 'left', 'right']),
    footerAlign: PropTypes.oneOf(['center', 'left', 'right']),
    shouldRenderAction: PropTypes.bool,
    onRequestClose: PropTypes.func,
    children: PropTypes.node,
    toggleOpenModal: PropTypes.func
  };

  static defaultProps = {
    confirmButtonText: 'Close',
    contentAlign: 'center',
    footerAlign: 'right',
    shouldRenderAction: true,
    onRequestClose: _noop,
    children: null,
    toggleOpenModal: _noop
  };

  onConfirm = () =>
    Promise.resolve(this.props.onRequestClose()).then(() =>
      this.props.toggleOpenModal(false)
    );

  render() {
    const { children, confirmButtonText, contentAlign, footerAlign, classes } =
      this.props;

    return (
      <div>
        <div
          className={classes.maximusDialogContent}
          style={{ textAlign: contentAlign }}
        >
          <ul style={{ textAlign: 'left', lineHeight: '2rem' }}>
            {(typeof children === 'string' ? [children] : children).map(
              (child) => (
                <li>{child}</li>
              )
            )}
          </ul>
        </div>

        {this.props.shouldRenderAction && (
          <div className={cn(classes.maximusDialogFooter, footerAlign)}>
            <Button
              onClick={this.onConfirm}
              variant="contained"
              style={{
                backgroundColor: colors.lenoxSuccess1,
                color: 'white',
                minWidth: '50%',
                margin: '0 25%'
              }}
            >
              {confirmButtonText}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default ErrorDialog;
