import { affiliateID } from '../../../common/commonRequests';

export const filters = () => ({
  affiliateID: {
    name: 'affiliateID',
    type: 'autocomplete',
    label: 'Affiliate',
    options: () => affiliateID(),
    value: 'all',
    inputWidth: 3
  }
});
