import './Logrocket';
import { type FC, memo, type ReactElement } from 'react';
import { useApp } from './Utilities/hooks/useApp';
import LoaderComponent from './Components/Utilities/LoaderComponent';
import Routes from './Routes/Routes';
import './App.css';

interface IAppProps {
  children?: ReactElement;
  isTesting?: boolean;
}

// @ts-expect-error rewrite prototype
// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function () {
  return this.toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const App: FC<IAppProps> = memo(({ children, isTesting }) => {
  const { isLoading } = useApp();

  if (isLoading && !isTesting) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <LoaderComponent padding={150} />
      </div>
    );
  }

  return children ?? <Routes />;
});
