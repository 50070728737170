export const defaultInputProps = {
  name: 'name',
  label: 'Name',
  type: 'input',
  validate: () => {},
  value: '',
  defaultVal: '',
  error: false,
  message: '',
  multiple: false,
  hidden: false,
  fullwidth: 'true'
};
