/* eslint-disable default-param-last */
import {
  HANDLE_CHANGE,
  HANDLE_GET_DATA_CHANGE,
  HANDLE_LAST_UPDATED_CHANGE,
  HANDLE_UPDATE_DATA_CALL_CHANGE
} from './actions';

export const initialState = {
  loggedIn: false,
  agentSetup: false,
  getTableData: () => {},
  lastUpdated: '',
  updateDataCall: false,
  dataGridCustomization: {
    columnOrder: {},
    columnSizes: {},
    hideColumns: {}
  }
};

export const lenox = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_CHANGE:
      return {
        ...state,
        [action.state]: action.value
      };
    case HANDLE_GET_DATA_CHANGE:
      return {
        ...state,
        getTableData: action.getData
      };
    case HANDLE_UPDATE_DATA_CALL_CHANGE:
      return {
        ...state,
        updateDataCall: !state.updateDataCall
      };
    case HANDLE_LAST_UPDATED_CHANGE:
      return {
        ...state,
        lastUpdated: action.lastUpdated
      };
    default:
      return state;
  }
};
