import { memo, type FC } from 'react';
import { Grid, Button } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { useBreadCrumbsStyles } from './styles';
import { useBreadCrumbs } from '../../providers/BreadCrumbs/useBreadCrumbs';

export const BreadCrumbs: FC = memo(() => {
  const { classes } = useBreadCrumbsStyles();
  const { breadCrumbs, goTobreadCrumb } = useBreadCrumbs();

  return (
    <Grid item xs={12}>
      <div className={classes.crumbsContainer}>
        {breadCrumbs.map((crumb, index) => {
          const { name } = crumb;

          return (
            <div key={name} className={classes.crumbContainer}>
              {!!index && <ChevronLeft />}
              <Button
                className={classes.button}
                onClick={() => goTobreadCrumb(crumb)}
              >
                {name}
              </Button>
            </div>
          );
        })}
      </div>
    </Grid>
  );
});
