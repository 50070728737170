import { defaultInputProps } from '../../../../FormContainer/defaultFormStates';
import Validation from '../validation/validation';

const prepayOptions = [
  { value: 'on-demand', display: 'On-Demand' },
  { value: 'prepay', display: 'Prepay Amount' }
];

export const prepayValue = {
  payment_type: {
    ...defaultInputProps,
    type: 'select',
    options: () => prepayOptions,
    value: 'prepay',
    name: 'payment_type',
    label: 'Payment Type'
  },
  prepay_amount: {
    ...defaultInputProps,
    validate: Validation.nonNegativeNumber,
    name: 'prepay_amount',
    label: 'Payment Amount',
    adornmentPosition: 'start',
    adornment: '$'
  }
};
