import { defaultInputProps } from '../../../FormContainer/defaultFormStates';
import ValidatePasswords from '../validation/validation';

export const updatePasswordState = () => ({
  password: {
    ...defaultInputProps,
    inputType: 'password',
    name: 'password',
    label: 'Current Password',
    value: '',
    defaultValue: false,
    validate: ValidatePasswords.password
  },
  new_password: {
    ...defaultInputProps,
    inputType: 'password',
    name: 'new_password',
    label: 'New Password',
    value: '',
    defaultValue: false,
    validate: ValidatePasswords.password
  },
  confirm_password: {
    ...defaultInputProps,
    inputType: 'password',
    name: 'confirm_password',
    label: 'Confirm New Password',
    value: '',
    defaultValue: false,
    validate: ValidatePasswords.confirmPassword,
    minMaxField: 'new_password'
  }
});
