import {
  affiliateID,
  siteOptions,
  tyType,
  lbType
} from '../../../common/commonRequests';

export const filters = (lb = false) => {
  const filtersList = {
    site: {
      name: 'site',
      type: 'select',
      label: 'Vertical',
      options: () => siteOptions(),
      value: '2',
      inputWidth: 3
    },
    affiliateID: {
      name: 'affiliateID',
      type: 'autocomplete',
      label: 'Affiliate',
      options: () => affiliateID(),
      value: 'all',
      inputWidth: 3
    }
  };
  if (!lb) {
    filtersList.tyType = {
      name: 'tyType',
      type: 'select',
      label: 'TY Type',
      options: () => tyType(),
      value: 'default',
      inputWidth: 3
    };
  }
  if (lb) {
    filtersList.lbType = {
      name: 'lbType',
      type: 'select',
      label: 'LB Type',
      options: () => lbType(),
      value: 'all',
      inputWidth: 3
    };
  }
  return filtersList;
};
