import { getAPIUsers } from '../../../common/commonRequests';

const APITypeFilter = [
  { value: 'all', display: 'All' },
  { value: 'ping', display: 'Ping' },
  { value: 'post', display: 'Post' },
  { value: 'directpost', display: 'Direct Post' }
];

export const filters = () => ({
  apiUser: {
    name: 'apiUser',
    type: 'select',
    label: 'API User',
    defaultVal: 'all',
    value: 'all',
    options: () => getAPIUsers()
  },
  apiType: {
    name: 'apiType',
    type: 'select',
    label: 'Type',
    defaultVal: 'all',
    value: 'all',
    options: () => APITypeFilter
  },
  pingID: {
    name: 'pingID',
    type: 'input',
    label: 'Ping ID',
    value: '',
    blur: true,
    includeSubmit: true
  }
});
