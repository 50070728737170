import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { button } from './overrides/button';
import { slider } from './overrides/slider';
import { select } from './overrides/select';
import { switchOverride } from './overrides/switch';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  palette: {
    // Default buttons colors from MUI 4
    default: {
      main: grey[300],
      dark: '#d5d5d5',
      light: '#e6e6e6',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    }
  },
  components: {
    MuiButton: button,
    MuiSlider: slider,
    MuiSwitch: switchOverride,
    MuiNativeSelect: select
  }
});
