/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, Checkbox as CheckBox, InputLabel } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { colors } from '../../../Utilities/LenoxColors';

const styles = () => ({
  formControl: {
    marginTop: '.5rem',
    marginBottom: '.5rem',
    position: 'relative'
  },
  margin: {
    marginRight: '1rem'
  },
  checkbox: {
    color: colors.lenoxDark2
  },
  colorPrimary: {
    color: `${colors.lenoxDark2}!important`
  },
  inline: {
    display: 'inline-flex',
    verticalAlign: 'middle'
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

class Checkbox extends Component {
  renderInputLabel = (classes) => (
    <InputLabel
      ref={(ref) => {
        this.InputLabelRef = ref;
      }}
      htmlFor={this.props.name}
      error={this.props.error}
      className={this.props.labelInline && classNames(classes.inline)}
    >
      {this.props.label}
    </InputLabel>
  );

  renderCheckbox = (classes) => {
    // eslint-disable-next-line
    const checked = this.props.value && this.props.value != 0;
    return (
      <CheckBox
        value={this.props.value}
        name={this.props.name}
        checked={checked}
        id={this.props.name}
        color="primary"
        classes={{ colorPrimary: classes.colorPrimary }}
        className={classNames(classes.margin, classes.checked)}
        onChange={({ target: { checked } }) =>
          this.props.onChange(this.props.name, checked)
        }
      />
    );
  };

  render() {
    const { classes, name, centered } = this.props;
    return (
      <Grid key={name} container justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          className={classNames(
            classes.margin,
            classes.formControl,
            centered ? classes.centered : ''
          )}
        >
          {this.renderCheckbox(classes)}
          {this.renderInputLabel(classes)}
        </Grid>
      </Grid>
    );
  }
}

Checkbox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelInline: PropTypes.bool,
  centered: PropTypes.bool
};

Checkbox.defaultProps = {
  label: '',
  error: false
};

export default withStyles(Checkbox, styles);
