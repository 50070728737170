import {
  affiliateID,
  siteOptions,
  formOptions
} from '../../../../common/commonRequests';

export const filters = () => ({
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions(false, false, false),
    value: '2',
    inputWidth: 3
  },
  affiliateID: {
    name: 'affiliateID',
    type: 'autocomplete',
    label: 'Affiliate',
    options: () => affiliateID(true),
    value: 'all',
    inputWidth: 3,
    checkForGiraffe: true
  },
  formID: {
    name: 'formID',
    type: 'select',
    label: 'Form',
    options: (dependency, secondDependency) =>
      formOptions(dependency, secondDependency, true),
    value: '0',
    inputWidth: 3,
    dependencyName: 'site',
    secondDependencyName: 'isGiraffeAffiliate'
  }
});
