/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Button } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import AffiliateAllowListTable from './AffiliateAllowListTable';
import Dialog from '../../../../../../../Dialog';
import { colors } from '../../../../../../../../Utilities/LenoxColors';
import AffiliateAllowListForm from './Components/AffiliateAllowListForm';
import { getAffiliateAllowList } from '../../../../../../requests';
import { NetworkRequest } from '../../../../../../../Utilities/NetworkRequests/NetworkRequests';
import { editAffiliateAllowListState } from '../../defaultState/state';
import { handleFormObjectChange } from '../../../../../../../Utilities/HandleFormObjectChange';
import { formatAffiliateValue } from '../../../../../../../Utilities/getAffiliateIDFromName';

const styles = () => ({
  sectionContainer: {
    border: 'solid 1px rgba(20,20,20,.3)',
    borderRadius: '8px',
    margin: '1rem auto'
  },
  sectionTitle: {
    fontSize: '1.25rem',
    textAlign: 'center',
    padding: '.5rem',
    backgroundColor: colors.lenoxDark2,
    color: colors.lenoxLight1,
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px'
  },
  section: { padding: '2rem' },
  container: { textAlign: 'center', paddingBottom: '5rem' },
  hr: { margin: '1rem' },
  header: {},
  addAffiliateButton: {
    backgroundColor: colors.lenoxDark2,
    margin: '1rem',
    color: 'white'
  }
});

function AddAffiliateAllowList(props) {
  const [formValues, setFormValues] = useState(editAffiliateAllowListState());
  const [valuesToSubmit, setValuesToSubmit] = useState([]);
  const [rows, setRows] = useState([]);
  const [removing, setRemoving] = useState(false);
  const [rowRemoved, setRowRemoved] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState({
    message: '',
    type: 'confirm',
    title: 'Confirm'
  });
  const [dialog, setDialog] = useState({
    message: '',
    type: 'error',
    title: 'Error'
  });
  const { classes } = props;

  const getData = useCallback(() => {
    getAffiliateAllowList({ buyer_id: props.buyerID, action: 'get' }).then(
      ({ data }) => {
        setRows(data);
        setRowRemoved('');
      }
    );
  }, [props.buyerID]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (
      formValues.affiliates.value.length > 0 &&
      !valuesToSubmit.includes(formValues.affiliates.value)
    ) {
      setValuesToSubmit((valuesToSubmit) => [
        ...valuesToSubmit,
        formValues.affiliates.value
      ]);
      setFormValues(editAffiliateAllowListState());
    } else if (valuesToSubmit.includes(formValues.affiliates.value)) {
      toggleDialog(
        `${formValues.affiliates.value} has already been selected`,
        'error',
        'Error'
      );
      setFormValues(editAffiliateAllowListState());
    }
  }, [formValues, valuesToSubmit]);

  const toggleDialog = (message = '', type = 'success', title = 'Success') => {
    setDialog({
      message,
      type,
      title
    });
  };

  const buildFormValues = (fields) => {
    Object.keys(fields).forEach((fieldName) => {
      if (fieldName === 'threshold') {
        fields[fieldName].hidden =
          fields.is_exclusive_buyer.value.toString() === '0';
      }
    });
    return fields;
  };

  const handleChange = (field, value) => {
    const values = handleFormObjectChange(field, value, { ...formValues });
    setFormValues(values);
  };

  const handleRemoveRow = async (row) => {
    setRowRemoved(row.id);
    const { data } = await NetworkRequest('buyerAffiliates', {
      action: 'delete',
      id: row.id
    });
    if (data.success) {
      const newRows = rows.filter(
        (affiliate) => affiliate.id !== row.affiliate_id
      );
      setRows(newRows);
      getData();
    }
  };

  const removeAll = async () => {
    setRemoving(true);
    const { data } = await NetworkRequest('buyerAffiliates', {
      action: 'deleteAll',
      buyer_id: props.buyerID
    });
    handleConfirm(false);
    setRemoving(false);
    if (data.success) {
      toggleDialog('Affiliate allow list removed successfully');
      setFormValues(editAffiliateAllowListState());
      getData();
    } else {
      toggleDialog('An error happened.', 'error', 'Error');
    }
  };

  const renderConfirmDialog = () => (
    <Dialog
      style={{ minHeight: '500px' }}
      title={confirmationDialog.title}
      children={confirmationDialog.message}
      open={!!confirmationDialog.message}
      loading={removing}
      onRequestSave={removeAll}
      onRequestClose={() => setTimeout(() => handleConfirm(false), 300)}
      type={confirmationDialog.type}
      saveButtonText="Confirm"
    />
  );

  const renderDialog = () => (
    <Dialog
      title={dialog.title || 'Error'}
      children={dialog.message}
      open={!!dialog.message}
      onRequestClose={() =>
        setTimeout(() => toggleDialog('', dialog.type), 300)
      }
      type={dialog.type}
    />
  );

  const handleConfirm = (
    message = '',
    type = 'confirm',
    title = 'Confirm Remove All'
  ) => {
    setConfirmationDialog({
      message,
      type,
      title
    });
  };

  const handleResponse = (response) => {
    setSubmitting(false);
    if (response && response.success === true) {
      toggleDialog('Affiliates successfully added', 'success', 'Success');
      setFormValues(editAffiliateAllowListState());
      setValuesToSubmit([]);
      getData();
    } else {
      this.toggleDialog('An error has happened', 'error', 'Error');
    }
  };

  const formatSubmitAffiliate = (submitData) => {
    const affiliateID = submitData.map((affiliateID) =>
      formatAffiliateValue(affiliateID)
    );
    return {
      action: 'insert',
      affiliate_ids: affiliateID,
      buyer_id: props.buyerID
    };
  };
  const handleSubmitRequest = () => {
    const params = formatSubmitAffiliate(valuesToSubmit);
    setSubmitting(true);
    NetworkRequest('buyerAffiliates', { ...params })
      .then(({ data }) => handleResponse(data))
      .catch(() => setSubmitting(false));
  };
  const handleSubmit = () => {
    const repeatedAffiliatesArray = rows.filter((row) =>
      valuesToSubmit.includes(row.affiliate_name)
    );
    if (valuesToSubmit.length < 1) {
      toggleDialog('Please select affiliates to add', 'error', 'Error');
      return false;
    }
    if (repeatedAffiliatesArray.length > 0) {
      const repeatedAffiliates = repeatedAffiliatesArray.map((affiliate) => {
        return affiliate.affiliate_id;
      });
      toggleDialog(
        `Some affiliates have already been added to the allow list (${repeatedAffiliates})`,
        'error',
        'Error'
      );
      return false;
    }
    handleSubmitRequest();
  };
  const renderSectionContainer = (title, content) => (
    <Grid item xs={10} className={classes.sectionContainer}>
      <div className={classes.sectionTitle}>{title}</div>
      <div className={classes.section}>{content}</div>
    </Grid>
  );

  const renderAffiliateTable = (rows) =>
    rows.length > 0 &&
    renderSectionContainer(
      'Existing Affiliates On Allow List',
      <AffiliateAllowListTable
        rows={rows}
        handleRemoveAffiliate={handleRemoveRow}
        rowRemoved={rowRemoved}
      />
    );

  const removePill = (affiliate) => {
    const newValues = valuesToSubmit.filter((value) => value !== affiliate);
    setValuesToSubmit(newValues);
  };

  return (
    <Grid>
      {renderConfirmDialog()}
      {renderDialog()}
      <hr style={{ margin: '1rem' }} />
      <h3 style={{ textAlign: 'center' }}>Affiliate Allow List</h3>
      <AffiliateAllowListForm
        formValues={buildFormValues(formValues)}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        submitting={submitting}
        dialog={dialog}
        removePill={removePill}
        pillValues={valuesToSubmit}
        toggleDialog={toggleDialog}
        formTitle="Add affiliate to allow list"
        content="Add affiliates to allow list"
      />
      {renderAffiliateTable(rows)}
      {rows.length > 0 && (
        <Button
          onClick={() =>
            handleConfirm(
              "Are you sure you want to remove this buyer's affiliate allow list?"
            )
          }
          style={{
            display: 'flex',
            margin: '0 auto 1em auto',
            backgroundColor: 'red',
            color: '#fff',
            textTransform: 'uppercase'
          }}
        >
          remove all affiliate allow lists
        </Button>
      )}
    </Grid>
  );
}

export default withStyles(AddAffiliateAllowList, styles);
