const agentListStatus = [
  { value: 'all', display: 'All Agents' },
  { value: '1', display: 'Active Agents' },
  { value: '0', display: 'Inactive Agents' }
];

export const filters = () => ({
  agentListStatus: {
    name: 'agentListStatus',
    type: 'select',
    label: 'Agent Status',
    options: () => agentListStatus,
    value: '1',
    inputWidth: 3
  }
});
