/* eslint-disable no-param-reassign */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import { Grid } from '@mui/material';
import { prepayValue } from '../defaultState/state';
import FormContainer from '../../../../FormContainer/FormContainer';
import { handleFormObjectChange } from '../../../../Utilities/HandleFormObjectChange';
import { validateForSubmit } from '../../../../Utilities/ValidationHelper/ValidateForm';
import { NetworkRequest } from '../../../../Utilities/NetworkRequests/NetworkRequests';
import { formatSubmitValues } from '../../../../Utilities/SubmitHelper/FormatForSubmit';
import LoaderComponent from '../../../../Utilities/LoaderComponent';

export default class PrePay extends Component {
  state = {
    formValues: { ...prepayValue },
    submitting: false,
    dialog: {
      message: '',
      type: 'error',
      title: 'Error'
    },
    dataLoading: true
  };

  componentDidMount() {
    this.getAgentCustomCriteria();
  }

  toggleDialog = (message = '', type = 'error', title = 'Error') =>
    this.setState({
      dialog: { ...this.state.dialog, message, type, title }
    });

  getAgentCustomCriteria = async () => {
    this.setState({ dataLoading: true });
    const { data } = await NetworkRequest('billing', {
      action: 'getBillingInfo',
      email: localStorage.getItem('email')
    });

    if (data && (Boolean(data.length) || Boolean(Object.keys(data).length))) {
      const currentFormValues = { ...this.state.formValues };
      const formValues = Object.keys(currentFormValues).reduce(
        (newFormValues, key) => {
          return handleFormObjectChange(
            key,
            data[key] || currentFormValues[key].value,
            currentFormValues
          );
        },
        currentFormValues
      );
      this.setState({ formValues });
    }
    this.setState({ dataLoading: false });
  };

  handleChange = async (field, value) => {
    let formValues = handleFormObjectChange(field, value, {
      ...this.state.formValues
    });
    formValues = this.handleTypeChange(formValues, field, value);
    this.setState({ formValues });
  };

  handleTypeChange = (formValues, field, value) => {
    if (field === 'payment_type') {
      Object.assign(formValues, formValues, {
        prepay_amount: {
          ...formValues.prepay_amount,
          hidden: value !== 'prepay'
        }
      });
    }
    return formValues;
  };

  handleSubmit = async () => {
    const { valid, messages } = validateForSubmit(this.state.formValues);
    if (!valid) {
      this.toggleDialog(messages, 'error');
      return false;
    }
    this.handleSubmitRequest();
  };

  handleSubmitRequest = () => {
    this.setState({ submitting: true }, () =>
      NetworkRequest(
        'billing',
        formatSubmitValues(this.state.formValues),
        'change_payment_type'
      )
        .then(({ data }) => this.handleResponse(data))
        .catch(() => this.setState({ submitting: false }))
    );
  };

  handleResponse = (data) => {
    if (!data) return;

    this.setState({ submitting: false }, () => {
      this.toggleDialog(
        data.status ? `Payment Info Updated` : data.message,
        data.status ? 'success' : 'error',
        data.status ? 'Success' : 'Error'
      );
    });
  };

  renderPrepayNotice = () => (
    <Grid
      style={{ fontSize: '.75rem' }}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        style={{ padding: '5px', color: 'red', textAlign: 'center' }}
        item
        xs={7}
      >
        {this.state.formValues.payment_type.value === 'prepay'
          ? "By selecting 'Prepay Amount', your credit card will only be charged the prepay " +
            'amount if you have an insufficient balance to purchase a lead.'
          : "By selecting 'On-Demand', your credit card will only be charged necessary amount to purchase a lead."}
      </Grid>
    </Grid>
  );

  buildFormValues = (fields, state) => {
    Object.keys(fields).map((fieldName) => {
      fields[fieldName].dependency =
        (state.formValues[fields[fieldName].dependencyName] &&
          state.formValues[fields[fieldName].dependencyName].value) ||
        false;
      fields[fieldName].value = state.formValues[fieldName].value;

      if (
        fieldName === 'prepay_amount' &&
        fields.payment_type.value !== 'prepay'
      ) {
        fields[fieldName].hidden = true;
      } else if (fieldName === 'prepay_amount') {
        fields[fieldName].hidden = false;
      }
      return true;
    });
    return fields;
  };

  renderLoader = () => <LoaderComponent padding={150} />;

  render() {
    const { formValues, submitting, dialog, dataLoading } = this.state;
    if (dataLoading) return this.renderLoader();

    return (
      <Grid>
        <FormContainer
          formValues={this.buildFormValues(formValues, this.state)}
          submitting={submitting}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          inputWidth={8}
          separateBlock
          dialog={dialog}
          toggleDialog={this.toggleDialog}
          content="Update"
        />
        {this.renderPrepayNotice()}
      </Grid>
    );
  }
}
