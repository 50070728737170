/* eslint-disable default-param-last */
import {
  HANDLE_CHANGE,
  HANDLE_RESET_STATE,
  UPDATE_BREADCRUMBS
} from './actions';
import { isMobileDevice } from '../common/utilities';

export const initialState = {
  selectedPage: '',
  menuOpen: !isMobileDevice(),
  route: false,
  breadCrumbs: []
};

export const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_CHANGE:
      return {
        ...state,
        [action.state]: action.value
      };
    case UPDATE_BREADCRUMBS:
      return {
        ...state,
        breadCrumbs: action.breadCrumbs
      };
    case HANDLE_RESET_STATE:
      return { ...initialState };
    default:
      return state;
  }
};
