import { defaultInputProps } from '../../../../FormContainer/defaultFormStates';

const operatorOptions = [
  { value: '-1', display: 'Select an operation.' },
  { value: '===', display: '===' },
  { value: '!==', display: '!==' },
  { value: '>', display: '>' },
  { value: '>=', display: '>=' },
  { value: '<', display: '<' },
  { value: '<=', display: '<=' }
];

export const editConstraints = () => ({
  key: {
    ...defaultInputProps,
    name: 'key',
    label: 'Select Key',
    type: 'select',
    options: () => [{ value: '0', display: 'Select a key' }],
    value: '0',
    defaultVal: '0'
  },
  operator: {
    ...defaultInputProps,
    name: 'operator',
    label: 'Select Operator',
    type: 'select',
    options: () => operatorOptions,
    value: '-1',
    defaultVal: '-1'
  },
  value: {
    ...defaultInputProps,
    name: 'value',
    label: 'Add Value',
    value: ''
  }
});
