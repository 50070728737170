export const swapElements = (array, index1, index2) => {
  if (array[index1] && array[index2]) {
    const formattedArray = [...array];
    const temp = formattedArray[index1];
    formattedArray[index1] = formattedArray[index2];
    formattedArray[index2] = temp;
    return formattedArray;
  }
  return array;
};
