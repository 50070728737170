import React, { type FC, memo, useMemo, lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ToolBar from '../Components/NavBar/NavigationContainer';
import { checkAgent } from '../Components/PermissionsWrappers/permissionChecks';
import { useAppStoreState } from '../Utilities/hooks/useApp';
import { usePrivateRoutes } from './hooks/usePrivateRoutes';
import { LiveChatRoute } from './LiveChatRoute';
import { RedirectRoute } from './RedirectRoute';
import { usePublicRoutes } from './hooks/usePublicRoutes';
import { BreadCrumbsProvider } from '../providers/BreadCrumbs/BreadCrumbsProvider';
import { useWindowScroll } from '../Utilities/hooks/useWindowScroll';
import { config } from '../config';

const Welcome = lazy(() => import('../Components/NavBar/OnBoarding/Welcome'));

const RoutingController: FC = memo(() => {
  return (
    <BrowserRouter basename={config.routeBaseName}>
      <RoutingWithoutBrowser />
    </BrowserRouter>
  );
});

// It's divided into two components to avoid the error of using the browser router outside the browser or for the jest test
export const RoutingWithoutBrowser: FC<{ isTesting?: boolean }> = ({
  isTesting
}) => {
  const { loggedIn, agentSetup } = useAppStoreState();
  const publicRoutes = usePublicRoutes();
  const privateRoutes = usePrivateRoutes();
  useWindowScroll();

  const isLoadedConditional = useMemo(() => {
    if (isTesting) return true;

    return loggedIn && (!checkAgent() || agentSetup);
  }, [agentSetup, isTesting, loggedIn]);

  const isWelcomePageExists = checkAgent() && !agentSetup;

  return (
    <BreadCrumbsProvider>
      <div id="appContainer">
        {isLoadedConditional && <ToolBar />}
        <Suspense fallback={<div />}>
          <Routes>
            <>
              {publicRoutes}
              {isLoadedConditional && privateRoutes}
              {isWelcomePageExists && (
                <Route path="/Welcome" element={<Welcome />} />
              )}
              <Route
                path="/*"
                element={
                  <RedirectRoute isLoadedConditional={isLoadedConditional} />
                }
              />
            </>
          </Routes>
        </Suspense>
        <LiveChatRoute />
      </div>
    </BreadCrumbsProvider>
  );
};

export default RoutingController;
