export const filters = () => ({
  tyPageName: {
    name: 'tyPageName',
    type: 'input',
    label: 'TY Page Name',
    value: '',
    inputWidth: 3,
    disabled: true
  }
});
