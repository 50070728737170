import { affiliateID, buyerIdsList } from '../../../common/commonRequests';

export const filters = () => ({
  affiliateID: {
    name: 'affiliateID',
    type: 'autocomplete',
    label: 'Affiliate ID',
    options: () => affiliateID(),
    value: 'all',
    defaultVal: 'all'
  },
  buyerID: {
    name: 'buyerID',
    label: 'Buyers',
    type: 'select',
    options: () => buyerIdsList(true, false, true),
    value: 'all',
    defaultVal: 'all'
  }
});
