/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import {
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { Edit } from '@mui/icons-material';
import { editConstraints } from '../defaultState/state';
import FormContainer from '../../../../FormContainer/FormContainer';
import { handleFormObjectChange } from '../../../../Utilities/HandleFormObjectChange';
import { validateForSubmit } from '../../../../Utilities/ValidationHelper/ValidateForm';
import { getBuyerRestrictions } from '../../../requests';
import { styles } from '../../AddBuyerConstraints/render/AddBuyerConstraints';
import { CircularProgress } from '../../../../Utilities/CircularProgress';
import { getValidKeys } from '../../../../common/commonRequests';
import { formatSubmitValues } from '../../../../Utilities/SubmitHelper/FormatForSubmit';
import Dialog from '../../../../Dialog';
import { logError } from '../../../../../Utilities/logError';

class EditConstraints extends Component {
  state = {
    rows: [],
    keyOptions: [],
    formValues: editConstraints(),
    submitting: false,
    editingRowIndex: -1,
    dialog: {
      message: '',
      type: 'error',
      title: 'Error'
    }
  };

  componentDidMount() {
    this.getTableData();
  }

  getTableData = async () => {
    let params = { action: 'get' };
    if (this.props.row.id !== '') {
      params = { ...params, id: this.props.row.id };
    }
    try {
      this.setState({ loading: true });
      const promiseKeys = getValidKeys(this.props.row.buyer_name);
      const { data } = await getBuyerRestrictions(params);
      const options = await promiseKeys;
      this.setState({
        rows: data.constraint_elements.map((row, index) => ({ ...row, index })),
        keyOptions: options,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      logError(e);
    }
  };

  loadKeyValues = (constraint) => {
    const { formValues, keyOptions } = this.state;
    ['key', 'operator', 'value'].map((column) =>
      column === 'key'
        ? Object.assign(formValues, formValues, {
            [column]: {
              ...formValues[column],
              value:
                `${constraint.key}!_!${constraint.type}` ||
                formValues.key.value,
              options: () => keyOptions
            }
          })
        : Object.assign(formValues, formValues, {
            [column]: {
              ...formValues[column],
              value: constraint[column] || formValues[column].value
            }
          })
    );
    this.setState({ formValues });
  };

  handleChange = (field, value) => {
    const formValues = handleFormObjectChange(field, value, {
      ...this.state.formValues
    });
    this.setState({ formValues });
  };

  toggleDialog = (message = '', type = 'error', title = 'Error') =>
    this.setState({
      dialog: { ...this.state.dialog, message, type, title }
    });

  handleSubmit = () => {
    const { valid, messages } = validateForSubmit(this.state.formValues);
    if (!valid) {
      this.toggleDialog(messages, 'error');
      return false;
    }
    this.handleSubmitRequest();
  };

  formatConstraintElements = (rows, submitValues) =>
    rows.map((row) => {
      if (row.index === this.state.editingRowIndex) {
        const keyTypePair = submitValues.key.split('!_!');
        return {
          key: keyTypePair[0],
          operator: submitValues.operator,
          value: submitValues.value,
          type: keyTypePair[1],
          index: row.index
        };
      }
      return row;
    });

  sendRequest = (constraint_elements) => {
    getBuyerRestrictions({
      constraint_elements,
      action: 'update',
      id: this.props.row.id
    })
      .then(({ data }) => this.handleResponse(data, constraint_elements))
      .catch(() => this.setState({ submitting: false }));
  };

  handleSubmitRequest = () => {
    const { formValues, rows } = this.state;
    const submitValues = formatSubmitValues(formValues);
    const constraint_elements = this.formatConstraintElements(
      rows,
      submitValues
    );
    this.setState({ submitting: true }, () =>
      this.sendRequest(constraint_elements)
    );
  };

  handleResponse = (data, constraint_elements) => {
    if (!!data && data.success) {
      this.setState({ rows: constraint_elements });
      this.toggleDialog(
        'Buyer restriction successfully updated',
        'success',
        'Success'
      );
    } else {
      this.toggleDialog('An error has happened', 'error', 'Error');
    }
    this.setState({ submitting: false, editingRowIndex: -1 });
  };

  handleEditConstraint = (row) => {
    this.setState({ editingRowIndex: row.index });
    this.loadKeyValues(row);
  };

  renderEditButton = (row) => (
    <div style={{ maxWidth: '10rem' }}>
      <IconButton
        onClick={() => this.handleEditConstraint(row)}
        value={row.index}
        size="large"
      >
        <Edit />
      </IconButton>
    </div>
  );

  renderRow = (row, index) => (
    <TableRow key={`row_${index}`}>
      {Object.keys(row).map(
        (item) =>
          item !== 'index' && (
            <TableCell className={this.props.classes.tableCell}>
              {' '}
              {row[item]}{' '}
            </TableCell>
          )
      )}
      <TableCell className={this.props.classes.tableCell}>
        {' '}
        {this.renderEditButton(row)}{' '}
      </TableCell>
    </TableRow>
  );

  renderTable = (title, headers, data) => (
    <Grid className={this.props.classes.tableContainer}>
      <div className={this.props.classes.tableTitle}>{title}</div>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell className={this.props.classes.tableCellHead}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => this.renderRow(row, index))}
        </TableBody>
      </Table>
    </Grid>
  );

  renderDialog = () => {
    const open = !!this.state.dialog.message;
    let { title } = this.state.dialog;
    if (!title) {
      title = open ? 'Error' : '';
    }

    return (
      <Dialog
        title={title}
        children={this.state.dialog.message}
        open={open}
        onRequestClose={() => setTimeout(() => this.toggleDialog(), 300)}
        type={this.state.dialog.type}
      />
    );
  };

  render() {
    const { formValues, submitting, dialog, editingRowIndex } = this.state;
    const { loading, rows } = this.state;
    const headers = ['Key', 'Operator', 'Value', 'Type', 'Edit'];
    return (
      <>
        {loading ? (
          <div style={{ textAlign: 'center' }}>{CircularProgress(50)}</div>
        ) : (
          this.renderTable('Restriction Elements', headers, rows)
        )}
        {editingRowIndex >= 0 && (
          <FormContainer
            formValues={formValues}
            submitting={submitting}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            inputWidth={8}
            separateBlock
            dialog={dialog}
          />
        )}
        {this.renderDialog()}
      </>
    );
  }
}

export default withStyles(EditConstraints, styles);
