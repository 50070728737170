import { siteOptions } from '../../../../common/commonRequests';

export const filters = () => ({
  siteUUID: {
    name: 'siteUUID',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions(false, true),
    value: '4c7879a2-da7b-4fe9-af69-8926cfc40072',
    inputWidth: 3
  }
});
