import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import UpdatePassword from '../../UserSettings/UpdatePassword/render/UpdatePassword';
import Billing from '../../Agents/Components/AgentBillingInfo/render/BillingInfo';
import PaymentType from '../../Agents/Components/PaymentType/render/PaymentType';
import {
  checkAdmin,
  checkAgent,
  checkTech
} from '../../PermissionsWrappers/permissionChecks';
import { StripeKey } from '../../Agents/Components/UpdateBillingCreateCharge/secret';
import { type IRoute } from '../../../Utilities/types';

const stripePromise = loadStripe(StripeKey);

const adminList = {
  change_password: {
    section: '',
    displayName: 'Change Password',
    content: <UpdatePassword />
  }
};

const billing = {
  section: '',
  displayName: 'Update Billing',
  content: (
    <Elements stripe={stripePromise}>
      <Billing />
    </Elements>
  )
};

const paymentType = {
  section: '',
  displayName: 'Payment Type',
  content: <PaymentType />
};

const agentList = {
  change_password: adminList.change_password,
  update_billing: billing,
  payment_type: paymentType
};

export const SettingsPageList = (): Record<string, IRoute> => {
  if (checkAdmin() || checkTech()) {
    return adminList;
  }

  if (checkAgent()) {
    return agentList;
  }

  return adminList;
};

export const sections = [''];
